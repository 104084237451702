import {
  Box,
  Button,
  Flex,
  HStack,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../services/api';

interface Props {
  id: string | undefined;
  profile: string | undefined;
}

interface PropsAble {
  agc_credor_id: string | undefined;
  procurador_nome: string | undefined;
  agc_credor_nome: string | undefined;
  agc_credor_agc_nome: string | undefined;
  credenciado: boolean;
  habilitado: boolean;
}

export const PendingAnalysis: React.FC<Props> = ({ id, profile }) => {
  const navigate = useNavigate();

  const [ables, setAbles] = useState<PropsAble[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const itemsPerPage = 10;

  useEffect(() => {
    getAnalysis();
  }, [id]);

  const getAnalysis = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_id=${id}&habilitado=false&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setLoading(false);
        setAbles(results);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 3;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const paginationButtons = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
    }

    if (startPage !== 1) {
      paginationButtons.push(
        <Button
          key={1}
          onClick={() => handlePageChange(1)}
          fontWeight={currentPage === 1 ? 'bold' : 'normal'}
          color={currentPage === 1 ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === 1
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          1
        </Button>,
      );
    }

    if (startPage > 2) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          fontWeight={currentPage === i ? 'bold' : 'normal'}
          color={currentPage === i ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === i
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {i}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    if (endPage !== totalPages) {
      paginationButtons.push(
        <Button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          fontWeight={currentPage === totalPages ? 'bold' : 'normal'}
          color={currentPage === totalPages ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === totalPages
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {totalPages}
        </Button>,
      );
    }

    return paginationButtons;
  };

  const DetailsCreditors = (credorId: any) => {
    if (profile === 'aj') {
      navigate(`/agcs/${id}/creditors/${credorId}`);
    } else {
      navigate(`/agcs/${id}/creditorBond/${credorId}`);
    }
  };

  const headTable = [
    { title: 'Nome do Procurador' },
    { title: 'Nome do Credor' },
    { title: 'Habilitado ?' },
    { title: 'Credenciado ?' },
    { title: 'Ver' },
  ];

  return (
    <Box>
      {loading && (
        <Flex justify="center" pt="20px">
          <Spinner size="xl" color="#DF8E25" />
        </Flex>
      )}

      {!loading && (
        <TableContainer mt="45px">
          <Table variant="simple">
            <Thead borderBottom="2px solid #9B9B9B">
              <Tr>
                {headTable.map((item) => (
                  <Th
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    <HStack>
                      <Text>{item.title}</Text>
                    </HStack>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {ables &&
                ables.length > 0 &&
                ables.map((item) => {
                  return (
                    <Tr
                      borderBottom="2px solid #CCC"
                      color="#000"
                      fontFamily="Rubik"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      <Td>{item.procurador_nome}</Td>
                      <Td>{item.agc_credor_nome}</Td>
                      <Td>{item.habilitado === false ? 'Não' : 'Sim'}</Td>
                      <Td>{item.credenciado === false ? 'Não' : 'Sim'}</Td>
                      <Td>
                        <Button
                          h="25px"
                          borderRadius="71px"
                          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                          fontSize="14px"
                          fontFamily="Rubik"
                          fontWeight="400"
                          color="#FFF"
                          onClick={() =>
                            DetailsCreditors(
                              item.agc_credor_id && item.agc_credor_id,
                            )
                          }
                        >
                          Ver
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>

          <HStack py="20px" align="center" justify="flex-end">
            {renderPaginationButtons()}
          </HStack>
        </TableContainer>
      )}
    </Box>
  );
};
