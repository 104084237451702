import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  Errors: any[];
}

export const ModalErrors: React.FC<Props> = ({ isOpen, onClose, Errors }) => {
  const textErrors = Errors.join('\n');
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  const copyToClipboard = () => {
    if (textAreaRef.current) {
      textAreaRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent h="500px" py="20px">
        <ModalBody>
          <Stack spacing="10px">
            <Text fontWeight="500" fontSize="18px">
              Erros encontrados:
            </Text>
            <Textarea ref={textAreaRef} h="350px">
              {textErrors}
            </Textarea>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button
            fontWeight="semibold"
            bg="transparent"
            border="2px solid #EBd87F"
            onClick={copyToClipboard}
            mr="10px"
          >
            Copiar
          </Button>
          <Button
            onClick={onClose}
            fontWeight="semibold"
            color="white"
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          >
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
