import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { File } from '@phosphor-icons/react';
import { RiFolderWarningLine } from 'react-icons/ri';
import Folder from '../../../../../assets/icons/FolderNotchOpen.svg';
import { api } from '../../../../../services/api';

interface Props {
  id: string | undefined;
  selectedAttorney: any;
}

interface PropsDocs {
  id: number;
  tipo_documento: string | undefined;
  data_documento: string | undefined;
  arquivo_link: string | undefined;
  agc_credor_procurador_id: number;
  procurador_nome: string | undefined;
}

export const CardDocuments: React.FC<Props> = ({ id, selectedAttorney }) => {
  const [docs, setDocs] = useState<PropsDocs[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedAttorney) getDocsAttorneys();
  }, [selectedAttorney]);

  const getDocsAttorneys = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `agc-credor-procurador-documento/?agc_credor_id=${id}&agc_credor_procurador_id=${selectedAttorney.id}`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setDocs(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p="24px 16px" bg="#FFF" borderRadius="31px" h="481px" w="100%">
      <Stack
        spacing="16px"
        maxH="100%"
        overflowY="auto"
        overflowX="hidden"
        pr="10px"
        sx={{
          '&::-webkit-scrollbar': {
            width: '3px',
            marginLeft: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
            borderRadius: 'full',
          },
          '&::-webkit-scrollbar-track': {
            width: '8px',
            bg: '#CCC',
            borderRadius: '10px',
          },
        }}
        h="94%"
      >
        <HStack spacing="33px">
          <Image src={Folder} />
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Documentos enviados
          </Text>
        </HStack>

        {/* <Select
          color="#222"
          fontFamily="Rubik"
          fontWeight="400"
          letterSpacing="0.8px"
          borderRadius="43px"
          border="1px solid #DF8E25"
          h="54px"
          w="100%"
          _focus={{ border: 'none' }}
          _focusVisible={{ border: '1px solid #DF8E25' }}
          _hover={{ border: '1px solid #DF8E25' }}
          iconColor="#8A8A8A"
          onChange={(e) => setIdAttorney(e.target.value)}
          placeholder="Selecione um procurador"
        >
          {attorneys &&
            attorneys.length > 0 &&
            attorneys.map((item) => (
              <option value={item.id}>{item.procurador_nome}</option>
            ))}
        </Select> */}

        {loading && (
          <Flex justify="center" pt="20px" align="center" h="60%">
            <Spinner size="xl" color="#DF8E25" />
          </Flex>
        )}

        {!loading && docs.length === 0 && (
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="60%"
          >
            <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
            <Text color="#222222" fontFamily="Rubik" fontWeight="500">
              Nenhum documento encontrado!
            </Text>
          </Box>
        )}

        {!loading &&
          docs &&
          docs.length > 0 &&
          docs.map((item) => {
            const formattedData = () => {
              if (item.data_documento) {
                const today = new Date(item.data_documento);
                const month =
                  today.getMonth() + 1 < 10
                    ? `0${today.getMonth() + 1}`
                    : today.getMonth() + 1;
                const day =
                  today.getDate() < 10
                    ? `0${today.getDate()}`
                    : today.getDate();
                const hours =
                  today.getHours() < 10
                    ? `0${today.getHours()}`
                    : today.getHours();
                const minutes =
                  today.getMinutes() < 10
                    ? `0${today.getMinutes()}`
                    : today.getMinutes();

                const dateNow = `${day}/${month}/${today.getFullYear()} - ${hours}:${minutes} AM`;

                return dateNow;
              }

              return '';
            };

            return (
              <Stack
                spacing="10px"
                p="9px 10px"
                borderRadius="12px"
                border="1px solid #E7E7E7"
              >
                <Stack spacing="10px">
                  <HStack align="center">
                    <Center
                      w="45px"
                      h="45px"
                      bg="#E9E9E9"
                      border="2px solid #D7D7D7"
                      borderRadius="100px"
                    >
                      <File size={22} weight="bold" color="white" />
                    </Center>
                    <Stack spacing="4px">
                      <Text
                        color="#515151"
                        fontFamily="Rubik"
                        fontSize="18px"
                        fontWeight="400"
                        letterSpacing="0.45px"
                        lineHeight="26px"
                      >
                        {item.procurador_nome}
                      </Text>
                      <Box>
                        <Text
                          color="#515151"
                          fontFamily="Rubik"
                          fontSize="18px"
                          fontWeight="400"
                          letterSpacing="0.45px"
                          lineHeight="26px"
                        >
                          {item.tipo_documento}
                        </Text>
                        <HStack>
                          <Link
                            letterSpacing="1.5px"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            maxW="400px"
                            href={item.arquivo_link}
                            color="#515151"
                            fontFamily="Rubik"
                            fontSize="12px"
                            target="_blank"
                          >
                            Download
                          </Link>
                        </HStack>
                      </Box>
                    </Stack>
                  </HStack>

                  <Text color="#A0A0A0" fontSize="12px" fontWeight="400">
                    {formattedData()}
                  </Text>
                </Stack>
              </Stack>
            );
          })}
      </Stack>
    </Box>
  );
};
