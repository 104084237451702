import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { api } from '../../../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  isChecked: () => void;
  checked: boolean;
  id: number;
  // eslint-disable-next-line no-unused-vars
  stateAttorney: (data: any) => void;
}

export const ModalSwitch: React.FC<Props> = ({
  isOpen,
  onClose,
  isChecked,
  checked,
  id,
  stateAttorney,
}) => {
  console.log(id);

  const ableAttorney = async () => {
    try {
      const dataAble = {
        habilitado: !checked,
      };

      const response = await api.patch(
        `/agc-credor-procurador/${id}/habilitar/`,
        dataAble,
      );
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        isChecked();
        onClose();
        stateAttorney(data);
      }
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p="17px 19px">
          <Stack spacing="12px">
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="22px"
              fontWeight="400"
            >
              Deseja {checked ? 'reprovar' : 'aprovar'} este credor?
            </Text>

            <HStack>
              <Button
                bg="transparent"
                boxShadow="none"
                borderColor="#DF8E25"
                borderWidth="1px"
                fontWeight="500"
                fontSize="15px"
                w="100%"
                onClick={onClose}
              >
                Cancelar
              </Button>

              <Button
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
                boxShadow="none"
                borderWidth="1px"
                fontWeight="500"
                fontSize="15px"
                color="white"
                w="100%"
                onClick={ableAttorney}
              >
                Confirmar
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
