import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Start from '../pages/auth/start';
import Login from '../pages/auth/login';
import { RequireAuth } from './require.auth';
import AGCs from '../pages/Agcs';
import Messages from '../pages/messages';
import ChangeProfile from '../pages/changeProfile';
import Profile from '../pages/profile';
import { Register } from '../pages/auth/register';
import { RegisterAGC } from '../pages/registerAgc';
import { CreditorsAgc } from '../pages/creditorsAgc';
import { LostPassword } from '../pages/lostPassword';
import { AgcInternal } from '../pages/agcInternal';
import { InternalCreditor } from '../pages/internalCreditor';
import MeetPage from '../pages/meet';
import { InternalCreditorBond } from '../pages/internalCreditorBond';
import { Report } from '../pages/agcInternal/report';
import { Ajs } from '../pages/ajs';

const RoutesConfig: React.FC = () => {
  const { isAuthenticated, logout } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('e-agc');

    if (token) {
      const object = JSON.parse(token);
      const tokenAccess = object.access;

      const tokenPayload = JSON.parse(atob(tokenAccess.split('.')[1]));

      const currentTime = Math.floor(Date.now() / 1000);

      if (tokenPayload.exp && tokenPayload.exp < currentTime) {
        logout();
      }
    }
  }, [isAuthenticated]);

  return (
    <Routes>
      <Route
        path="/start"
        element={!isAuthenticated ? <Start /> : <Navigate to="/agcs" />}
      />
      <Route path="/login" element={!isAuthenticated && <Login />} />
      <Route
        path="/login"
        element={!isAuthenticated ? <Login /> : <Navigate to="/agcs" />}
      />
      <Route
        path="/register"
        element={!isAuthenticated ? <Register /> : <Navigate to="/agcs" />}
      />
      <Route
        path="/lost-password"
        element={!isAuthenticated ? <LostPassword /> : <Navigate to="/agcs" />}
      />
      <Route path="/" element={<RequireAuth />}>
        <Route index element={<Navigate to="/agcs" />} />
        <Route path="/agcs" element={<AGCs />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/changeProfile" element={<ChangeProfile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/register-agcs" element={<RegisterAGC />} />
        <Route path="/creditors" element={<CreditorsAgc />} />
        <Route path="/agcs/:agcId" element={<AgcInternal />} />
        <Route
          path="/agcs/:agcId/creditors/:internalCreditorId"
          element={<InternalCreditor />}
        />
        <Route
          path="/agcs/:agcId/creditorBond/:internalCreditorBondId"
          element={<InternalCreditorBond />}
        />
        <Route path="/agcs/:agcId/report" element={<Report />} />

        <Route path="/ajs" element={<Ajs />} />
      </Route>

      <Route path="/meet" element={<MeetPage />} />
    </Routes>
  );
};

export default RoutesConfig;
