import React from 'react';
import {
  HStack,
  Icon,
  IconButton,
  Td,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { BiSolidEdit, BiTrashAlt } from 'react-icons/bi';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { ICredores } from '../../../hooks/useCredores';
import EditarCredores from '../editar-credor';
import { formatClasse } from '../../../utils/formatClasse';
import { useStream } from '../../../context/stream.context';

interface Props {
  credor: ICredores;
  updateCredor: (_credor: ICredores) => void;
  deleteCredor: (_id: number) => void;
}

const CardCredor: React.FC<Props> = ({
  credor,
  updateCredor,
  deleteCredor,
}) => {
  const { isAdmin, callId } = useStream();
  const { isOpen, onClose, onOpen } = useDisclosure();

  const toCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);
  };

  return (
    <Tr>
      <Td>{credor?.nome}</Td>
      <Td>{credor.documento}</Td>
      <Td>{formatClasse(credor.agc_classe_classe)}</Td>
      <Td isNumeric>{credor.moeda}</Td>
      <Td isNumeric>{toCurrency(credor.valor)}</Td>
      <Td isNumeric>
        <HStack justify="end">
          <IconButton
            display={isAdmin ? 'block' : 'none'}
            aria-label=""
            onClick={onOpen}
            icon={<Icon as={BiSolidEdit} fontSize="22px" color="primary" />}
          />

          <IconButton
            display={isAdmin ? 'block' : 'none'}
            onClick={() => deleteCredor(credor.id)}
            aria-label=""
            icon={<Icon as={BiTrashAlt} fontSize="22px" color="primary" />}
          />

          <IconButton
            onClick={() => {
              const newRoute = `agcs/${callId}/creditorBond/${credor.id}`;
              window.open(`${window.location.origin}/${newRoute}`, '_blank');
            }}
            aria-label=""
            icon={
              <Icon
                as={MdOutlineRemoveRedEye}
                fontSize="22px"
                color="primary"
              />
            }
          />

          {isAdmin && (
            <EditarCredores
              updateCredor={updateCredor}
              isOpen={isOpen}
              onClose={onClose}
              credor={credor}
            />
          )}
        </HStack>
      </Td>
    </Tr>
  );
};

export default CardCredor;
