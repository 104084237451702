import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Image,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react';
import React, { ChangeEvent, useRef, useState } from 'react';
import { FiPaperclip } from 'react-icons/fi';
import CheckIcon from '../../../../assets/icons/checkIcon.png';
import { api } from '../../../../services/api';
import { StepTwo } from './stepTwo';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
}

export const DrawerBond: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const [steps, setSteps] = useState(1);

  const [errorExists, setErrorExists] = useState('');

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const postCreditor = async () => {
    try {
      const dados = {
        agc_credor_id: id,
      };
      const response = await api.post('/agc-credor-procurador/', dados);
      const result = response.data;
      if (
        (response.status === 200 && selectedFiles.length < 1) ||
        (response.status === 201 && selectedFiles.length < 1)
      ) {
        setSteps(steps + 1);
      }
      if (selectedFiles.length > 0) {
        const idProc = result.id;

        const uploadPromises = selectedFiles.map(async (selectedFile) => {
          const formData = new FormData();
          formData.append('tipo_documento', selectedFile.name);
          formData.append('agc_credor_procurador_id', idProc);
          formData.append('arquivo_link', selectedFile);

          const responseDoc = await api.post(
            'agc-credor-procurador-documento/',
            formData,
          );

          if (responseDoc.status === 200 || responseDoc.status === 201) {
            setSteps(steps + 1);
            setSelectedFiles([]);
          }
        });

        await Promise.all(uploadPromises);
      }
    } catch (error: any) {
      const { status } = error.response;
      const { detail } = error.response.data;

      setErrorExists(detail);
      if (status === 400) {
        setErrorExists('Você já é credenciado a este Credor');
      }
    }
  };

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.target.files || [];
    setSelectedFiles(Array.from(files));
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        onClose();
        setErrorExists('');
        setSelectedFiles([]);
      }}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent bg="#EEE">
        <DrawerCloseButton />

        <DrawerBody p="71px 38px">
          {steps === 1 && (
            <Stack spacing="9px">
              <Text
                color="#222"
                fontFamily="Roboto"
                fontSize="30px"
                fontWeight="400"
                letterSpacing="-0.3px"
              >
                Termine o credenciamento
              </Text>
              <Text
                color="#222"
                fontFamily="Rubik"
                fontSize="18px"
                fontWeight="400"
                lineHeight="122.5%"
              >
                Art. 36 <br /> § 4º O credor poderá ser representado na
                assembléia-geral por mandatário ou representante legal, desde
                que entregue ao administrador judicial, até 24 (vinte e quatro)
                horas antes da data prevista no aviso de convocação,
                <span
                  style={{
                    color: '#222',
                    fontFamily: 'Rubik',
                    fontSize: 18,
                    fontWeight: '700',
                  }}
                >
                  {' '}
                  documento hábil que comprove seus poderes ou a indicação das
                  folhas dos autos do processo em que se encontre o documento.
                </span>
              </Text>

              <Textarea
                p="10px"
                bg="#FFF"
                borderRadius="10px"
                boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.10)"
                mt="10px"
                minH="184px"
                _focus={{ border: 'none' }}
                _focusVisible={{ border: 'none' }}
                placeholder="Indique as folhas do processo ou envie uma mensagem (opcional)"
                _placeholder={{
                  color: '#222',
                  fontFamily: 'Rubik',
                  letterSpacing: '0.8px',
                  w: '362px',
                }}
              />

              <Text
                color="#222"
                textAlign="center"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
              >
                ou
              </Text>

              <Stack spacing="12px">
                <Text
                  color="#222"
                  fontFamily="Rubik"
                  fontSize="22px"
                  fontWeight="400"
                >
                  Anexar documento
                </Text>

                <Button
                  borderRadius="31px"
                  bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                  color="#FFF"
                  fontFamily="Rubik"
                  fontWeight="400"
                  letterSpacing="0.8px"
                  rightIcon={<FiPaperclip />}
                  display="flex"
                  justifyContent="space-between"
                  w="339px"
                  onClick={openFileInput}
                >
                  Escolher ficheiro
                </Button>

                {selectedFiles.length > 0 &&
                  selectedFiles.map((item) => <Text>{item.name}</Text>)}

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept=".xls,.xlsx,.xlsb,.txt,.csv,.tsv,.pdf"
                  multiple
                  onChange={handleFileChange}
                />
              </Stack>

              <HStack justify="space-between">
                <Button
                  border="2px solid #EBD87F"
                  borderRadius="45px"
                  bg="transparent"
                  fontFamily="Rubik"
                  fontSize="14px"
                  fontWeight="400"
                  color="#000"
                  leftIcon={
                    <CloseIcon w="10px" h="10px" color="#000" opacity="0.25" />
                  }
                  alignItems="center"
                  onClick={() => {
                    onClose();
                    setErrorExists('');
                    setSelectedFiles([]);
                  }}
                >
                  <Text mt="4px">Cancelar</Text>
                </Button>

                <Button
                  bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                  boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                  onClick={postCreditor}
                >
                  <HStack spacing="10px">
                    <Image src={CheckIcon} />{' '}
                    <Text
                      color="#FFF"
                      fontFamily="Rubik"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      Confirmar
                    </Text>
                  </HStack>
                </Button>
              </HStack>
              {errorExists && (
                <Text color="red" fontSize="14px">
                  {errorExists}
                </Text>
              )}
            </Stack>
          )}
          {steps === 2 && (
            <StepTwo onClose={onClose} prevStep={() => setSteps(1)} />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
