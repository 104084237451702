/* eslint-disable no-plusplus */
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Order from '../../../assets/icons/UpDown.svg';
import { api } from '../../../services/api';
import { ModalDelete } from './components/modalDelete';

interface Props {
  agcId: string | undefined;
  profile: string | undefined;
  data: any;
}

interface PropsCreditors {
  id: number;
  nome: string;
  tipo_documento: string;
  documento: string;
  agc_classe_classe: string;
  moeda: string;
  valor_original: number;
  valor: number;
  procuradores: [
    {
      procurador_nome: string | undefined;
      habilitado: boolean;
    },
  ];
}

export const AllCreditors: React.FC<Props> = ({ agcId, profile, data }) => {
  const navigate = useNavigate();

  const [creditors, setCreditors] = useState<PropsCreditors[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (filterText.length >= 5 || filterText.length === 0) {
      tableCreditors();
    }
    onSave();
  }, [currentPage, data, filterText]);

  const onSave = () => {
    if (data?.length > 0) {
      setCreditors([data, ...creditors]);
    }
  };

  const headTable = [
    { title: 'Nome' },
    { title: 'CNPJ / CPF' },
    { title: 'Procurador' },
    { title: 'Classe' },
    { title: 'Moeda' },
    { title: 'Crédito' },
    { title: 'Status' },
    { title: 'Ações' },
  ];

  const itemsPerPage = 10;

  const tableCreditors = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/agc-credor/?agc_id=${agcId}&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }&search=${filterText}`,
      );
      const { results, count } = response.data;
      if (response.status === 200 || response.status === 201) {
        setCreditors(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (event: any) => {
    const newFilterText = event.target.value;
    setFilterText(newFilterText);
  };

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 3;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const paginationButtons = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
    }

    if (startPage !== 1) {
      paginationButtons.push(
        <Button
          key={1}
          onClick={() => handlePageChange(1)}
          fontWeight={currentPage === 1 ? 'bold' : 'normal'}
          color={currentPage === 1 ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === 1
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          1
        </Button>,
      );
    }

    if (startPage > 2) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          fontWeight={currentPage === i ? 'bold' : 'normal'}
          color={currentPage === i ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === i
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {i}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    if (endPage !== totalPages) {
      paginationButtons.push(
        <Button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          fontWeight={currentPage === totalPages ? 'bold' : 'normal'}
          color={currentPage === totalPages ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === totalPages
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {totalPages}
        </Button>,
      );
    }

    return paginationButtons;
  };

  const DetailsCreditors = (id: any) => {
    if (profile === 'aj') {
      navigate(`/agcs/${agcId}/creditors/${id}`);
    } else {
      navigate(`/agcs/${agcId}/creditorBond/${id}`);
    }
  };

  return (
    <Box>
      <InputGroup>
        <InputLeftElement mt="4px" pl="36px">
          <SearchIcon color="#AAA" w="20px" h="20px" />
        </InputLeftElement>
        <Input
          boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.05)"
          placeholder="Pesquisa por nome"
          _placeholder={{
            color: '#888',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '28px',
            letterSpacing: '1.6px',
          }}
          pl="79px"
          h="56px"
          onChange={handleFilterChange}
        />
      </InputGroup>

      {loading ? (
        <Flex justify="center" pt="20px">
          <Spinner size="xl" color="#DF8E25" />
        </Flex>
      ) : (
        <TableContainer mt="45px">
          <Table variant="simple" size="sm">
            <Thead borderBottom="2px solid #9B9B9B">
              <Tr>
                {headTable.map((item) => (
                  <Th
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    <HStack>
                      <Text>{item.title}</Text>
                      <button>
                        <Image src={Order} />
                      </button>
                    </HStack>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {creditors &&
                creditors.length > 0 &&
                creditors.map((item) => {
                  /* eslint no-else-return: "error" */
                  const formattedCreditors = () => {
                    if (item && item.agc_classe_classe === 'trabalhista') {
                      return 'Trabalhista';
                    } else if (
                      item &&
                      item.agc_classe_classe === 'garantia-real'
                    ) {
                      return 'Garantia Real';
                    } else if (
                      item &&
                      item.agc_classe_classe === 'quirografario'
                    ) {
                      return 'Quirografário';
                    }
                    return 'Microempresa';
                  };

                  const formattedValue = {
                    coin: item && item.moeda,
                    valor: `${item?.valor_original?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: item && item.moeda,
                    })}`,
                  };

                  const formatCnpjCpf = () => {
                    /* eslint no-else-return: "error" */
                    if (item && item?.documento?.length === 11) {
                      return item.documento.replace(
                        /(\d{3})(\d{3})(\d{3})(\d{2})/,
                        '$1.$2.$3-$4',
                      );
                    } else if (item && item?.documento?.length === 14) {
                      return (
                        item &&
                        item.documento.replace(
                          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                          '$1.$2.$3/$4-$5',
                        )
                      );
                    }
                    return item && item.documento;
                  };

                  const filterStatus = item?.procuradores.filter(
                    (itemProc) => itemProc.habilitado === true,
                  );

                  return (
                    <Tr
                      borderBottom="2px solid #CCC"
                      color="#000"
                      fontFamily="Rubik"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      <Td>{item && item.nome}</Td>
                      <Td>{formatCnpjCpf()}</Td>
                      <Td>
                        <HStack spacing="2px" maxW="150px" overflow="hidden">
                          <Text
                            maxW="150px"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {item?.procuradores.filter(
                              (pro) => pro.habilitado === true,
                            ).length > 0
                              ? item.procuradores
                                  .filter((pro) => pro.habilitado === true)
                                  .map((itemProc) => itemProc.procurador_nome)
                                  .slice(0, 2)
                                  .join(', ')
                              : 'Nenhum habilitado'}{' '}
                            {item.procuradores.length > 2 &&
                              `+${item && item.procuradores.length - 2}`}
                          </Text>
                        </HStack>
                      </Td>
                      <Td>{formattedCreditors()}</Td>
                      <Td>{formattedValue.coin}</Td>
                      <Td>{formattedValue.valor}</Td>
                      <Td>
                        {filterStatus?.length > 0 ? (
                          <Text>Habilitado({filterStatus?.length})</Text>
                        ) : (
                          <Text>Habilitado(0)</Text>
                        )}
                      </Td>
                      <Td>
                        <HStack spacing="16px">
                          <Button
                            h="25px"
                            borderRadius="71px"
                            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                            fontSize="14px"
                            fontFamily="Rubik"
                            fontWeight="400"
                            color="#FFF"
                            onClick={() => DetailsCreditors(item.id && item.id)}
                          >
                            Ver
                          </Button>

                          {profile === 'aj' && (
                            <ModalDelete
                              name={item && item.nome}
                              id={item.id}
                              callback={() => {
                                tableCreditors();
                                setCurrentPage(1);
                              }}
                            />
                          )}
                        </HStack>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>

          <HStack py="20px" align="center" justify="flex-end">
            {renderPaginationButtons()}
          </HStack>

          {/* <DrawerDetailsCreditors
            isOpen={open}
            onClose={() => setOpen(false)}
            id={creditorId}
            profile={profile}
          /> */}
        </TableContainer>
      )}
    </Box>
  );
};
