import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import InputComponent from '../../../forms/input';
import { ICredores } from '../../hooks/useCredores';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  credor: ICredores;
  updateCredor: (_credor: ICredores) => void;
}

const EditarCredores: React.FC<Props> = ({
  isOpen,
  onClose,
  credor,
  updateCredor,
}) => {
  const methodsForm = useForm<ICredores>({});

  useEffect(() => {
    methodsForm.reset(credor);
  }, [credor]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="unstyled" size="2xl">
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(10px)" />
      <ModalContent bg="#EEEEEE">
        <ModalHeader textAlign="center" fontSize="20px" fontWeight="normal">
          Editar credor
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormProvider {...methodsForm}>
            <form
              onSubmit={methodsForm.handleSubmit((data) => {
                updateCredor(data);
                onClose();
              })}
            >
              <Stack spacing="20px" p="30px">
                <InputComponent name="nome" placeholder="Nome" label="Nome" />
                <InputComponent
                  name="documento"
                  placeholder="Documento"
                  label="Documento"
                />
                <InputComponent
                  name="moeda"
                  placeholder="Moeda"
                  label="Moeda"
                />
                <InputComponent
                  name="valor_original"
                  placeholder="Valor"
                  label="Valor"
                />

                <Button type="submit" mt="20px" bg="primary" color="white">
                  Salvar
                </Button>

                <Button
                  onClick={onClose}
                  variant="outline"
                  borderColor="primary"
                  borderWidth="2px"
                >
                  Cancelar
                </Button>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default EditarCredores;
