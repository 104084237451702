import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputComponent from '../../../../components/forms/input';
import ArrowRight from '../../../../assets/icons/rightArrow.png';
import { api } from '../../../../services/api';

const schema = yup.object().shape({
  token: yup
    .string()
    .required('Campo obrigatório.')
    .test('token-incorrect', 'Token inválido.', async (value) => {
      if (!value) return true;

      try {
        const data = {
          token: value,
        };

        const response = await api.post(
          '/login/password-reset/validate_token/',
          data,
        );
        return response.data.status;
      } catch (error) {
        return false;
      }
    }),
});

export const InsertCode = ({ handlePrevious, handleNext }: any) => {
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      token: '',
    },
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await api.post(
        '/login/password-reset/validate_token/',
        data,
      );
      if (response.status === 200) {
        handleNext(data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Box display="flex" flexDir="column" alignItems="center">
      <Text color="#222" fontFamily="Rubik" fontSize="36px" fontWeight="500">
        Inserir código
      </Text>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack mt="41px" spacing="15px">
            <InputComponent
              w="370px"
              h="53px"
              placeholder="Código"
              _placeholder={{
                color: '#AAA',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: '400',
              }}
              name="token"
            />
            <Button
              borderRadius="31px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 15px 30px 0px rgba(117, 76, 20, 0.25)"
              color="white"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              type="submit"
            >
              Continuar
              <Image src={ArrowRight} ml="17px" />
            </Button>
          </Stack>
        </form>
      </FormProvider>

      <Stack
        mt="30px"
        w="100%"
        display="flex"
        flexDir="column"
        alignItems="center"
        spacing="15px"
      >
        <Box bg="#DDD" h="1px" w="100%" />
        <Text
          color="#222"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          onClick={() => handlePrevious()}
          cursor="pointer"
        >
          Voltar
        </Text>
      </Stack>
    </Box>
  );
};
