import {
  ChatClient,
  LiveStreamClient,
  Participant,
  RecordingClient,
  Stream,
  VideoClient,
} from '@zoom/videosdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import zoomContext from './zoom.context';

interface IStremContext {
  users: Participant[];
  started: boolean;
  stream: typeof Stream | undefined;
  client: typeof VideoClient | undefined;
  live: typeof LiveStreamClient | undefined;
  currentUser: Participant | undefined;
  callId?: string;
  currentUserBackend?: CurrentUserBackend;
  getUsers: () => void;
  cloudRecord: typeof RecordingClient | undefined;
  sairDaAssembleia: () => Promise<void>;
  chatClient: typeof ChatClient | undefined;
  isAdmin?: boolean;

  //
  setStream: React.Dispatch<React.SetStateAction<typeof Stream | undefined>>;
  setCurrentUser: React.Dispatch<React.SetStateAction<Participant | undefined>>;
  setLive: React.Dispatch<
    React.SetStateAction<typeof LiveStreamClient | undefined>
  >;
  setChatClient: React.Dispatch<
    React.SetStateAction<typeof ChatClient | undefined>
  >;
  setCloudRecord: React.Dispatch<
    React.SetStateAction<typeof RecordingClient | undefined>
  >;
  encerrarAssembleia: () => Promise<void>;
}

const StreamContext = createContext<IStremContext>({} as IStremContext);

type CurrentUserBackend = {
  id: number;
  name: string;
  email: string;
  admin?: boolean;
  avatar: string;
};

interface Props {
  callId?: string;
  currentUserBackend?: CurrentUserBackend;
  children: React.ReactNode;
}

export const StreamProvider: React.FC<Props> = ({
  children,
  callId,
  currentUserBackend,
}) => {
  const client = useContext(zoomContext);

  const [currentUser, setCurrentUser] = useState<Participant>();
  const [stream, setStream] = useState<typeof Stream>();
  const [live, setLive] = useState<typeof LiveStreamClient>();
  const [chatClient, setChatClient] = useState<typeof ChatClient>();
  const [cloudRecord, setCloudRecord] = useState<typeof RecordingClient>();
  const [users, setUsers] = useState<Participant[]>([]);
  const [started, setStarted] = useState(false);
  const isAdmin = currentUserBackend?.admin;

  useEffect(() => {
    client.on('user-added', getUsers);
    client.on('user-removed', getUsers);
    client.on('user-updated', getUsers);

    return () => {
      client.off('user-added', getUsers);
      client.off('user-removed', getUsers);
      client.off('user-updated', getUsers);
    };
  }, [client]);

  const getUsers = async () => {
    try {
      const AllUsers = client.getAllUser();

      setUsers(AllUsers);
    } catch (error) {
      console.log('erro ao carregar usuarios: ', error);
    }
  };

  const sairDaAssembleia = async () => {
    try {
      await client.leave();
      setStarted(false);
    } catch (error) {
      console.log('[EXIT CLIENT] => ', error);
    }
  };

  const encerrarAssembleia = async () => {
    try {
      await client.leave(true);
      setStarted(false);
    } catch (error) {
      console.log('[EXIT CLIENT] => ', error);
    }
  };

  return (
    <StreamContext.Provider
      value={{
        users,
        started,
        stream,
        live,
        client,
        currentUser,
        callId,
        currentUserBackend,
        getUsers,
        cloudRecord,
        sairDaAssembleia,
        chatClient,
        setStream,

        isAdmin,
        //
        setCurrentUser,
        setLive,
        setChatClient,
        setCloudRecord,
        encerrarAssembleia,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export const useStream = () => {
  const context = React.useContext<IStremContext>(StreamContext);
  if (context === undefined) {
    throw new Error('useStream must be used within a StreamProvider');
  }

  return context;
};
