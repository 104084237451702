import { Avatar, Box, Button, HStack, Text, Wrap } from '@chakra-ui/react';
import React, { useState } from 'react';
import { IAGCCredorProcurador } from '../../../hooks/useCredores';
import { IRespostas } from '../../../hooks/usePerguntas';

interface Props {
  credor: IAGCCredorProcurador;
  onVote: (_credorId: number, _id: number) => void;
  respostas: IRespostas[];
}

const CardCredorVotar: React.FC<Props> = ({ respostas, credor, onVote }) => {
  const [voto, setVoto] = useState<number>();

  const handleVoto = (idReposta: number) => {
    setVoto(idReposta);
    onVote(credor.id, idReposta);
  };

  const findVoto = respostas.find((a) => a.id === voto);

  return (
    <HStack borderBottom="1px solid #ddd" py="10px">
      <Avatar />
      <Box flex={1}>
        <Text>{credor.agc_credor_nome}</Text>
        <Text color={findVoto?.cor_resposta} fontSize="14px">
          {findVoto?.resposta}
        </Text>
      </Box>

      <Wrap spacing="0">
        {respostas.map((resposta) => {
          const isVoto = voto === resposta.id;

          return (
            <Button
              variant="unstyled"
              onClick={() => handleVoto(resposta!.id!)}
              bg={
                isVoto
                  ? 'radial-gradient(68.62% 148.68% at 63.77% 2.63%, #FFD200 0%, #DF8E25 100%)'
                  : 'transparent'
              }
              p="10px 30px"
              fontSize="14px"
              color={isVoto ? 'white' : undefined}
              border={isVoto ? 'none' : '2px solid #ddd'}
            >
              {resposta?.resposta}
            </Button>
          );
        })}
      </Wrap>
    </HStack>
  );
};

export default CardCredorVotar;
