import {
  Button,
  HStack,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { api } from '../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: number;
}

interface PropsDocs {
  arquivo_link: string | undefined;
  data_documento: string | undefined;
  dialogo_ativo: boolean;
  status: string | undefined;
  tipo_documento: string | undefined;
}

export const ModalView: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const [document, setDocument] = useState<PropsDocs>();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getDocs();
  }, [id]);

  const getDocs = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/agc-credor-procurador-documento/${id}/`);
      if (response.status === 200 || response.status === 201) {
        setDocument(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formattedData = () => {
    if (document?.data_documento) {
      const today: Date = new Date(document?.data_documento);
      const month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      const day =
        today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      const hours =
        today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes =
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();

      const dateNow = `${day}/${month}/${today.getFullYear()} - ${hours}:${minutes} AM`;

      return dateNow;
    }

    return '';
  };

  const status = () => {
    /* eslint no-else-return: "error" */
    if (document?.status === 'em-analise') {
      return 'Em análise';
    } else if (document?.status === 'aprovado') {
      return 'Aprovado';
    }
    return 'Reprovado';
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p="35px">
          <Stack spacing="10px">
            <Skeleton isLoaded={!loading}>
              <Stack spacing="0px">
                <Text color="#06152B" fontFamily="Rubik" fontWeight="400">
                  Nome
                </Text>
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontWeight="400"
                  lineHeight="28px"
                  letterSpacing="1px"
                >
                  {document?.tipo_documento}
                </Text>
              </Stack>
            </Skeleton>

            <HStack justify="space-between">
              <Skeleton isLoaded={!loading}>
                <Stack spacing="0px">
                  <Text color="#06152B" fontFamily="Rubik" fontWeight="400">
                    Data
                  </Text>
                  <Text
                    color="#444"
                    fontFamily="Rubik"
                    fontWeight="400"
                    lineHeight="28px"
                    letterSpacing="1px"
                  >
                    {formattedData()}
                  </Text>
                </Stack>
              </Skeleton>

              <Skeleton isLoaded={!loading}>
                <Stack spacing="0px">
                  <Text color="#06152B" fontFamily="Rubik" fontWeight="400">
                    Status
                  </Text>
                  <Text
                    color="#444"
                    fontFamily="Rubik"
                    fontWeight="400"
                    lineHeight="28px"
                    letterSpacing="1px"
                  >
                    {status()}
                  </Text>
                </Stack>
              </Skeleton>
            </HStack>

            <Link
              href={document?.arquivo_link}
              target="_blank"
              borderRadius="71px"
              w="136px"
              h="40px"
            >
              <Button
                borderRadius="71px"
                w="100%"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                _hover={{
                  bg: 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
                }}
                color="#FFF"
                fontFamily="Rubik"
                fontWeight="400"
                letterSpacing="0.5px"
                fontSize="22px"
                h="40px"
              >
                Ver
              </Button>
            </Link>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
