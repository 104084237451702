import React, { useEffect, useState } from 'react';
import {
  Box,
  Center,
  HStack,
  Image,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import { VideoComponent } from '../../components/meet-sdk';
import { useAuth } from '../../context/AuthContext';
import { api } from '../../services/api';

const MeetPage: React.FC = () => {
  const { user } = useAuth();
  const [profile, setProfile] = useState<{ perfil?: string }>({});
  const callId = new URLSearchParams(window.location.search).get('id');

  useEffect(() => {
    if (user.id) {
      api.get(`/agc/${callId}/perfil-usuario-logado/`).then((res) => {
        setProfile(res.data);
      });
    }
  }, [user.id]);

  if (!user?.id || !callId || !profile?.perfil) {
    return (
      <Stack align="center" justify="center" h="100vh" w="100vw" bg="white">
        <Spinner />
      </Stack>
    );
  }

  if (profile?.perfil === 'nunhum') {
    <Box h="100vh">
      <HStack
        bg="#F2F2F2"
        px="50px"
        h="70px"
        w="full"
        shadow="lg"
        zIndex={10}
        pos="relative"
        justify="space-between"
      >
        <Image src="/logo.png" w="53px" h="53px" />
      </HStack>

      <Center h="full">
        <Text>Você não tem autorização para entrar nessa AGC</Text>
      </Center>
    </Box>;
  }

  return (
    <Box h="100vh">
      <VideoComponent
        callId={callId}
        currentUserBackend={{
          email: user.email,
          id: user.id,
          name: `${user.nome} ${user.sobrenome}`,
          avatar: '',
          admin: profile?.perfil.includes('aj'),
        }}
      />
    </Box>
  );
};

export default MeetPage;
