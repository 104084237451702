import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import React, { useContext } from 'react';
import './report-btn.scss';
import zoomContext from '../../../../meet-sdk/context/zoom.context';

const ReportBtn = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const zmClient = useContext(zoomContext);
  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Successfully reported the log.',
    });
  };
  const onReportClick = async () => {
    try {
      await zmClient.getLoggerClient().reportToGlobalTracing();
      success();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {contextHolder}
      <div>
        <Button
          type="link"
          className="report-button"
          icon={<InfoCircleOutlined />}
          size="large"
          onClick={onReportClick}
        />
      </div>
    </>
  );
};

export default ReportBtn;
