import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import React, { useState } from 'react';

import { AjAgcs } from './components/aj';
import { MemberTeamAgcs } from './components/memberTeam';
import { CreditorAgcs } from './components/creditors';
import { RecoveringAgcs } from './components/recovering';

export const MyAgcs: React.FC = () => {
  const [indexTabs, setIndexTabs] = useState(0);

  return (
    <Box p="20px 40px 0px 14px">
      <Tabs variant="enclosed" onChange={(index) => setIndexTabs(index)} isLazy>
        <TabList>
          <Tab
            border="none"
            _selected={{
              bg: '#F2F2F2',
              boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              color: '#222',
            }}
            w="269px"
            background="#FFFFFF"
            borderTopRadius="30px"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            color="#AAA"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            AJ
          </Tab>
          <Tab
            border="none"
            _selected={{
              bg: '#F2F2F2',
              boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              color: '#222',
            }}
            w="269px"
            background="#FFFFFF"
            borderTopRadius="30px"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            color="#AAA"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Membro de equipe
          </Tab>
          <Tab
            border="none"
            _selected={{
              bg: '#F2F2F2',
              boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              color: '#222',
            }}
            w="269px"
            background="#FFFFFF"
            borderTopRadius="30px"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            color="#AAA"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Credor
          </Tab>
          <Tab
            border="none"
            _selected={{
              bg: '#F2F2F2',
              boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              color: '#222',
            }}
            w="269px"
            background="#FFFFFF"
            borderTopRadius="30px"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            color="#AAA"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Recuperanda
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AjAgcs index={indexTabs} />
          </TabPanel>
          <TabPanel>
            <MemberTeamAgcs index={indexTabs} />
          </TabPanel>
          <TabPanel>
            <CreditorAgcs index={indexTabs} />
          </TabPanel>
          <TabPanel>
            <RecoveringAgcs index={indexTabs} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
