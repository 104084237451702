import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Select,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { getAuthStorage } from '../../../../services/storage';
import CheckIcon from '../../../../assets/icons/checkIcon.png';
import { api } from '../../../../services/api';
import { ListStates } from '../../../../constants/listStates';
import { ModalEdits } from '../../../../components/modals/modalEdits';

const validationSchema = yup.object().shape({
  cep: yup
    .string()
    .min(8, 'Digite um CEP válido.')
    .max(8, 'Digite um CEP válido.'),
});

export const Address: React.FC = () => {
  const [dataUser, setDataUser] = useState({
    id: 0,
    numero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    cpf: '',
    email: '',
    endereco: '',
  });

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    const getDataUser = async () => {
      const user = await getAuthStorage();
      try {
        const response = await api.get(`/usuario/registro/${user.user_id}/`);
        const { data } = response;
        if (response.status === 200 || response.status === 201) {
          setDataUser(data);
        }
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error.response);
      }
    };

    getDataUser();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setDataUser({
      ...dataUser,
      [name]: value,
    });
  };

  const updateDataUser = async () => {
    try {
      await validationSchema.validate(dataUser, { abortEarly: false });

      const data = {
        numero: dataUser.numero,
        complemento: dataUser.complemento,
        bairro: dataUser.bairro,
        cidade: dataUser.cidade,
        uf: dataUser.uf,
        cep: dataUser.cep,
        cpf: dataUser.cpf,
        email: dataUser.email,
        endereco: dataUser.endereco,
      };
      const response = await api.put(`/usuario/registro/${dataUser.id}/`, data);
      if (response.status === 200 || response.status === 201) {
        setValidationErrors({});
        onOpen();
      }
    } catch (error: any) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const ufs = Object.entries(ListStates);

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <Stack mt="30px" spacing="35px">
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Logradouro
          </Text>
          <Input
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.endereco}
            onChange={handleChange}
            name="endereco"
          />
        </Flex>

        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Numero
          </Text>
          <Input
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.numero}
            onChange={handleChange}
            name="numero"
          />
        </Flex>
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Complemento
          </Text>
          <Input
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.complemento}
            onChange={handleChange}
            name="complemento"
          />
        </Flex>

        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Bairro
          </Text>
          <Input
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.bairro}
            onChange={handleChange}
            name="bairro"
          />
        </Flex>

        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Cidade
          </Text>
          <Input
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.cidade}
            onChange={handleChange}
            name="cidade"
          />
        </Flex>
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Estado
          </Text>
          <Select
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.uf}
            onChange={handleChange}
            name="uf"
            background="#FFF"
            borderRadius="43px"
            _focus={{ border: 'none' }}
            _focusVisible={{ border: 'none' }}
            placeholder={dataUser.uf}
          >
            {ufs.map((states) => {
              const id = states[0];
              const label = states[0];
              return (
                <option key={id} value={id}>
                  {label}
                </option>
              );
            })}
          </Select>
        </Flex>
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            CEP
          </Text>
          <Box
            w="360px"
            alignItems="flex-start"
            display="flex"
            flexDir="column"
          >
            <Input
              fill="#FFF"
              filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
              h="56px"
              value={dataUser.cep}
              onChange={handleChange}
              name="cep"
            />
            {validationErrors.cep ? (
              <Text
                textAlign="start"
                marginLeft="15px"
                marginTop="8px !important"
                lineHeight="12px"
                fontSize="14px"
                color="red.400"
                textStyle="body.small"
              >
                {validationErrors.cep}
              </Text>
            ) : null}
          </Box>
        </Flex>
      </Stack>
      <Button
        w="170px"
        h="51px"
        borderRadius="105px"
        background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
        m="35px 51px 48px 0px"
        leftIcon={<Image src={CheckIcon} />}
        onClick={updateDataUser}
        boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
      >
        <Text color="#FFF" fontFamily="Rubik" fontSize="14px" fontWeight="400">
          Salvar
        </Text>
      </Button>

      <ModalEdits isOpen={isOpen} onClose={onClose} type="endereco" />
    </Box>
  );
};
