import { useState } from 'react';
import { useToast } from '@chakra-ui/react';
import { api } from '../../../services/api';

export const useCredores = (agcId: number) => {
  const [filters, setFilters] = useState<{
    limit: number;
    page: number;
    search?: string;
    usuario_id?: number;
  }>({
    page: 1,
    limit: 10,
  });
  const toast = useToast();
  const [credores, setCredores] = useState<ICredores[]>([]);
  const [credoresHabilitados, setCredoresHabilitados] = useState<ICredores[]>(
    [],
  );
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [credenciamento, setCredenciamento] = useState<IAcompanhamento[]>([]);
  const [credorProcuradors, setCredorProcuradors] = useState<
    IAGCCredorProcurador[]
  >([]);

  const updateCredor = async (credor: ICredores) => {
    try {
      setLoading(true);
      await api.put<ICredores>(`agc-credor/${credor.id}`, {
        nome: credor.nome,
        tipo_documento: credor.tipo_documento,
        documento: credor.documento,
        agc_classe_id: credor.agc_classe_id,
        moeda: credor.moeda,
        valor_original: credor.valor_original,
        procuradores: credor.procuradores,
      });
      await getCredores();
      setLoading(false);
      toast({
        title: 'Credor atualizado com sucesso!',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Erro ao atualizar credor!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getOffset = (page: number, limit: number) => {
    return page * limit - limit;
  };

  const getCredores = async () => {
    try {
      setLoading(true);
      const res = await api.get<IResultCredor>('agc-credor', {
        params: {
          agc_id: agcId,
          limit: filters?.limit ?? 10,
          offset: getOffset(filters?.page ?? 0, filters?.limit ?? 10),
          search: filters?.search ?? '',
          usuario_id: filters?.usuario_id,
        },
      });
      setCount(res.data?.count ?? 0);
      setCredores(res.data?.results ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const deleteCredor = async (id: number) => {
    try {
      setLoading(true);
      await api.delete<ICredores>(`agc-credor/${id}`);
      await getCredores();
      setLoading(false);
    } catch (error) {
      toast({
        title: 'Erro ao deletar credor!',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const getCredenciamento = async () => {
    try {
      const res = await api.get<IAcompanhamento[]>(
        `/agc/${agcId}/acompanhamento-credenciamento/`,
      );
      setCredenciamento(res.data);
    } catch (error) {
      //
    }
  };

  const getAcompanhamentoCredenciamento = async () => {
    try {
      const res = await api.get<IAcompanhamento[]>(
        `/agc/${agcId}/acompanhamento-credenciamento-totalizar/`,
      );
      setCredenciamento(res.data);
    } catch (error) {
      //
    }
  };

  const getAgcCredorProcurador = async (userId: number) => {
    try {
      const res = await api.get<IResultAGCCredorProcurador>(
        '/agc-credor-procurador/',
        {
          params: {
            agc_id: agcId,
            usuario_id: userId,
            credenciado: true,
            habilitado: true,
          },
        },
      );
      setCredorProcuradors(res.data.results);
    } catch (error) {
      //
    }
  };

  const getCredoresHabilitados = async () => {
    try {
      setLoading(true);
      const res = await api.get('/agc-credor/credores-habilitados/');
      setCredoresHabilitados(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return {
    getCredoresHabilitados,
    filters,
    setFilters,
    getAcompanhamentoCredenciamento,
    getAgcCredorProcurador,
    credenciamento,
    credorProcuradors,
    updateCredor,
    getCredores,
    deleteCredor,
    credores,
    loading,
    count,
    credoresHabilitados,
    getCredenciamento,
  };
};

export interface IAcompanhamento {
  classe: string;
  credor: string;
  valor_total: number;
  valor_confirmado: number;
  quantidade_confirmado: number;
  percentual: number;
}

export interface ICredores {
  id: number;
  agc_id: number;
  nome: string;
  tipo_documento: string;
  documento: string;
  agc_classe_id: number;
  agc_classe_classe: string;
  moeda: string;
  valor_original: number;
  valor: number;

  procuradores: {
    habilitado: boolean;
  }[];
}

export interface IResultCredor {
  count: number;
  next: string;
  previous: string;
  results: ICredores[];
}

export interface IAGCCredorProcurador {
  id: number;
  procurador_id: number;
  procurador_nome: string;
  agc_credor_id: number;
  agc_credor_nome: string;
  agc_credor_agc_id: number;
  agc_credor_agc_nome: string;
  habilitado: boolean;
  credenciado: boolean;
  data_credenciamento: string;
}

export interface IResultAGCCredorProcurador {
  count: number;
  next: string;
  previous: string;
  results: IAGCCredorProcurador[];
}
