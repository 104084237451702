import { Box, Button, HStack, Icon, Stack, Text } from '@chakra-ui/react';
import { Participant } from '@zoom/videosdk';
import React from 'react';
import { BiSolidUser } from 'react-icons/bi';
import { useVideoActions } from '../../context/video-actions.context';

interface Props {
  user: Participant;
  index: number;
}

const UserCardRequest: React.FC<Props> = ({ user, index }) => {
  const { toggleAudio, removeRequestedMicrophone } = useVideoActions();

  return (
    <HStack
      pb="10px"
      key={String(user.userId)}
      justify="space-between"
      spacing="14px"
    >
      <Stack
        align="center"
        justify="center"
        w="45px"
        h="45px"
        borderRadius="50px"
        bg="gray.100"
      >
        <Icon as={BiSolidUser} color="gray.400" fontSize="22px" />
      </Stack>

      <Box flex={1}>
        <Text fontSize="12px" fontWeight="bold" color="rgba(223, 145, 38, 1)">
          {index + 1}° lugar na fila
        </Text>
        <Text fontSize="14px">{user?.displayName}</Text>
      </Box>

      <Button
        color="white"
        fontSize="12px"
        p="0px 20px"
        h="34px"
        bg="#DCAC36"
        borderRadius="40px"
        onClick={() => {
          toggleAudio(user.userId, true);
          removeRequestedMicrophone(user);
        }}
      >
        Autorizar
      </Button>
    </HStack>
  );
};

export default UserCardRequest;
