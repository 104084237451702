import { Button, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '../../../../assets/icons/personIcon.svg';
import Peoples from '../../../../assets/icons/peoples.svg';
import Participant from '../../../../assets/images/participant.png';
import Close from '../../../../assets/icons/close.svg';
import ArrowLeft from '../../../../assets/icons/leftArrow.svg';
import Check from '../../../../assets/icons/checkIcon.png';
import { api } from '../../../../services/api';

interface Props {
  handlePrevious: () => void;
  formData: any;
  formClasses: any;
}

export const FormThreeAgc: React.FC<Props> = ({
  handlePrevious,
  formData,
  formClasses,
}) => {
  const participants = [
    { name: 'Luan', img: Participant },
    { name: 'Lincoln', img: Participant },
    { name: 'Lincoln', img: Participant },
    { name: 'Lincoln', img: Participant },
  ];

  const dataCredenciamentoFormatted = `${formData.data_credenciamento}T${formData.hora_credenciamento}`;

  const navigate = useNavigate();

  const createAgc = async () => {
    const data = {
      aj_id: 2,
      numero_processo: formData.numero_processo,
      natureza: formData.natureza,
      nome: formData.nome,
      observacao: formData.observacao,
      data_credenciamento: dataCredenciamentoFormatted,
      hora_instalacao: formData.hora_instalacao,
      convocacao: formData.convocacao,
    };
    try {
      const response = await api.post('/agc/', data);
      if (response.status === 201) {
        formClasses.forEach(async (valor: any) => {
          try {
            const dataClasse = {
              agc_id: response.data.id,
              classe: valor,
            };
            await api.post('/agc-classe/', dataClasse);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
        });
        window.location.href = `/agcs/${response.data.id}`;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Stack mt="33px" spacing="213px">
      <Stack spacing="51px">
        <Stack spacing="22px">
          <HStack spacing="33px">
            <Image src={PersonIcon} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              Participantes
            </Text>
          </HStack>

          <HStack spacing="17px">
            {participants.map((item) => (
              <Flex
                bg="#FFF"
                borderRadius="37px"
                boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
                p="11px 30px 11px 11px"
                align="center"
              >
                <Image src={item.img} />
                <Text
                  textAlign="center"
                  color="#222"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  ml="20px"
                >
                  {item.name}
                </Text>
              </Flex>
            ))}
          </HStack>

          <Button
            borderRadius="19px"
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            h="32px"
            w="164px"
            color="#FFF"
            fontFamily="Rubik"
            fontSize="12px"
            fontWeight="500"
          >
            Adicionar participante
          </Button>
        </Stack>

        <HStack spacing="106px">
          <HStack spacing="25px">
            <Image src={Peoples} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="30px"
              fontWeight="400"
            >
              24 Credores
            </Text>
          </HStack>

          <Button
            borderRadius="75px"
            background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            color="#FFF"
            fontFamily="Rubik"
            fontSize="12px"
            fontWeight="500"
            h="34px"
            onClick={() => navigate('/creditors')}
          >
            Ver todos os credores
          </Button>
        </HStack>
      </Stack>

      <HStack spacing="29px">
        <Button
          h="51px"
          borderRadius="45px"
          bg="none"
          border="1px solid #DF8E25"
          color="#000"
          fontFamily="Rubik"
          fontSize="14px"
          fontWeight="400"
          onClick={() => navigate('/agcs')}
        >
          <Image src={Close} pr="10px" />
          Cancelar
        </Button>

        <Button
          h="51px"
          borderRadius="31px"
          color="white"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          p="16px 27px"
          letterSpacing="0.8px"
          onClick={handlePrevious}
          boxShadow="0px 15px 30px 0px rgba(117, 76, 20, 0.25)"
        >
          <Image src={ArrowLeft} paddingRight="17px" />
          Voltar
        </Button>

        <Button
          h="51px"
          borderRadius="31px"
          color="white"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          p="16px 27px"
          letterSpacing="0.8px"
          onClick={() => createAgc()}
          boxShadow="0px 15px 30px 0px rgba(117, 76, 20, 0.25)"
        >
          <Image src={Check} paddingRight="17px" />
          Confirmar
        </Button>
      </HStack>
    </Stack>
  );
};
