import React, { FC, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import ZoomVideo from '@zoom/videosdk';
import ZoomContext from './context/zoom.context';
import { ZoomComponent } from './video';
import { StreamProvider } from './context/stream.context';
import { VideoActionProvider } from './context/video-actions.context';
import { ChatProvider } from './hooks/useChat';
import { DialogProvider } from './context/useDialog';
import { WebsocketProvider } from './hooks/useWebsocket';

interface Props {
  callId: string;
  currentUserBackend: {
    id: number;
    name: string;
    email: string;
    admin?: boolean;
    avatar?: string;
  };
}

export const VideoComponent: FC<Props> = ({ callId, currentUserBackend }) => {
  const client = ZoomVideo.createClient();

  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      client.leave();
    });
    return () => {
      window.removeEventListener('beforeunload', (e) => {
        e.preventDefault();
        client.leave();
      });
    };
  }, []);

  return (
    <Box h="calc(100vh - 80px)">
      <DialogProvider>
        <ZoomContext.Provider value={client}>
          <StreamProvider
            callId={callId as string}
            currentUserBackend={currentUserBackend as any}
          >
            <VideoActionProvider>
              <WebsocketProvider callId={callId as string}>
                <ChatProvider>
                  <ZoomComponent />
                </ChatProvider>
              </WebsocketProvider>
            </VideoActionProvider>
          </StreamProvider>
        </ZoomContext.Provider>
      </DialogProvider>
    </Box>
  );
};
