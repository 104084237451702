import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react';
import { User, UserPlus } from '@phosphor-icons/react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAuth } from '../../../context/AuthContext';
import background from '../../../assets/images/backgroundStart.png';
import logo from '../../../assets/images/logoWhite.png';
import keys from '../../../assets/icons/keys.png';
import InputComponent from '../../../components/forms/input';

const schema = yup.object().shape({
  cpf: yup
    .string()
    .required('Email obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido'),
  password: yup.string().required('Senha obrigatória'),
});

const Login: React.FC = () => {
  const [errorExist, setErrorExist] = useState<string>('');

  const navigate = useNavigate();
  const { login } = useAuth();

  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      cpf: '',
      password: '',
    },
  });

  const handleLogin = async (data: any) => {
    try {
      const cleanData = { ...data };

      if (cleanData.cpf) {
        cleanData.cpf = cleanData.cpf.replace(/\D/g, '');
      }

      await login(cleanData);
    } catch (error: any) {
      console.log(error.response);
      const { detail } = error.response.data;
      setErrorExist(detail);
    }
  };

  return (
    <Box
      minH="100vh"
      backgroundImage={`url(${background})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <header>
        <Flex justifyContent="space-between" padding="40px">
          <Image src={logo} w="53px" h="53px" />

          <Button
            leftIcon={<UserPlus color="#DCAC36" weight="bold" />}
            color="#FFFFFF"
            paddingX="35px"
            backgroundColor="#31302F"
            boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
            onClick={() => navigate('/register')}
          >
            Cadastre-se
          </Button>
        </Flex>
      </header>
      <main>
        <Center>
          <Box
            w="450px"
            bgColor="#EEEEEE"
            px="49px"
            py="38px"
            borderRadius="37px"
            boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
          >
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(handleLogin)}>
                <Center>
                  <Text fontSize="36px" fontWeight="500" fontFamily="Rubik">
                    Faça o Login
                  </Text>
                </Center>

                <Stack spacing="15px" mt="41px">
                  <InputComponent
                    name="cpf"
                    mask="***.***.***-**"
                    placeholder="CPF"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontWeight: '400',
                      fontSize: 16,
                    }}
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  />

                  <Stack spacing="0px">
                    <InputComponent
                      name="password"
                      placeholder="Senha"
                      type="password"
                      _placeholder={{
                        color: '#AAA',
                        fontFamily: 'Rubik',
                        fontWeight: '400',
                        fontSize: 16,
                      }}
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                    />
                    {errorExist && (
                      <Text color="red" fontSize="14px">
                        {errorExist}
                      </Text>
                    )}
                  </Stack>

                  <Button
                    background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #31302F"
                    color="#FFFFFF"
                    // isDisabled={formMethods.formState.isSubmitting}
                    // isLoading={formMethods.formState.isSubmitting}
                    type="submit"
                    w="full"
                    leftIcon={<User color="#FFFFFF" weight="bold" />}
                  >
                    Fazer Login
                  </Button>
                </Stack>

                <Divider my="20px" borderColor="#DDDDDD" />

                <Center>
                  <Button
                    onClick={() => {
                      navigate('/lost-password');
                    }}
                    color="#222"
                    variant="link"
                    leftIcon={<Image src={keys} />}
                    boxShadow="none"
                    fontFamily="Rubik"
                    fontWeight="400"
                  >
                    Perdeu a senha?
                  </Button>
                </Center>
              </form>
            </FormProvider>
          </Box>
        </Center>
      </main>
    </Box>
  );
};

export default Login;
