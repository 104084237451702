/* eslint-disable no-plusplus */
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import { SearchIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../services/api';
import Order from '../../../assets/icons/UpDown.svg';

interface Props {
  id: string | undefined;
}

interface PropsMyCreditors {
  id: number;
  procurador_nome: string | undefined;
  agc_credor_nome: string | undefined;
  agc_credor_agc_nome: string | undefined;
  habilitado: boolean;
  credenciado: boolean;
  agc_credor_id: number;
}

export const MyCreditors: React.FC<Props> = ({ id }) => {
  const navigate = useNavigate();

  const [myCreditors, setMyCreditors] = useState<PropsMyCreditors[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [filterText, setFilterText] = useState<string>('');

  useEffect(() => {
    if (filterText.length >= 5 || filterText.length === 0) {
      getMyCreditors();
    }
  }, [currentPage, filterText]);

  const itemsPerPage = 5;

  const userId = localStorage.getItem('userId');

  const getMyCreditors = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/agc-credor-procurador/?agc_id=${id}&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }&usuario_id=${userId}&search=${filterText}`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results, count } = response.data;
        setMyCreditors(results);
        setLoading(false);
        setTotalRecords(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const headTable = [
    { title: 'Nome do credor da AGC' },
    { title: 'Habilitado' },
    { title: 'Credenciado' },
    { title: 'Ações' },
  ];

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 3;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const paginationButtons = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
    }

    if (startPage !== 1) {
      paginationButtons.push(
        <Button
          key={1}
          onClick={() => handlePageChange(1)}
          fontWeight={currentPage === 1 ? 'bold' : 'normal'}
          color={currentPage === 1 ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === 1
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          1
        </Button>,
      );
    }

    if (startPage > 2) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          fontWeight={currentPage === i ? 'bold' : 'normal'}
          color={currentPage === i ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === i
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {i}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    if (endPage !== totalPages) {
      paginationButtons.push(
        <Button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          fontWeight={currentPage === totalPages ? 'bold' : 'normal'}
          color={currentPage === totalPages ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === totalPages
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {totalPages}
        </Button>,
      );
    }

    return paginationButtons;
  };

  const DetailsCreditors = (idCred: any) => {
    navigate(`/agcs/${id}/creditorBond/${idCred}`);
  };

  const handleFilterChange = (event: any) => {
    const newFilterText = event.target.value;
    setFilterText(newFilterText);
  };

  return (
    <Box>
      <InputGroup>
        <InputLeftElement mt="4px" pl="36px">
          <SearchIcon color="#AAA" w="20px" h="20px" />
        </InputLeftElement>
        <Input
          boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.05)"
          placeholder="Pesquisa por nome"
          _placeholder={{
            color: '#888',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '28px',
            letterSpacing: '1.6px',
          }}
          pl="79px"
          h="56px"
          onChange={handleFilterChange}
        />
      </InputGroup>
      {loading ? (
        <Flex justify="center" pt="20px">
          <Spinner size="xl" color="#DF8E25" />
        </Flex>
      ) : (
        <TableContainer mt="45px">
          <Table variant="simple">
            <Thead borderBottom="2px solid #9B9B9B">
              <Tr>
                {headTable.map((item) => (
                  <Th
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    <HStack>
                      <Text>{item.title}</Text>
                      <button>
                        <Image src={Order} />
                      </button>
                    </HStack>
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {myCreditors.map((item) => {
                const formattedCredential = (value: boolean) => {
                  if (value === true) {
                    return 'Sim';
                  }
                  return 'Não';
                };

                return (
                  <Tr
                    borderBottom="2px solid #CCC"
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    <Td>{item.agc_credor_nome}</Td>
                    <Td>{formattedCredential(item.habilitado)}</Td>
                    <Td>{formattedCredential(item.credenciado)}</Td>
                    <Td>
                      <Button
                        h="25px"
                        borderRadius="71px"
                        bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                        fontSize="14px"
                        fontFamily="Rubik"
                        fontWeight="400"
                        color="#FFF"
                        onClick={() => DetailsCreditors(item.agc_credor_id)}
                      >
                        Ver
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <HStack py="20px" align="center" justify="flex-end">
            {renderPaginationButtons()}
          </HStack>
        </TableContainer>
      )}
    </Box>
  );
};
