/* eslint no-underscore-dangle: 0 */
import {
  Box,
  Grid,
  HStack,
  Image,
  Spinner,
  Stack,
  Tag,
  TagLabel,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CheckboxAgc } from '../../registerAgc/components/checkbox';
import StackIcon from '../../../assets/icons/Stack.svg';
import Dollar from '../../../assets/icons/dollar.svg';
import Pencil from '../../../assets/icons/pencilData.svg';
import { api } from '../../../services/api';
import IconAlert from '../../../assets/icons/iconAlert.svg';
import { DrawerEdit } from './components/drawerEdit';

export const DetailsAgc = ({
  closed,
  formatTime,
  noFormatTime,
  formattedData,
  formatedTime,
  nProcess,
  name,
  nature,
  suspended,
  id,
  profile,
  nameAj,
  date,
}: any) => {
  const [price, setPrice] = useState<
    {
      id: number;
      agc_id: number;
      moeda: string;
      valor_cotacao: number;
    }[]
  >([]);

  const [classe, setClasse] = useState([{ id: 0, agc_id: 0, classe: '' }]);

  // const [selectedPrices, setSelectedPrices] = useState<
  //   Array<{ agcId: any; index: number; value: string; valorCotacao: number }>
  // >([]);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorExist, setErrorExist] = useState('');

  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    getPrice();
    getClasses();
  }, []);

  const classes = [
    { name: 'Trabalhista', value: 'trabalhista' },
    { name: 'Garantia Real', value: 'garantia-real' },
    { name: 'Quirografário', value: 'quirografario' },
    { name: 'Microempresa', value: 'microempresa' },
  ];

  const getPrice = async () => {
    try {
      const response = await api.get(`/agc-cotacao/?agc_id=${id}`);
      const { results } = response.data;
      if (response.status === 200 || response.status === 201) {
        setPrice(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // const { onClose } = useDisclosure();

  const getClasses = async () => {
    try {
      const response = await api.get(`/agc-classe/?agc_id=${id}`);
      const { results } = response.data;
      if (response.status === 200) {
        setClasse(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = async (value: any) => {
    setLoading(true);

    try {
      const classIndex = classe.findIndex((e) => e.classe === value);

      if (classIndex === -1) {
        const data = {
          agc_id: id,
          classe: value,
        };
        const response = await api.post('/agc-classe/', data);
        if (response.status === 200 || response.status === 201) {
          setClasse([
            ...classe,
            { id: response.data.id, agc_id: id, classe: value },
          ]);
          setLoading(false);
          setErrorExist('');
        }
      } else {
        const classIdToDelete = classe[classIndex].id;
        const response = await api.delete(`/agc-classe/${classIdToDelete}`);
        if (response.status === 200 || response.status === 204) {
          const newClasse = classe.filter((e) => e.classe !== value);
          setClasse(newClasse);
          setErrorExist('');
        }
      }
    } catch (error: any) {
      const { detail } = error.response.data;
      setErrorExist(detail);
      if (error.response.status === 403) {
        setErrorExist('Existem objetos relacionados a esta classe');
      }
    } finally {
      setLoading(false);
    }
  };

  // const handleChangePrices = (index: number, value: any, valuePrice: any) => {
  //   const existingSelect = selectedPrices.find((item) => item.index === index);

  //   if (existingSelect) {
  //     setSelectedPrices((prevSelected) =>
  //       prevSelected.map((item) =>
  //         item.index === index
  //           ? {
  //               ...item,
  //               value: value !== null ? value : item.value,
  //               valorCotacao:
  //                 valuePrice !== null ? valuePrice : item.valorCotacao,
  //             }
  //           : item,
  //       ),
  //     );
  //   } else {
  //     setSelectedPrices((prevSelectedPrices) => [
  //       ...prevSelectedPrices,
  //       { index, value, agcId: id, valorCotacao: valuePrice },
  //     ]);
  //   }
  // };

  // /* eslint-disable-next-line consistent-return */
  // const savePrices = async () => {
  //   try {
  //     /* eslint-disable-next-line consistent-return */
  //     const promises = selectedPrices.map(async (value: any) => {
  //       const data = {
  //         id: value.id,
  //         agc_id: id,
  //         moeda: value.value,
  //         valor_cotacao: value.valorCotacao,
  //       };

  //       const response = await api.post('/agc-cotacao/', data);

  //       if (response.status === 201 || response.status === 200) {
  //         setSelectedPrices([]);
  //         return data;
  //       }
  //     });

  //     const results = await Promise.all(promises);

  //     const formattedResults =
  //       results &&
  //       results.map((result) => ({
  //         id: result?.id,
  //         agc_id: result?.agc_id,
  //         moeda: result?.moeda,
  //         valor_cotacao: result?.valor_cotacao,
  //       }));

  //     setPrice([...price, ...formattedResults]);
  //     onClose();
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const onSave = (values: any) => {
    const updatedPrice = [...price];

    const index = updatedPrice.findIndex((item) => item.id === values.id);

    if (index !== -1) {
      updatedPrice[index] = { ...updatedPrice[index], ...values };

      setPrice(updatedPrice);
    }
  };

  const hourSplit = formatTime.split(':');
  const targetTime = new Date();
  targetTime.setHours(Number(hourSplit[0]));
  targetTime.setMinutes(Number(hourSplit[1]));
  targetTime.setSeconds(0);

  useEffect(() => {
    const timerId = setInterval(() => {
      const now = new Date();
      const timeDiff = (targetTime.getTime() - now.getTime()) / 1000;
      if (timeDiff <= 0) {
        clearInterval(timerId);
        setRemainingTime(0);
      } else {
        setRemainingTime(timeDiff);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [targetTime]);

  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);

  const dateCred = date.split('T');
  const hourInstalation = `${dateCred[0]}T${noFormatTime}`;

  return (
    <Box bg="#FFF" borderRadius="31px" p="35px">
      <HStack justify="space-between" w="90%" align="flex-start">
        <Box>
          <Stack spacing="26px">
            <Stack spacing="13px">
              <Stack spacing="0px">
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Nome da AGC
                </Text>
                <Text
                  color="#222"
                  fontFamily="Rubik"
                  fontSize="30px"
                  fontWeight="400"
                >
                  {name}
                </Text>
              </Stack>

              <Stack spacing="0px">
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Nome do AJ
                </Text>
                <Text
                  color="#222"
                  fontFamily="Rubik"
                  fontSize="30px"
                  fontWeight="400"
                >
                  {nameAj}
                </Text>
              </Stack>
            </Stack>

            <HStack spacing="121px">
              <Box>
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Nª Processo
                </Text>
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  {nProcess}
                </Text>
              </Box>

              <Box>
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Natureza
                </Text>
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  letterSpacing="1px"
                >
                  {nature === 'recuperacao-judicial'
                    ? 'Recuperação Judicial'
                    : 'Falência'}
                </Text>
              </Box>
            </HStack>
          </Stack>

          <Stack mt="37px" spacing="24px">
            <HStack spacing="56px" align="flex-start">
              <Stack>
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Data e hora de credenciamento
                </Text>
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  letterSpacing="1px"
                >
                  {formattedData} às {formatedTime}
                </Text>
              </Stack>

              <Stack>
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Hora instalação
                </Text>
                {profile === 'aj' && (
                  <Text
                    color="#444"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                    letterSpacing="1px"
                  >
                    {formatTime}
                  </Text>
                )}

                {profile !== 'aj' && (
                  <Box
                    p="8px 16px"
                    borderRadius="8px"
                    border="1px solid #AEAEAE"
                    bg="#FBFBFB"
                    w="100%"
                  >
                    <HStack>
                      <Image src={IconAlert} />
                      <Text
                        color="#000"
                        fontFamily="Rubik"
                        fontSize="12px"
                        fontWeight="400"
                        letterSpacing="0.3px"
                      >
                        {new Date() >= new Date(hourInstalation)
                          ? 'AGC JÁ INSTALADA'
                          : hours > 0
                          ? `A habilitação para AGC termina daqui ${hours} ${
                              hours > 1 ? 'horas' : 'hora'
                            } e ${minutes + 1} ${
                              minutes + 1 === 1 ? 'minuto' : 'minutos'
                            }`
                          : `A habilitação para AGC termina daqui ${
                              minutes + 1
                            } ${minutes + 1 === 1 ? 'minuto' : 'minutos'}`}
                      </Text>
                    </HStack>
                  </Box>
                )}
              </Stack>
            </HStack>

            {profile === 'aj' && (
              <Box
                p="16px"
                border="1px solid #AEAEAE"
                bg="#FBFBFB"
                borderRadius="8px"
                w="58%"
              >
                <HStack spacing="16px">
                  <Image src={IconAlert} />
                  <Text
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    {new Date() >= new Date(hourInstalation)
                      ? 'AGC JÁ INSTALADA'
                      : `Com base no artigo 36, §4o da lei 11.101/05 A Habilitação
                      para AGC deve terminar em ${
                        hours > 0
                          ? hours > 1
                            ? `${hours} horas e`
                            : `${hours} hora e`
                          : ''
                      } ${minutes + 1} ${
                        minutes + 1 > 1 ? 'minutos' : 'minuto'
                      }`}
                  </Text>
                </HStack>
              </Box>
            )}

            <HStack spacing="65px">
              <Box>
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Encerrada
                </Text>
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  letterSpacing="1px"
                >
                  {closed ? 'Sim' : 'Não'}
                </Text>
              </Box>

              <Box>
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Suspensa
                </Text>
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  letterSpacing="1px"
                >
                  {suspended ? 'Sim' : 'Não'}
                </Text>
              </Box>
            </HStack>
          </Stack>
        </Box>

        <Box>
          <Stack spacing="22px">
            <HStack spacing="33px">
              <Image src={StackIcon} />
              <Text>Classes</Text>
            </HStack>
            {loading ? (
              <Spinner color="#DF8E25" size="xl" />
            ) : (
              <Box>
                <Grid templateColumns="repeat(2, 1fr)" gap="36px">
                  {classes &&
                    classes.length > 0 &&
                    classes.map((item) => (
                      <CheckboxAgc
                        key={item.value}
                        name={item.name}
                        value={item.value}
                        isChecked={classe.some((e) => e.classe === item.value)}
                        onChange={() =>
                          profile === 'aj'
                            ? handleCheckboxChange(item.value)
                            : null
                        }
                      />
                    ))}
                </Grid>
                {errorExist && (
                  <Text h={0} color="red" fontSize="14px">
                    {errorExist}
                  </Text>
                )}
              </Box>
            )}
          </Stack>

          {profile === 'aj' && (
            <Stack spacing="22px" mt="48px" align="flex-start">
              <HStack spacing="33px" align="center">
                <Image src={Dollar} w="20px" h="20px" />
                <Text
                  color="#222"
                  fontFamily="Rubik"
                  fontSize="18px"
                  fontWeight="400"
                  letterSpacing="1.8px"
                >
                  Cotação
                </Text>
              </HStack>

              <HStack spacing="10px">
                {price &&
                  price.length > 0 &&
                  price.map((item) => {
                    /* eslint no-else-return: "error" */
                    const formattedCoin = () => {
                      if (item.moeda === 'USD') {
                        return 'Dolar   $';
                      } else if (item.moeda === 'EUR') {
                        return 'Euro   EUR';
                      } else if (item.moeda === 'BRL') {
                        return 'Real   R$';
                      } else if (item.moeda === 'JPY') {
                        return 'Iene    JPY';
                      }
                      return 'Yuan    CNY';
                    };

                    return (
                      <Tag bg="#DEDEDE" p="8px 17px" borderRadius="50px">
                        <HStack spacing="10px">
                          <TagLabel
                            color="#848484"
                            fontFamily="Rubik"
                            fontSize="14px"
                            fontWeight="400"
                            whiteSpace="pre"
                          >
                            {formattedCoin()}
                          </TagLabel>
                        </HStack>
                      </Tag>
                    );
                  })}
                <button onClick={() => setOpen(true)}>
                  <Image src={Pencil} />
                </button>
              </HStack>
            </Stack>
          )}
        </Box>
      </HStack>

      <DrawerEdit
        isOpen={open}
        onClose={() => setOpen(false)}
        id={id}
        onChange={setPrice}
        statePrices={onSave}
        estadoMoedas={setPrice}
      />
    </Box>
  );
};
