import React, { createContext, useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

type AlertContextProps = (_: AlertProps) => void;

interface AlertProps {
  title?: string;
  message?: string;
  buttonOK?: string;
  buttonCancel?: string;
  onClick(): void;
}

const DialogContext = createContext<AlertContextProps>(null as any);

export const DialogProvider: React.FC<any> = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const [alert, setAlert] = useState<AlertProps>(null as any);

  const showDialog = (dialogData: AlertProps) => {
    setAlert(dialogData);
    setShow(true);
  };

  return (
    <DialogContext.Provider value={showDialog}>
      {children}
      <Modal isCentered onClose={() => setShow(false)} isOpen={show}>
        <ModalOverlay bg="blackAlpha.400" backdropFilter="blur(10px)" />
        <ModalContent>
          <ModalHeader>{alert?.title ?? ''}</ModalHeader>
          <ModalCloseButton />
          <ModalBody mt={-3}>
            <Text>{alert?.message ?? ''}</Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="text_secondary"
              mr={3}
              size="sm"
              onClick={() => setShow(false)}
            >
              {alert?.buttonCancel ?? 'Cancelar'}
            </Button>

            <Button
              size="sm"
              variant="solid_secondary"
              onClick={() => {
                alert?.onClick();
                setShow(false);
              }}
            >
              {alert?.buttonOK ?? 'Salvar'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext<AlertContextProps>(DialogContext);
  if (!context) {
    throw new Error('Cant use Alert context');
  }
  return context;
};
