import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { api } from '../../../services/api';
import { useWebsocket } from './useWebsocket';

export const useAGC = (agcId: number) => {
  const push = useNavigate();
  const { dispararPerguntas } = useWebsocket();
  const [votacaoIniciada, setVotacaoIniciada] = useState<boolean>(false); // [TODO
  const toast = useToast();

  const entrar = async () => {
    try {
      await api.post(`/agc/${agcId}/entrar/`);
    } catch (error) {
      const err = error as any;
      push('/agcs');
      toast({
        title: 'Erro ao entrar na AGC',
        description: err.response.data?.detail,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const encerrar = async () => {
    try {
      await api.patch(`/agc/${agcId}/encerrar/`, {
        encerrada: true,
      });
    } catch (error) {
      const err = error as any;
      toast({
        title: 'Erro ao entrar na AGC',
        description: err.response.data?.detail,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const desabilitarCredenciamento = async () => {
    try {
      await api.patch(`/agc/${agcId}/habilitar-credenciamento/`, {
        credenciamento_habilitado: false,
      });
    } catch (error) {
      const err = error as any;
      toast({
        title: 'Erro ao entrar na AGC',
        description: err.response.data?.detail,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const iniciarVotacao = async (idPergunta: number) => {
    try {
      setVotacaoIniciada(!votacaoIniciada);
      dispararPerguntas(votacaoIniciada ? undefined : idPergunta);
      if (votacaoIniciada) {
        await api.post(`/agc-pergunta/${idPergunta}/encerrar-votacao/`);
      } else {
        await api.post(`/agc-pergunta/${idPergunta}/iniciar-votacao/`);
      }
    } catch (error) {
      //
    }
  };

  const listarVotantes = async (idPergunta: number) => {
    try {
      const res = await api.get<Votantes>(
        `agc-pergunta/${idPergunta}/listar-votantes/`,
      );

      return res.data?.usuarios ?? [];
    } catch (error) {
      return [];
    }
  };

  return {
    entrar,
    encerrar,
    desabilitarCredenciamento,
    iniciarVotacao,
    listarVotantes,
    votacaoIniciada,
  };
};

export interface Votantes {
  usuarios: number[];
}
