import {
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import CheckIcon from '../../../../../assets/icons/checkIcon.png';
import { api } from '../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
  // eslint-disable-next-line no-unused-vars
  stateRecover: (prevState: any) => void;
}

const schemaValidation = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  tipo_documento: yup.string().required('Campo obrigatório'),
  documento: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?:\d{3}\d{3}\d{3}\d{2}|\d{2}\d{3}\d{3}\d{4}\d{2})$/,
      'Documento inválido.',
    ),
});

export const ModalAddRecover: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  stateRecover,
}) => {
  const [dataRecover, setDataRecover] = useState({
    nome: '',
    tipo_documento: '',
    documento: '',
  });

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  const onSubmit = async () => {
    try {
      await schemaValidation.validate(dataRecover, { abortEarly: false });

      const dados = {
        agc_id: id,
        nome: dataRecover?.nome,
        tipo_documento: dataRecover?.tipo_documento,
        documento: dataRecover?.documento,
      };
      const response = await api.post('agc-recuperanda/', dados);
      if (response.status === 200 || response.status === 201) {
        setValidationErrors({});
        onClose();
        stateRecover((prevState: any) => [...prevState, dataRecover]);
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setValidationErrors({});
        setDataRecover({
          nome: '',
          tipo_documento: '',
          documento: '',
        });
      }}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent bg="none">
        <ModalCloseButton />
        <ModalBody bg="#EEE" borderRadius="8px" p="25px 25px">
          <Text color="#222" fontSize="18px" fontWeight="500">
            Adicionar Recuperanda
          </Text>

          <Stack mt="20px" spacing="17px">
            <Stack spacing="0px">
              <Input
                name="nome"
                placeholder="Nome"
                onChange={(e) =>
                  setDataRecover({ ...dataRecover, nome: e.target.value })
                }
              />
              {validationErrors.nome && (
                <Text
                  textAlign="start"
                  marginLeft="15px"
                  marginTop="8px !important"
                  lineHeight="12px"
                  fontSize="14px"
                  color="red.400"
                  textStyle="body.small"
                >
                  {validationErrors.nome}
                </Text>
              )}
            </Stack>

            <HStack>
              <Stack spacing="0px" w="100%">
                <Select
                  placeholder="Tipo documento"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: '400',
                    letterSpacing: '0.8px',
                  }}
                  bg="#FFF"
                  borderRadius="43px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  color="#AAA"
                  h="53px"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  _focus={{ border: 'none' }}
                  _focusVisible={{ border: 'none' }}
                  w="100%"
                  onChange={(e) =>
                    setDataRecover({
                      ...dataRecover,
                      tipo_documento: e.target.value,
                    })
                  }
                >
                  <option value="cpf">CPF</option>
                  <option value="cnpj">CNPJ</option>
                </Select>
                {validationErrors.tipo_documento && (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="8px !important"
                    lineHeight="12px"
                    fontSize="14px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {validationErrors.tipo_documento}
                  </Text>
                )}
              </Stack>

              {dataRecover.tipo_documento === 'cpf' && (
                <Stack spacing="0px" w="100%">
                  <Input
                    w="100%"
                    placeholder="CPF"
                    as={InputMask}
                    mask="***.***.***-**"
                    name="documento"
                    onChange={(e) => {
                      const unmaskedValue = e.target.value.replace(
                        /[^0-9]/g,
                        '',
                      );
                      setDataRecover({
                        ...dataRecover,
                        documento: unmaskedValue,
                      });
                    }}
                  />
                  {validationErrors.documento && (
                    <Text
                      textAlign="start"
                      marginLeft="15px"
                      marginTop="8px !important"
                      lineHeight="12px"
                      fontSize="14px"
                      color="red.400"
                      textStyle="body.small"
                    >
                      {validationErrors.documento}
                    </Text>
                  )}
                </Stack>
              )}
              {dataRecover.tipo_documento === 'cnpj' && (
                <Stack spacing="0px" w="100%">
                  <Input
                    w="100%"
                    placeholder="CNPJ"
                    as={InputMask}
                    mask="**.***.***/****-**"
                    name="documento"
                    onChange={(e) => {
                      const unmaskedValue = e.target.value.replace(
                        /[^0-9]/g,
                        '',
                      );
                      setDataRecover({
                        ...dataRecover,
                        documento: unmaskedValue,
                      });
                    }}
                  />
                  {validationErrors.documento && (
                    <Text
                      textAlign="start"
                      marginLeft="15px"
                      marginTop="8px !important"
                      lineHeight="12px"
                      fontSize="14px"
                      color="red.400"
                      textStyle="body.small"
                    >
                      {validationErrors.documento}
                    </Text>
                  )}
                </Stack>
              )}
            </HStack>

            <HStack justify="space-between" mt="13px">
              <Button
                borderRadius="45px"
                border="2px solid #DF8E25"
                bg="rgba(255, 255, 255, 0.00)"
                color="#000"
                fontFamily="Rubik"
                fontSize="14px"
                fontWeight="400"
                onClick={() => {
                  onClose();
                  setValidationErrors({});
                  setDataRecover({
                    nome: '',
                    tipo_documento: '',
                    documento: '',
                  });
                }}
              >
                <HStack spacing="10px" align="center">
                  <CloseIcon w="10px" h="10px" color="#000" opacity="0.25" />
                  <Text>Cancelar</Text>
                </HStack>
              </Button>

              <Button
                borderRadius="105px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                fontFamily="Rubik"
                fontSize="14px"
                color="#FFF"
                p="14px 25px"
                w="170px"
                onClick={onSubmit}
              >
                <HStack spacing="10px">
                  <Image src={CheckIcon} />
                  <Text>Confirmar</Text>
                </HStack>
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
