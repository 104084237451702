import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Image,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import CheckIcon from '../../../../assets/icons/checkIcon.png';
import { api } from '../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
  // eslint-disable-next-line no-unused-vars
  stateProps: (data: any) => void;
}

interface PropsClasses {
  id: number;
  agc_id: number;
  classe: string | undefined;
}

const validationSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório.'),
  tipo_documento: yup.string().required('Campo obrigatório.'),
  documento: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?:\d{3}\d{3}\d{3}\d{2}|\d{2}\d{3}\d{3}\d{4}\d{2})$/,
      'Documento inválido.',
    ),
  agc_classe_id: yup.string().required('Campo obrigatório.'),
  moeda: yup.string().required('Campo obrigatório.'),
  valor_original: yup
    .string()
    .required('Campo obrigatório.')
    .matches(/^[0-9]+$/, 'Este campo deve conter apenas números'),
});

export const ModalAddCreditor: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  stateProps,
}) => {
  const [dataCred, setDataCred] = useState({
    tipo_documento: '',
    agc_id: id,
    nome: '',
    documento: '',
    agc_classe_id: '',
    moeda: '',
    valor_original: '',
  });

  const [classes, setClasses] = useState<PropsClasses[]>([]);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    getClasse();
  }, []);

  const getClasse = async () => {
    try {
      const response = await api.get(`agc-classe/?agc_id=${id}`);
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setClasses(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const coin = [
    { coin: 'USD', value: 'USD' },
    { coin: 'EUR', value: 'EUR' },
    { coin: 'BRL', value: 'BRL' },
    { coin: 'JPY', value: 'JPY' },
    { coin: 'CNY', value: 'CNY' },
  ];

  const clearFields = () => {
    setDataCred({
      tipo_documento: '',
      agc_id: id,
      nome: '',
      documento: '',
      agc_classe_id: '',
      moeda: '',
      valor_original: '',
    });
  };

  const postCreditor = async () => {
    try {
      await validationSchema.validate(dataCred, { abortEarly: false });

      const response = await api.post('agc-credor/', dataCred);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        onClose();
        clearFields();
        stateProps(data);
        setValidationErrors({});
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        console.error(error);
      }
    }
  };

  const postCreditorOther = async () => {
    try {
      await validationSchema.validate(dataCred, { abortEarly: false });

      const response = await api.post('agc-credor/', dataCred);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        clearFields();
        stateProps(data);
        setValidationErrors({});
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        console.error(error);
      }
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setValidationErrors({});
      }}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerBody p="68px 38px" bg="#EEE">
          <Stack spacing="36px">
            <HStack spacing="19px">
              <FiChevronLeft size={24} />
              <Text
                color="#222"
                fontFamily="Roboto"
                fontSize="30px"
                fontWeight="400"
              >
                Adicionando credor
              </Text>
            </HStack>

            <Stack spacing="17px">
              <HStack spacing="37px">
                <Stack w="100%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Nome
                  </Text>
                  <Stack spacing="0px">
                    <Input
                      borderRadius="43px"
                      bg="#FFF"
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      h="54px"
                      name="nome"
                      placeholder="Nome"
                      value={dataCred.nome}
                      onChange={(e) =>
                        setDataCred({ ...dataCred, nome: e.target.value })
                      }
                    />
                    {validationErrors.nome && (
                      <Text
                        textAlign="start"
                        marginLeft="15px"
                        marginTop="8px !important"
                        lineHeight="12px"
                        fontSize="14px"
                        color="red.400"
                        textStyle="body.small"
                      >
                        {validationErrors.nome}
                      </Text>
                    )}
                  </Stack>
                </Stack>

                <Stack w="100%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Classe
                  </Text>
                  <Stack spacing="0px">
                    <Select
                      borderRadius="43px"
                      bg="#FFF"
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      h="54px"
                      _focus={{ border: 'none' }}
                      _focusVisible={{ border: 'none' }}
                      fontFamily="Rubik"
                      placeholder="Classe"
                      value={dataCred.agc_classe_id}
                      onChange={(e) =>
                        setDataCred({
                          ...dataCred,
                          agc_classe_id: e.target.value,
                        })
                      }
                    >
                      {classes &&
                        classes.length > 0 &&
                        classes.map((item) => {
                          const dictionaryClasse = [
                            {
                              classe: 'quirografario',
                              response: 'Quirografário',
                            },
                            { classe: 'trabalhista', response: 'Trabalhista' },
                            {
                              classe: 'garantia-real',
                              response: 'Garantia Real',
                            },
                            {
                              classe: 'microempresa',
                              response: 'Microempresa',
                            },
                          ];
                          const classeInfo = dictionaryClasse.find(
                            (entry) => entry.classe === item.classe,
                          );
                          const optionText = classeInfo
                            ? classeInfo.response
                            : item.classe;

                          return (
                            <option key={item.id} value={item.id}>
                              {optionText}
                            </option>
                          );
                        })}
                    </Select>
                    {validationErrors.agc_classe_id && (
                      <Text
                        textAlign="start"
                        marginLeft="15px"
                        marginTop="8px !important"
                        lineHeight="12px"
                        fontSize="14px"
                        color="red.400"
                        textStyle="body.small"
                      >
                        {validationErrors.agc_classe_id}
                      </Text>
                    )}
                  </Stack>
                </Stack>
              </HStack>

              <HStack spacing="37px">
                <Stack w="100%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    CPF / CNPJ
                  </Text>
                  <Stack spacing="0px">
                    <Select
                      borderRadius="43px"
                      bg="#FFF"
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      h="54px"
                      _focus={{ border: 'none' }}
                      _focusVisible={{ border: 'none' }}
                      fontFamily="Rubik"
                      value={dataCred.tipo_documento}
                      onChange={(e) => {
                        setDataCred({
                          ...dataCred,
                          tipo_documento: e.target.value,
                        });
                      }}
                    >
                      <option value="">Tipo documento</option>
                      <option value="cpf">CPF</option>
                      <option value="cnpj">CNPJ</option>
                    </Select>
                    {validationErrors.tipo_documento && (
                      <Text
                        textAlign="start"
                        marginLeft="15px"
                        marginTop="8px !important"
                        lineHeight="12px"
                        fontSize="14px"
                        color="red.400"
                        textStyle="body.small"
                      >
                        {validationErrors.tipo_documento}
                      </Text>
                    )}
                  </Stack>
                </Stack>
                {dataCred.tipo_documento === 'cpf' && (
                  <Stack w="100%">
                    <Text visibility="hidden">CPF</Text>
                    <Stack spacing="0px">
                      <Input
                        placeholder="CPF"
                        as={InputMask}
                        mask="***.***.***-**"
                        value={dataCred.documento}
                        onChange={(e) => {
                          const unmaskedValue = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                          setDataCred({
                            ...dataCred,
                            documento: unmaskedValue,
                          });
                        }}
                      />
                      {validationErrors.documento && (
                        <Text
                          textAlign="start"
                          marginLeft="15px"
                          marginTop="8px !important"
                          lineHeight="12px"
                          fontSize="14px"
                          color="red.400"
                          textStyle="body.small"
                        >
                          {validationErrors.documento}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                )}
                {dataCred.tipo_documento === 'cnpj' && (
                  <Stack w="100%">
                    <Text visibility="hidden">CNPJ</Text>
                    <Stack spacing="0px">
                      <Input
                        value={dataCred.documento}
                        placeholder="CNPJ"
                        as={InputMask}
                        mask="**.***.***/****-**"
                        onChange={(e) => {
                          const unmaskedValue = e.target.value.replace(
                            /[^0-9]/g,
                            '',
                          );
                          setDataCred({
                            ...dataCred,
                            documento: unmaskedValue,
                          });
                        }}
                      />
                      {validationErrors.documento && (
                        <Text
                          textAlign="start"
                          marginLeft="15px"
                          marginTop="8px !important"
                          lineHeight="12px"
                          fontSize="14px"
                          color="red.400"
                          textStyle="body.small"
                        >
                          {validationErrors.documento}
                        </Text>
                      )}
                    </Stack>
                  </Stack>
                )}
              </HStack>

              <HStack spacing="37px" align="flex-end">
                <Stack w="100%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Moeda
                  </Text>
                  <Stack spacing="0px">
                    <Select
                      borderRadius="43px"
                      bg="#FFF"
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      h="54px"
                      _focus={{ border: 'none' }}
                      _focusVisible={{ border: 'none' }}
                      fontFamily="Rubik"
                      w="100%"
                      onChange={(e) =>
                        setDataCred({ ...dataCred, moeda: e.target.value })
                      }
                      placeholder="Moeda"
                      value={dataCred.moeda}
                    >
                      {coin.map((item) => (
                        <option key={item.value} value={item.value}>
                          {item.coin}
                        </option>
                      ))}
                    </Select>
                    {validationErrors.moeda && (
                      <Text
                        textAlign="start"
                        marginLeft="15px"
                        marginTop="8px !important"
                        lineHeight="12px"
                        fontSize="14px"
                        color="red.400"
                        textStyle="body.small"
                      >
                        {validationErrors.moeda}
                      </Text>
                    )}
                  </Stack>
                </Stack>

                <Stack spacing="0px" w="100%">
                  <Input
                    value={dataCred.valor_original}
                    borderRadius="43px"
                    bg="#FFF"
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                    h="54px"
                    placeholder="Valor"
                    onChange={(e) =>
                      setDataCred({
                        ...dataCred,
                        valor_original: e.target.value,
                      })
                    }
                  />
                  {validationErrors.valor_original && (
                    <Text
                      textAlign="start"
                      marginLeft="15px"
                      marginTop="8px !important"
                      lineHeight="12px"
                      fontSize="14px"
                      color="red.400"
                      textStyle="body.small"
                    >
                      {validationErrors.valor_original}
                    </Text>
                  )}
                </Stack>
              </HStack>
            </Stack>

            <HStack justify="space-between">
              <Button
                borderRadius="45px"
                border="2px solid #EBD87F"
                bg="rgba(255, 255, 255, 0.00)"
                onClick={() => {
                  onClose();
                  setDataCred({
                    tipo_documento: '',
                    agc_id: id,
                    nome: '',
                    documento: '',
                    agc_classe_id: '',
                    moeda: '',
                    valor_original: '',
                  });
                }}
              >
                <HStack spacing="10px" align="center">
                  <CloseIcon w="10px" h="10px" color="#B3B3B3" />
                  <Text
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    Cancelar
                  </Text>
                </HStack>
              </Button>

              <Button
                borderRadius="105px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                w="170px"
                onClick={postCreditor}
              >
                <HStack spacing="10px">
                  <Image src={CheckIcon} />
                  <Text
                    color="#FFF"
                    fontFamily="Rubik"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    Salvar
                  </Text>
                </HStack>
              </Button>
            </HStack>

            <HStack>
              <Button
                borderRadius="31px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                onClick={postCreditorOther}
              >
                <HStack spacing="17px" align="center">
                  <Image src={CheckIcon} />
                  <Text
                    color="#FFF"
                    fontFamily="Rubik"
                    fontWeight="400"
                    letterSpacing="0.8px"
                  >
                    Salvar e adicionar outro
                  </Text>
                </HStack>
              </Button>
            </HStack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
