/* eslint-disable no-console */
import { Box, HStack, Image, Stack, Switch, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Attorneys from '../../../../../assets/icons/archive.svg';
import { api } from '../../../../../services/api';
import { ModalSwitch } from './components/modal';

interface PropsAttorney {
  id: number;
  procurador_nome: string;
  habilitado: boolean;
  data_credenciamento: string | undefined;
}

interface Props {
  id: string | undefined;
  setSelectedAttorney: any;
}

export const CardAttorneys: React.FC<Props> = ({ id, setSelectedAttorney }) => {
  const [attorney, setAttorney] = useState<PropsAttorney[]>([]);

  const [checked, setChecked] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [idAttorney, setIdAttorney] = useState<number>(0);
  const [indexAtt, setIndexAtt] = useState<number>(0);

  useEffect(() => {
    getCreditors();
  }, []);

  const getCreditors = async () => {
    try {
      const response = await api.get(
        `/agc-credor-procurador/?agc_credor_id=${id}`,
      );
      if (response.status === 200) {
        const { results } = response.data;
        setAttorney(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveAtt = (data: PropsAttorney) => {
    const updatedAttorney = [...attorney];
    updatedAttorney[indexAtt] = {
      ...updatedAttorney[indexAtt],
      habilitado: data.habilitado,
    };
    setAttorney(updatedAttorney);
  };

  return (
    <Box
      p="24px 16px"
      bg="#FFF"
      borderRadius="31px"
      h="100%"
      w="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Box height="100%" justifyContent="flex-end">
        <Stack spacing="16px">
          <HStack spacing="33px">
            <Image src={Attorneys} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              Representantes
            </Text>
          </HStack>

          <Stack
            spacing="16px"
            maxH="100%"
            overflowY="auto"
            overflowX="hidden"
            pr="10px"
            sx={{
              '&::-webkit-scrollbar': {
                width: '3px',
                marginLeft: '10px',
                height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
                borderRadius: 'full',
              },
              '&::-webkit-scrollbar-track': {
                width: '8px',
                bg: '#CCC',
                borderRadius: '10px',
              },
            }}
          >
            {attorney &&
              attorney.length > 0 &&
              attorney.map((item, index) => {
                const formattedData = () => {
                  if (item.data_credenciamento) {
                    const today = new Date(item.data_credenciamento);
                    const month =
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1;
                    const day =
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate();
                    const hours =
                      today.getHours() < 10
                        ? `0${today.getHours()}`
                        : today.getHours();
                    const minutes =
                      today.getMinutes() < 10
                        ? `0${today.getMinutes()}`
                        : today.getMinutes();

                    const dateNow = `${day}/${month}/${today.getFullYear()} - ${hours}:${minutes} AM`;

                    return dateNow;
                  }

                  return '';
                };

                return (
                  <HStack
                    spacing="10px"
                    borderRadius="12px"
                    border="1px solid #E7E7E7"
                    bg="#FFF"
                    p="9px 10px"
                    align="center"
                  >
                    <HStack justify="space-between" w="98%">
                      <Stack spacing="10px">
                        <Text
                          color="#515151"
                          fontFamily="Rubik"
                          fontSize="18px"
                          fontWeight="400"
                          letterSpacing="0.45px"
                          w="100%"
                          onClick={() => setSelectedAttorney(item)}
                          cursor="pointer"
                          _hover={{
                            borderBottom: '1px solid #515151',
                            width: '100%',
                          }}
                        >
                          {item.procurador_nome}
                        </Text>
                        <Text color="#A0A0A0" fontSize="14px" fontWeight="400">
                          {formattedData()}
                        </Text>
                      </Stack>

                      <HStack>
                        <Switch
                          isChecked={item.habilitado}
                          onChange={() => {
                            setOpen(true);
                            setIdAttorney(item.id);
                            setChecked(item.habilitado);
                            setIndexAtt(index);
                          }}
                          size="lg"
                          color="red"
                          sx={{
                            '& input:checked + .chakra-switch__track': {
                              backgroundColor: '#DF8E25',
                            },
                          }}
                        />
                        <Text
                          color="#222"
                          fontFamily="Rubik"
                          fontSize="14px"
                          fontWeight="400"
                        >
                          {item.habilitado ? 'APROVADO' : 'REPROVADO'}
                        </Text>
                      </HStack>
                    </HStack>
                  </HStack>
                );
              })}
          </Stack>
        </Stack>
      </Box>

      <ModalSwitch
        isOpen={open}
        onClose={() => setOpen(false)}
        isChecked={() => setChecked(!checked)}
        checked={checked}
        id={idAttorney}
        stateAttorney={onSaveAtt}
      />
    </Box>
  );
};
