import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import {
  AiFillQuestionCircle,
  AiOutlineCheck,
  AiOutlineClose,
} from 'react-icons/ai';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  name: string | undefined;
  surname: string | undefined;
}

export const ModalAddMember: React.FC<Props> = ({
  isOpen,
  onClose,
  name,
  surname,
  onClick,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent>
        <ModalBody textAlign="center" p="20px">
          <Stack spacing="20px">
            <Stack display="flex" flexDir="column" alignItems="center">
              <AiFillQuestionCircle size={30} color="#D89320" />
              <Text fontSize="18px" fontFamily="Rubik">
                Deseja adicionar o usuário{' '}
                <strong>
                  {name} {surname}
                </strong>{' '}
                como membro de sua equipe?
              </Text>
            </Stack>

            <HStack spacing="20px" justify="flex-end">
              <Button
                bg="transparent"
                border="2px solid #EbD87F"
                onClick={onClose}
                leftIcon={<AiOutlineClose />}
              >
                Cancelar
              </Button>
              <Button
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                color="white"
                fontFamily="Rubik"
                leftIcon={<AiOutlineCheck />}
                onClick={onClick}
              >
                Confirmar
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
