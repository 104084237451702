import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  HStack,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { api } from '../../services/api';
import PencilEdit from '../../assets/icons/pencil.svg';
import { DrawerEditCreditor } from './components/edit';
import { CardAttorneys } from './components/cards/attorneys';
import { CardDocuments } from './components/cards/documents';
import { CardComments } from './components/cards/comments';

interface PropsCreditor {
  id: string;
  nome: string;
  tipo_documento: string;
  documento: string;
  moeda: string;
  valor_original: number;
  valor: number;
  agc_classe_classe: string;
}

export const InternalCreditor: React.FC = () => {
  const [agcNome, setAgcNome] = useState('');
  const [selectedAttorney, setSelectedAttorney] = useState();

  const [creditor, setCreditor] = useState<PropsCreditor>();

  const [open, setOpen] = useState(false);

  const { internalCreditorId, agcId } = useParams();

  useEffect(() => {
    getNameAgc();
    getCreditor();
  }, []);

  const getNameAgc = async () => {
    const response = await api.get(`/agc/${agcId}`);

    if (response.status === 200) {
      setAgcNome(response.data.nome);
    }
  };

  const getCreditor = async () => {
    const response = await api.get(`/agc-credor/${internalCreditorId}`);
    if (response.status === 200) {
      setCreditor(response.data);
    }
  };

  const classeFormatted = () => {
    /* eslint no-else-return: "error" */
    if (creditor?.agc_classe_classe === 'trabalhista') {
      return 'Trabalhista';
    } else if (creditor?.agc_classe_classe === 'garantia-real') {
      return 'Garantia Real';
    } else if (creditor?.agc_classe_classe === 'Quirografário') {
      return 'Quirografário';
    }
    return 'Microempresa';
  };

  const formatCnpjCpf = (value: any) => {
    if (value && value.length === 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (value && value.length === 14) {
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
    }
    return value;
  };

  const onSaveCreditor = (creditorSave: any) => {
    setCreditor({ ...creditor, ...creditorSave });
  };

  const formattedValue = {
    coin: creditor?.moeda,
    valor: `${creditor?.valor_original.toLocaleString('pt-BR', {
      style: 'currency',
      currency: creditor && creditor.moeda,
    })}`,
  };

  return (
    <Box p="33px 32px 13.5px 20px">
      <Flex justify="space-between" align="center">
        <Breadcrumb
          spacing="7px"
          separator={<ChevronRightIcon w="22px" h="22px" color="#AAAAAA" />}
          display="flex"
          alignItems="flex-start"
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to="/agcs"
              color="#224141"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              AGC's
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/agcs/${agcId}`}
              color="#224141"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              {agcNome}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              color="#848484"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              Detalhes do Credor
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <button
          onClick={() => setOpen(true)}
          style={{
            borderRadius: '53px',
            background:
              'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
            padding: '8px 17px',
          }}
        >
          <Flex alignItems="center">
            <Image src={PencilEdit} width="20px" height="20px" />
            <Text color="#FFF" ml="10px" fontWeight="500">
              Editar
            </Text>
          </Flex>
        </button>
      </Flex>

      <Flex
        p="35px"
        bg="#FFF"
        borderRadius="31px"
        justify="space-between"
        mt="14px"
      >
        <Box>
          <Text
            color="#06152B"
            fontSize="16px"
            fontWeight="400"
            fontFamily="Rubik"
          >
            Nome
          </Text>
          <Text>{creditor?.nome}</Text>
        </Box>

        <HStack spacing="57px">
          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              CNPJ / CPF
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {formatCnpjCpf(creditor?.documento)}
            </Text>
          </Box>

          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              Classe
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {classeFormatted()}
            </Text>
          </Box>
        </HStack>

        <HStack spacing="57px">
          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              Moeda
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {formattedValue.coin}
            </Text>
          </Box>

          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              Crédito
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {formattedValue.valor}
            </Text>
          </Box>
        </HStack>
      </Flex>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 min-h-[480px] gap-3">
        <CardAttorneys
          id={internalCreditorId}
          setSelectedAttorney={setSelectedAttorney}
        />
        {selectedAttorney ? (
          <>
            <CardDocuments
              id={internalCreditorId}
              selectedAttorney={selectedAttorney}
            />
            <CardComments
              id={internalCreditorId}
              selectedAttorney={selectedAttorney}
            />
          </>
        ) : (
          <div className="lg:col-span-2 py-6 px-4 bg-white rounded-2xl justify-center items-center flex flex-col gap-5">
            <img
              className="w-[60%]"
              // eslint-disable-next-line global-require
              src={require('../../assets/select_attoerney.svg').default}
              alt="mySvgImage"
            />
            <Text
              textAlign="center"
              color="#222"
              fontFamily="Rubik"
              fontSize="28px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              Selecione um Representante
            </Text>
          </div>
        )}
      </div>

      <DrawerEditCreditor
        isOpen={open}
        onClose={() => setOpen(false)}
        id={internalCreditorId}
        agcId={agcId}
        creditorId={creditor?.id}
        onSave={onSaveCreditor}
      />
    </Box>
  );
};
