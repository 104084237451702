import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { AiFillInfoCircle, AiOutlineClose } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  name: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onConfirm: () => void;
}

export const ModalConfirm: React.FC<Props> = ({
  isOpen,
  onClose,
  name,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody textAlign="center" p="20px">
          <Stack spacing="20px">
            <Stack display="flex" flexDir="column" alignItems="center">
              <AiFillInfoCircle size={60} color="#D89320" />
              <Text fontSize="18px" fontFamily="Rubik">
                Tem certeza que deseja adicionar <strong>{name}</strong> como
                procurador?
              </Text>
            </Stack>

            <HStack spacing="20px" justify="flex-end">
              <Button
                bg="transparent"
                border="2px solid #EbD87F"
                onClick={onClose}
                leftIcon={<AiOutlineClose />}
              >
                Cancelar
              </Button>
              <Button
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                color="white"
                fontFamily="Rubik"
                leftIcon={<FaCheck />}
                onClick={onConfirm}
              >
                Confirmar
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
