import React, { createRef, useEffect } from 'react';
import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import 'moment/locale/pt-br';
import moment from 'moment';
import { TbSend } from 'react-icons/tb';
import { CgImage } from 'react-icons/cg';
import { FiPaperclip } from 'react-icons/fi';
import { BsEmojiSmile } from 'react-icons/bs';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import { BiSolidUser } from 'react-icons/bi';
import { useChat } from '../../hooks/useChat';
import { resizeImg } from '../../utils/resize';

moment.locale('pt-br');

const ChatBox: React.FC = () => {
  const { messages, sendMessage } = useChat();
  const messagesEndRef = createRef<any>();
  const [text, setText] = React.useState('');

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const handleSubmit = () => {
    sendMessage({ text });
    setText('');
  };

  const selectImage = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (a) => {
          const data = a.target?.result;

          if (data) {
            resizeImg(data?.toString() ?? '', (img) => {
              sendMessage({ image: img.toString() });
            });
          }
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const selectDocument = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = (e) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (a) => {
          const data = a.target?.result;
          if (data) {
            sendMessage({ doc: data.toString() });
          }
        };
        reader.readAsDataURL(file);
      }
    };
    input.click();
  };

  const downloadFile = (url: string, filename: string) => {
    fetch(url).then((t) =>
      t.blob().then((b) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();
      }),
    );
  };

  return (
    <Flex flexDir="column" flex={1}>
      <Flex flexDir="column" overflow="auto" flex="1 1 0">
        <Text p="30px" fontSize="18px">
          Chat
        </Text>

        {messages.map((message, index) => (
          <Box key={String(`${index}as`)} pb="10px" px="30px">
            <HStack>
              <Stack
                align="center"
                justify="center"
                w="30px"
                h="30px"
                borderRadius="50px"
                bg="gray.100"
              >
                <Icon as={BiSolidUser} color="gray.400" fontSize="22px" />
              </Stack>

              <Text fontSize="12px" fontWeight="bold">
                {message.sender.name}
              </Text>

              <Text opacity={0.5} fontSize="10px">
                {moment(message.timestamp).format('HH[h]mm')}
              </Text>
            </HStack>

            <Box pl="38px" flex={1}>
              <Image src={message.message?.image} />
              {message.message?.doc && (
                <Flex
                  onClick={() =>
                    downloadFile(message.message?.doc ?? '', 'documento')
                  }
                  cursor="pointer"
                  _hover={{ bg: 'gray.300' }}
                  p="8px 20px"
                  borderRadius="100px"
                  bg="gray.200"
                  w="250px"
                  align="center"
                >
                  <Text
                    flex={1}
                    opacity={0.6}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                  >
                    enviou um documento
                  </Text>

                  <Icon as={AiOutlineCloudDownload} fontSize="22px" />
                </Flex>
              )}
              <Text lineHeight="16px">{message.message?.text}</Text>
            </Box>
          </Box>
        ))}

        <div ref={messagesEndRef} />
      </Flex>

      <HStack spacing={0} px="20px">
        <IconButton
          aria-label="Enviar mensagem"
          bg="white"
          shadow="lg"
          size="sm"
          onClick={handleSubmit}
          icon={<Icon color="#DCAC36" as={BsEmojiSmile} fontSize="24px" />}
        >
          Enviar
        </IconButton>
        <Input
          px="14px"
          shadow="none"
          bg="transparent"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          placeholder="Mensagem"
          onSubmit={handleSubmit}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
        <HStack>
          <Icon
            onClick={selectImage}
            color="#AAAAAA"
            as={CgImage}
            fontSize="22px"
          />
          <Icon
            onClick={selectDocument}
            color="#AAAAAA"
            as={FiPaperclip}
            fontSize="22px"
          />
          <IconButton
            size="md"
            bg="#DCAC36"
            aria-label="Enviar mensagem"
            onClick={handleSubmit}
            icon={<Icon color="white" as={TbSend} fontSize="26px" />}
            shadow="lg"
          />
        </HStack>
      </HStack>
    </Flex>
  );
};

export default ChatBox;
