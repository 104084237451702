/* eslint-disable no-plusplus */
import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import EditIcon from '../../assets/icons/edit.svg';
import DetailsIcon from '../../assets/icons/detail.svg';
import DeleteIcon from '../../assets/icons/trash.svg';
import { api } from '../../services/api';

export const CreditorsAgc: React.FC = () => {
  const [creditors, setCreditors] = useState([
    {
      nome: '',
      documento: '',
      agc_classe_classe: '',
      moeda: '',
      valor: 0,
      id: 0,
    },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [filterText, setFilterText] = useState('');

  const [loading, setLoading] = useState<boolean>(false);

  const itemsPerPage = 10;

  useEffect(() => {
    if (filterText.length >= 5 || filterText.length === 0) {
      getCreditors();
    }
  }, [currentPage, filterText]);

  const getCreditors = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/agc-credor/?agc_id=1&search=${filterText}&limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }`,
      );
      const { results, count } = response.data;
      if (response.status === 200 || response.status === 201) {
        setCreditors(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleFilterPerName = (event: any) => {
    const filter = event.target.value;
    setFilterText(filter);
  };

  const headCred = [
    { name: 'Nome' },
    { name: 'CNPJ / CPF' },
    { name: 'Classe' },
    { name: 'Moeda' },
    { name: 'Crédito' },
    { name: 'Ações' },
  ];

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 3;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const paginationButtons = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
    }

    if (startPage !== 1) {
      paginationButtons.push(
        <Button
          key={1}
          onClick={() => handlePageChange(1)}
          fontWeight={currentPage === 1 ? 'bold' : 'normal'}
          color={currentPage === 1 ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === 1
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          1
        </Button>,
      );
    }

    if (startPage > 2) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          fontWeight={currentPage === i ? 'bold' : 'normal'}
          color={currentPage === i ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === i
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {i}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    if (endPage !== totalPages) {
      paginationButtons.push(
        <Button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          fontWeight={currentPage === totalPages ? 'bold' : 'normal'}
          color={currentPage === totalPages ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === totalPages
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {totalPages}
        </Button>,
      );
    }

    return paginationButtons;
  };

  return (
    <Box p="20px">
      <Box
        bg="#EEE"
        minH="86vh"
        boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
        filter="drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.10))"
        borderRadius="30px"
      >
        <Flex justify="space-between" p="32px 40px 0px 64px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="30px"
            fontWeight="500"
          >
            Credores da AGC
          </Text>

          <HStack spacing="12px">
            <Text color="#AAA" fontSize="16px" fontWeight="400" w="105px">
              Filtrar por
            </Text>

            <Select
              borderRadius="37px"
              bg="#FFF"
              boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
              h="61px"
              color="#222"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              iconColor="#AAA"
              focusBorderColor="none"
              _focus={{ border: 'none' }}
              _focusVisible={{ border: 'none' }}
            >
              <option>Hoje, 13 de Dez</option>
            </Select>
          </HStack>
        </Flex>

        <Tabs variant="unstyled">
          <TabList pl="60px">
            <Tab
              border="none"
              _selected={{
                bg: '#F2F2F2',
                boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                color: '#222',
              }}
              w="269px"
              background="#FFFFFF"
              borderTopRadius="30px"
              boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
              color="#AAA"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              <Text py="6px">Todos os Credores</Text>
            </Tab>
          </TabList>

          <TabPanels
            borderRadius="30px"
            fill="#DADADA"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset"
          >
            <TabPanel p="28px 40px 38px 28px">
              <Box pb="45px">
                <InputGroup>
                  <InputLeftElement mt="4px" pl="36px">
                    <SearchIcon color="#AAA" w="20px" h="20px" />
                  </InputLeftElement>

                  <InputRightElement>
                    <Box
                      w="36px"
                      h="36px"
                      bg="#D9D9D9"
                      borderRadius="100px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mt="6px"
                    >
                      <ChevronDownIcon color="#AAA" w="24px" h="24px" />
                    </Box>
                  </InputRightElement>
                  <Input
                    boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.05)"
                    placeholder="Pesquisa por nome"
                    _placeholder={{
                      color: '#888',
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '28px',
                      letterSpacing: '1.6px',
                    }}
                    pl="79px"
                    h="56px"
                    onChange={handleFilterPerName}
                  />
                </InputGroup>
              </Box>

              <TableContainer>
                <Table variant="simple">
                  <Thead borderBottom="2px solid #9B9B9B">
                    <Tr>
                      {headCred.map((item) => (
                        <Th
                          color="#000"
                          fontFamily="Rubik"
                          fontSize="12px"
                          fontWeight="700"
                        >
                          {item.name}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {creditors.map((item) => {
                      /* eslint no-else-return: "error" */
                      const formattedClass = () => {
                        if (item.agc_classe_classe === 'trabalhista') {
                          return 'Trabalhista';
                        } else if (item.agc_classe_classe === 'garantia-real') {
                          return 'Garantia Real';
                        } else if (item.agc_classe_classe === 'quirografario') {
                          return 'Quirografário';
                        }
                        return 'Microempresa';
                      };

                      const formattedValue = {
                        coin: item?.moeda,
                        valor:
                          item?.moeda && item?.valor !== undefined
                            ? item.valor.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: item.moeda,
                              })
                            : 'Valor não disponível', // Pode fornecer uma mensagem alternativa se o valor ou a moeda não estiverem disponíveis.
                      };

                      return (
                        <Tr
                          borderBottom="2px solid #CCC"
                          color="#000"
                          fontFamily="Rubik"
                          fontSize="14px"
                          fontWeight="400"
                          _hover={{ bg: '#E4E4E4' }}
                          key={item.id}
                        >
                          <Td>{item.nome}</Td>
                          <Td>{item.documento}</Td>
                          <Td>{formattedClass()}</Td>
                          <Td>{formattedValue?.coin}</Td>
                          <Td>{formattedValue?.valor}</Td>
                          <Td>
                            <HStack spacing="16px">
                              <Image src={EditIcon} />
                              <Image src={DetailsIcon} />
                              <Image src={DeleteIcon} />
                            </HStack>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
                <HStack py="20px" align="center" justify="flex-end">
                  {renderPaginationButtons()}
                </HStack>
              </TableContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};
