import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { Suspense, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BiSolidReport } from 'react-icons/bi';
import PencilEdit from '../../assets/icons/pencil.svg';
import { api } from '../../services/api';
import { PaymentMade } from './paymentMade';
import { DetailsAgc } from './details';
import { Recover } from './cards';
import Folder from '../../assets/icons/FolderNotchOpen.svg';
import Clip from '../../assets/icons/clip.svg';
import { AllCreditors } from './allCreditors';
import { EditAgc, Data } from './components/editAgc';
import { MyCreditors } from './myCreditors';
import { Pendencies } from './cards/pendencies';
import { Documents } from './cards/documents';
import { ModalImportCreditor } from './components/modalImport';
import { ModalAddCreditor } from './components/modalAddCreditor';
import { PendingAnalysis } from './pendingAnalysis';
import { Able } from './able';

export const AgcInternal: React.FC = () => {
  const { agcId } = useParams();

  const [agc, setAgc] = useState({
    id: 0,
    aj_id: 0,
    aj_usuario_id: '',
    aj_usuario_nome: '',
    aj_usuario_cpf: '',
    data_cadastro: '',
    numero_processo: '',
    natureza: '',
    nome: '',
    observacao: '',
    data_credenciamento: '',
    hora_credenciamento: '',
    hora_instalacao: '',
    convocacao: '',
    credenciamento_habilitado: false,
    suspensa: false,
    encerrada: false,
    zoom_meeting_id: null,
    zoom_meeting_password: null,
    agc_id_anterior: null,
    pagamento_realizado: false,
    valor_pago: 0,
  });

  const [lengthMyCreditors, setLengthMyCreditors] = useState<number>(0);
  const [lengthPendences, setLengthPendences] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState<number>(0);
  // const location = useLocation();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [open, setOpen] = useState<boolean>(false);
  const [openAdd, setOpenAdd] = useState<boolean>(false);
  const [isCredEnabled, setIsCredEnabled] = useState<boolean>(false);
  const [isClose, setIsClose] = useState<boolean>(false);

  const [dataProps, setDataProps] = useState(null);

  const [profile, setProfile] = useState<string | undefined>('');

  useEffect(() => {
    Promise.allSettled([
      getInfosAGC(),
      getMyCreditors(),
      getUserProfile(),
      getAnalysis(),
      setIsCredEnabled(agc.credenciamento_habilitado),
      setIsClose(agc.encerrada),
      getAttorneys(),
    ]);
  }, [
    agcId,
    agc.pagamento_realizado,
    agc.credenciamento_habilitado,
    agc.encerrada,
    // location.key,
  ]);

  const getInfosAGC = async () => {
    try {
      const response = await api.get(`/agc/${agcId}/`);
      if (response.status === 200 || response.status === 201) {
        const dataFormattData = {
          ...response.data,
          hora_credenciamento: formatTime(response.data.data_credenciamento),
        };
        setAgc(dataFormattData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const userId = localStorage.getItem('userId');

  const getMyCreditors = async () => {
    try {
      const response = await api.get(
        `/agc-credor-procurador/?agc_id=${agcId}&usuario_id=${userId}`,
        { params: { limit: 10 } },
      );
      if (response.status === 200 || response.status === 201) {
        const { count } = response.data;
        setLengthMyCreditors(count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (date: any) => {
    if (typeof date !== 'undefined') {
      const dateParts = date.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1;
      const day = parseInt(dateParts[2], 10);

      const today = new Date(year, month, day);
      const monthPart =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      const dayPart =
        today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

      const dateNow = `${dayPart}/${monthPart}/${today.getFullYear()}`;

      return dateNow;
    }

    return '';
  };

  const formatTime = (dateTime: any) => {
    if (typeof dateTime !== 'undefined') {
      const today = new Date(dateTime);
      const hours =
        today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes =
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();

      const dateNow = `${hours}:${minutes}`;

      return dateNow;
    }

    return '';
  };

  const onSaveAgc = (agcSave: Data) => {
    setAgc({ ...agc, ...agcSave });
  };

  const [hour, minute] = agc?.hora_instalacao
    ? agc.hora_instalacao.split(':')
    : [undefined, undefined];
  const hourFormatted = `${hour}:${minute}`;

  const getUserProfile = async () => {
    try {
      const response = await api.get(`agc/${agcId}/perfil-usuario-logado/`);
      if (response.status === 200 || response.status === 201) {
        const { perfil } = response.data;
        setProfile(perfil);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ableCred = async () => {
    try {
      const data = {
        credenciamento_habilitado: true,
      };
      const response = await api.patch(
        `agc/${agcId}/habilitar-credenciamento/`,
        data,
      );
      if (response.status === 200 || response.status === 201) {
        setIsCredEnabled(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const disableCred = async () => {
    try {
      const data = {
        credenciamento_habilitado: false,
      };
      const response = await api.patch(
        `agc/${agcId}/habilitar-credenciamento/`,
        data,
      );
      if (response.status === 200 || response.status === 201) {
        setIsCredEnabled(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAnalysis = async () => {
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_id=${agcId}&habilitado=false`,
      );
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        setLengthPendences(data.count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const reopen = async (onCloseConfirm: any) => {
    try {
      const data = {
        encerrada: false,
      };

      const response = await api.patch(`agc/${agcId}/encerrar/`, data);
      if (response.status === 200 || response.status === 201) {
        setIsClose(false);
        onCloseConfirm();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAttorneys = async () => {
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_id=${agcId}&habilitado=true`,
      );
      if (response.status === 200 || response.status === 201) {
        const { count } = response.data;
        if (count >= 1) {
          ableCred();
        } else if (count === 0) {
          disableCred();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p="26px 32px 34px 20px">
      <Suspense fallback={<Skeleton />}>
        <Flex justify="space-between" align="center" pr="10px" pb="18px">
          <Breadcrumb
            spacing="7px"
            separator={<ChevronRightIcon w="22px" h="22px" color="#AAAAAA" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink
                as={Link}
                to="/agcs"
                color="#224141"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
              >
                AGC's
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink
                color="#848484"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
              >
                {agc?.nome}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>

          <HStack spacing="10px">
            {isClose && (
              <Button
                leftIcon={<BiSolidReport color="white" />}
                borderRadius="53px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                color="white"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="500"
                onClick={() => {
                  window.location.href = `/agcs/${agcId}/report`;
                }}
              >
                Laudo
              </Button>
            )}
            {profile === 'aj' && (
              <button
                style={{
                  borderRadius: '53px',
                  background:
                    'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
                  boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)',
                  padding: '8px 17px 8px 17px',
                }}
                onClick={onOpen}
              >
                <Flex alignItems="center" justify="center">
                  <Image src={PencilEdit} w="18px" h="18px" />
                  <Text
                    color="white"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="500"
                    ml="10px"
                  >
                    Editar
                  </Text>
                </Flex>
              </button>
            )}
          </HStack>
        </Flex>

        <EditAgc
          isOpen={isOpen}
          onClose={onClose}
          id={agcId}
          onSave={onSaveAgc}
        />

        <Stack spacing="33px">
          <Stack spacing="19px">
            <PaymentMade
              agcId={agc.id}
              profile={profile}
              amountPaid={agc?.valor_pago}
              appointment={agc?.hora_credenciamento}
              paymentMade={agc?.pagamento_realizado}
              data={agc.data_credenciamento}
              hourCred={agc.hora_credenciamento}
              ableCred={isCredEnabled}
              // onClick={ableCred}
              closed={isClose}
              suspended={agc.suspensa}
              reopen={reopen}
            />

            <DetailsAgc
              closed={agc?.encerrada}
              formatTime={hourFormatted}
              formattedData={formatDate(agc?.data_credenciamento)}
              formatedTime={agc?.hora_credenciamento}
              nProcess={agc?.numero_processo}
              name={agc?.nome}
              nature={agc?.natureza}
              suspended={agc?.suspensa}
              id={agcId}
              profile={profile}
              nameAj={agc.aj_usuario_nome}
              date={agc.data_credenciamento}
              noFormatTime={agc.hora_instalacao}
            />

            <HStack justify="space-between">
              <Pendencies id={agcId} />
              <Documents id={agcId} profile={profile} />
              <Recover
                title="Recuperanda(s)"
                iconTitle={Folder}
                id={agcId}
                profile={profile}
              />
            </HStack>
          </Stack>

          <Box
            borderRadius="20px"
            bg="#EEE"
            boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
          >
            <Tabs
              variant="unstyled"
              mt="16px"
              isLazy
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
            >
              <TabList pl="60px">
                <Tab
                  border="none"
                  _selected={{
                    bg: '#EEE',
                    boxShadow:
                      '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                    color: '#222',
                  }}
                  w="269px"
                  background="#FFFFFF"
                  borderTopRadius="30px"
                  boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
                  color="#AAA"
                  fontFamily="Rubik"
                  fontSize="18px"
                  fontWeight="400"
                  letterSpacing="1.8px"
                  tabIndex={-1}
                >
                  <Text py="6px">Credores</Text>
                </Tab>
                {profile === 'aj' && (
                  <Flex>
                    <Tab
                      border="none"
                      _selected={{
                        bg: '#EEE',
                        boxShadow:
                          '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                        color: '#222',
                      }}
                      w="269px"
                      background="#FFFFFF"
                      borderTopRadius="30px"
                      boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
                      color="#AAA"
                      fontFamily="Rubik"
                      fontSize="18px"
                      fontWeight="400"
                      letterSpacing="1.8px"
                    >
                      <HStack
                        spacing="0px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Text py="6px" w="100%" lineHeight="18px">
                          Habilitação Pendente
                        </Text>
                        <Center
                          w="20px"
                          h="20px"
                          marginLeft="10px"
                          borderRadius="66px"
                          bg="rgba(34, 34, 34, 0.10)"
                          color="#888"
                          fontFamily="Rubik"
                          fontSize="12px"
                          fontWeight="normal"
                          letterSpacing="0.3px"
                        >
                          {lengthPendences}
                        </Center>
                      </HStack>
                    </Tab>
                    <Tab
                      border="none"
                      _selected={{
                        bg: '#EEE',
                        boxShadow:
                          '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                        color: '#222',
                      }}
                      w="269px"
                      background="#FFFFFF"
                      borderTopRadius="30px"
                      boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
                      color="#AAA"
                      fontFamily="Rubik"
                      fontSize="18px"
                      fontWeight="400"
                      letterSpacing="1.8px"
                    >
                      <Text py="11px">Habilitado</Text>
                    </Tab>
                  </Flex>
                )}

                {profile !== 'aj' && (
                  <Tab
                    key="meus-credores"
                    border="none"
                    _selected={{
                      bg: '#EEE',
                      boxShadow:
                        '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                      color: '#222',
                    }}
                    w="269px"
                    background="#FFFFFF"
                    borderTopRadius="30px"
                    boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
                    color="#AAA"
                    fontFamily="Rubik"
                    fontSize="18px"
                    fontWeight="400"
                    letterSpacing="1.8px"
                    tabIndex={-1}
                  >
                    <Text p="11px 5.5px">Meus credores</Text>
                    <Center
                      w="20px"
                      h="20px"
                      borderRadius="66px"
                      bg="#FF6161"
                      color="#FFF"
                      fontFamily="Rubik"
                      fontSize="12px"
                      fontWeight="normal"
                      letterSpacing="0.3px"
                    >
                      {lengthMyCreditors}
                    </Center>
                  </Tab>
                )}

                {profile === 'aj' && (
                  <HStack w="52%" justify="flex-end" spacing="16px">
                    <Button
                      borderRadius="31px"
                      bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                      boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                      color="#FFF"
                      fontFamily="Rubik"
                      fontSize="12px"
                      fontWeight="500"
                      px="17px"
                      h="34px"
                      onClick={() => setOpen(true)}
                    >
                      <HStack spacing="40px">
                        <Text>Importar</Text>
                        <Image src={Clip} w="14px" h="14px" />
                      </HStack>
                    </Button>

                    <Button
                      borderRadius="31px"
                      bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                      boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                      color="#FFF"
                      fontFamily="Rubik"
                      fontSize="12px"
                      fontWeight="500"
                      px="24px"
                      h="34px"
                      onClick={() => setOpenAdd(true)}
                    >
                      Adicionar credor
                    </Button>

                    <ModalImportCreditor
                      isOpen={open}
                      onClose={() => setOpen(false)}
                      agcId={agcId}
                      stateProps={setDataProps}
                    />
                    <ModalAddCreditor
                      isOpen={openAdd}
                      onClose={() => setOpenAdd(false)}
                      id={agcId}
                      stateProps={setDataProps}
                    />
                  </HStack>
                )}
              </TabList>
              <TabPanels
                borderRadius="30px"
                fill="#DADADA"
                boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset"
              >
                <TabPanel p="28px">
                  <AllCreditors
                    agcId={agcId}
                    profile={profile}
                    data={dataProps}
                  />
                </TabPanel>
                {profile !== 'aj' && (
                  <TabPanel>
                    <MyCreditors id={agcId} />
                  </TabPanel>
                )}
                <TabPanel>
                  <PendingAnalysis id={agcId} profile={profile} />
                </TabPanel>
                <TabPanel>
                  <Able id={agcId} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Stack>
      </Suspense>
    </Box>
  );
};
