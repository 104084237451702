import {
  Box,
  Button,
  HStack,
  Image,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import Close from '../../../../assets/icons/close.svg';
import Arrow from '../../../../assets/icons/arrowRight.svg';
import InputComponent from '../../../../components/forms/input';

const schema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório.'),
  numero_processo: yup.string().required('Campo obrigatório.'),
  natureza: yup.string().required('Campo obrigatório.'),
  data_credenciamento: yup.string().required('Campo obrigatório.'),
  hora_instalacao: yup.string().required('Campo obrigatório.'),
  convocacao: yup.string().required('Campo obrigatório.'),
  hora_credenciamento: yup.string().required('Campo obrigatório.'),
});

interface FieldError {
  message: string;
}

export const FormOneAgc = ({ nextStep }: any) => {
  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      nome: '',
      numero_processo: '',
      natureza: '',
      data_credenciamento: '',
      hora_credenciamento: '',
      hora_instalacao: '',
      convocacao: '',
      observacao: '',
    },
  });

  const { formState } = formMethods;
  const { errors } = formState;

  const onSubmit = async (data: any) => {
    const cleanData = { ...data };
    nextStep(cleanData);
  };

  const navigate = useNavigate();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack w="60%" spacing="17px">
          <HStack mt="33px" spacing="37px">
            <InputComponent
              placeholder="Nome da AGC"
              _placeholder={{
                color: '#AAA',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.8px',
              }}
              h="53px"
              w="100%"
              name="nome"
            />
            <InputComponent
              placeholder="N° Processo"
              _placeholder={{
                color: '#AAA',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: '400',
                letterSpacing: '0.8px',
              }}
              h="53px"
              w="100%"
              name="numero_processo"
            />
          </HStack>

          <Controller
            name="natureza"
            control={formMethods.control}
            render={({ field }) => (
              <Box>
                <Select
                  {...field}
                  placeholder="Natureza"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: '400',
                    letterSpacing: '0.8px',
                  }}
                  h="53px"
                  bg="#FFF"
                  borderRadius="43px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  color="#AAA"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  _focus={{ border: 'none' }}
                  _focusVisible={{ border: 'none' }}
                  iconColor="#8A8A8A"
                  iconSize="40px"
                  w="237px"
                >
                  <option value="recuperacao-judicial">
                    Recuperação Judicial
                  </option>
                  <option value="falencia">Falência</option>
                </Select>
                {errors.natureza !== undefined && (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="4px !important"
                    lineHeight="12px"
                    fontSize="12px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {(errors.natureza as FieldError).message}
                  </Text>
                )}
                {/* {errors.natureza} */}
              </Box>
            )}
          />

          <HStack justify="space-between">
            <Box w="62%">
              <Text
                color="#06152B"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
              >
                Data e hora de credenciamento
              </Text>

              <HStack spacing="18px" mt="7px">
                <InputComponent
                  type="date"
                  h="54px"
                  color="#AAA"
                  placeholder="DD / MM / AAAA"
                  name="data_credenciamento"
                  w="100%"
                />
                <InputComponent
                  type="time"
                  h="54px"
                  color="#AAA"
                  name="hora_credenciamento"
                  w="100%"
                />
              </HStack>
            </Box>

            <Box w="31%">
              <Text
                color="#06152B"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
              >
                Hora instalação
              </Text>

              <Box mt="7px">
                <InputComponent
                  type="time"
                  h="54px"
                  color="#AAA"
                  name="hora_instalacao"
                />
              </Box>
            </Box>
          </HStack>

          <Controller
            name="convocacao"
            control={formMethods.control}
            render={({ field }) => (
              <Box>
                <Select
                  {...field}
                  placeholder="Convocação"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: '400',
                    letterSpacing: '0.8px',
                  }}
                  h="53px"
                  bg="#FFF"
                  borderRadius="43px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  color="#AAA"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  _focus={{ border: 'none' }}
                  _focusVisible={{ border: 'none' }}
                  iconColor="#8A8A8A"
                  iconSize="40px"
                  w="236px"
                >
                  <option value="1a-convocacao">1° Convocação</option>
                  <option value="2a-convocacao">2° Convocação</option>
                </Select>
                {errors.convocacao !== undefined && (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="4px !important"
                    lineHeight="12px"
                    fontSize="12px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {(errors.convocacao as FieldError).message}
                  </Text>
                )}
              </Box>
            )}
          />

          <InputComponent
            placeholder="Observações"
            _placeholder={{
              color: '#AAA',
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: '400',
              letterSpacing: '0.8px',
            }}
            h="53px"
            w="70%"
            name="observacao"
          />
        </Stack>

        <HStack mt="45px" spacing="29px">
          <Button
            h="51px"
            borderRadius="45px"
            bg="none"
            border="1px solid #DF8E25"
            color="#000"
            fontFamily="Rubik"
            fontSize="14px"
            fontWeight="400"
            onClick={() => navigate('/agcs')}
          >
            <Image src={Close} pr="10px" />
            Cancelar
          </Button>

          <Button
            h="51px"
            borderRadius="31px"
            color="white"
            fontFamily="Rubik"
            fontSize="16px"
            fontWeight="400"
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            p="16px 27px"
            letterSpacing="0.8px"
            type="submit"
          >
            Continuar
            <Image src={Arrow} ml="17px" />
          </Button>
        </HStack>
      </form>
    </FormProvider>
  );
};
