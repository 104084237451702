import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Layout from '../components/Layout';

export const RequireAuth = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  // if (loading) {
  //   return <Loader />;
  // }

  return isAuthenticated ? (
    <Layout />
  ) : (
    <Navigate to="/start" state={{ from: location }} replace />
  );
};
