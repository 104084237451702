import React, { useContext } from 'react';
import { Button, Tooltip, Dropdown } from 'antd';
import { CheckOutlined, UpOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { BiVideo, BiVideoOff } from 'react-icons/bi';
import ZoomMediaContext from '../../../context/media-context';
import { MediaDevice } from '../video-types';
import {
  getAntdDropdownMenu,
  getAntdItem,
  MenuItem,
} from './video-footer-utils';

interface CameraButtonProps {
  isStartedVideo: boolean;
  isMirrored?: boolean;
  isBlur?: boolean;
  isPreview?: boolean;
  onCameraClick: () => void;
  onSwitchCamera: (_deviceId: string) => void;
  onMirrorVideo?: () => void;
  onVideoStatistic?: () => void;
  onBlurBackground?: () => void;
  onSelectVideoPlayback?: (_url: string) => void;
  className?: string;
  cameraList?: MediaDevice[];
  activeCamera?: string;
  activePlaybackUrl?: string;
}
// const videoPlaybacks = [
//   {
//     title: 'Video 1',
//     url: 'https://source.zoom.us/brand/mp4/Using%20the%20Zoom%20PWA.mp4',
//   },
//   {
//     title: 'Video 2',
//     url: 'https://source.zoom.us/brand/mp4/Zoom%20Cares%20Nonprofit%20Impact.mp4',
//   },
//   {
//     title: 'Video 3',
//     url: 'https://source.zoom.us/brand/mp4/Zoom%20One%20-%20Team%20Chat%2C%20Phone%2C%20Email%2C%20and%20more.mp4',
//   },
// ];
const CameraButton = (props: CameraButtonProps) => {
  const {
    isStartedVideo,
    className,
    cameraList,
    activeCamera,
    isMirrored,
    isBlur,
    isPreview,
    onCameraClick,
    onSwitchCamera,
    onMirrorVideo,
    onVideoStatistic,
    onBlurBackground,
    onSelectVideoPlayback,
  } = props;
  const { mediaStream } = useContext(ZoomMediaContext);
  const onMenuItemClick = (payload: { key: any }) => {
    if (payload.key === 'mirror') {
      onMirrorVideo?.();
    } else if (payload.key === 'statistic') {
      onVideoStatistic?.();
    } else if (payload.key === 'blur') {
      onBlurBackground?.();
    } else if (/^https:\/\//.test(payload.key)) {
      onSelectVideoPlayback?.(payload.key);
    } else {
      onSwitchCamera(payload.key);
    }
  };
  const menuItems =
    cameraList &&
    cameraList.length > 0 &&
    ([
      getAntdItem(
        'Selecionar Camera',
        'camera',
        undefined,
        cameraList.map((item) =>
          getAntdItem(
            item.label,
            item.deviceId,
            item.deviceId === activeCamera && <CheckOutlined />,
          ),
        ),
        'group',
      ),
      getAntdItem('', 'd1', undefined, undefined, 'divider'),
      !isPreview &&
        getAntdItem(
          'Espelhe meu vídeo',
          'mirror',
          isMirrored && <CheckOutlined />,
        ),
      mediaStream?.isSupportVirtualBackground()
        ? getAntdItem('Desfocar meu fundo', 'blur', isBlur && <CheckOutlined />)
        : getAntdItem('Mascarar meu plano de fundo', 'blur'),
      !isPreview && getAntdItem('', 'd2', undefined, undefined, 'divider'),
      !isPreview && getAntdItem('Estatística de vídeo', 'statistic'),
    ].filter(Boolean) as MenuItem[]);
  return (
    <div className={classNames('camera-footer', className)}>
      {isStartedVideo && menuItems ? (
        <Dropdown.Button
          className="vc-dropdown-button"
          size="large"
          menu={getAntdDropdownMenu(menuItems, onMenuItemClick)}
          onClick={onCameraClick}
          trigger={['click']}
          type="ghost"
          icon={<UpOutlined />}
          placement="topRight"
        >
          <BiVideo />
        </Dropdown.Button>
      ) : (
        <Tooltip
          title={`${isStartedVideo ? 'parar a câmera' : 'iniciar câmera'}`}
        >
          <Button
            className={classNames('vc-button', className)}
            icon={isStartedVideo ? <BiVideo /> : <BiVideoOff />}
            ghost
            shape="circle"
            size="large"
            onClick={onCameraClick}
          />
        </Tooltip>
      )}
    </div>
  );
};
export default CameraButton;
