import {
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { api } from '../../../../services/api';
import { ModalErrors } from './modalErrors';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  agcId: string | undefined;
  // eslint-disable-next-line no-unused-vars
  stateProps: (data: any) => void;
}

interface PropsImport {
  agc_id: string | undefined;
  arquivo_link: string | undefined;
}

export const ModalImportCreditor: React.FC<Props> = ({
  isOpen,
  onClose,
  agcId,
  stateProps,
}) => {
  const [form, setForm] = useState<PropsImport>();

  const [errorExist, setErrorExist] = useState([]);

  const [open, setOpen] = useState(false);

  const handleInputChange = (ev: any) => {
    ev.preventDefault();

    return changeFile(ev.target.files);
  };

  const changeFile = async (files: any) => {
    const file = files[0];
    setForm({ agc_id: agcId, arquivo_link: file });
  };

  const handleCreate = async () => {
    try {
      const formData = new FormData();
      if (form?.agc_id) {
        formData.append('agc_id', form?.agc_id);
      }
      if (form?.arquivo_link) {
        formData.append('arquivo_link', form?.arquivo_link);
      }

      const response = await api.post(
        'agc-credor-arquivo-importacao/',
        formData,
      );
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        onClose();
        stateProps(data);
        setErrorExist([]);
      }
    } catch (error: any) {
      const { data } = error.response;
      if (data.arquivo_link) {
        setOpen(true);
        setErrorExist(data.arquivo_link);
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p="35px 45px">
          <Stack spacing="20px">
            <Text fontWeight="500" color="#222222" fontSize="22px">
              Importação de CREDORES
            </Text>

            <Stack spacing="4px">
              <Input
                type="file"
                variant="unstyled"
                fontSize="16px"
                bg="#FFF"
                border="1px solid #CED4DA"
                p="6px 12px 6px 12px"
                _hover={{
                  borderColor: 'none',
                }}
                _focus={{
                  outline: 'none',
                  boxShadow: 'none',
                }}
                accept=".xls,.xlsx,.xlsb,.txt,.csv,.tsv,.pdf"
                onChange={handleInputChange}
              />
            </Stack>

            <HStack justify="space-between">
              <Button
                boxShadow="none"
                bg="none"
                border="1px solid #DF8E25"
                fontWeight="500"
                fontSize="15px"
                onClick={() => onClose()}
                w="100%"
              >
                Cancelar
              </Button>

              <Button
                boxShadow="none"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                fontWeight="500"
                fontSize="15px"
                color="white"
                w="100%"
                onClick={handleCreate}
              >
                Confirmar
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>

      <ModalErrors
        isOpen={open}
        onClose={() => setOpen(false)}
        Errors={errorExist}
      />
    </Modal>
  );
};
