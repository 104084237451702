import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Center, Image, Stack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import iconRegister from '../../../../../assets/icons/iconRegister.png';
import InputComponent from '../../../../../components/forms/input';
import { api } from '../../../../../services/api';

const schema = yup.object().shape({
  nome: yup
    .string()
    .required('Campo obrigatório.')
    .min(2, 'Seu nome deve ter pelo menos 2 caracteres.'),
  sobrenome: yup
    .string()
    .required('Campo obrigatório.')
    .min(2, 'Seu nome deve ter pelo menos 2 caracteres.'),
  cpf: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'CPF inválido')
    .test('cpf-format', 'Este CPF já está em uso', async (value) => {
      if (!value) return true;
      try {
        const cpfFormatted = value.replace(/\D/g, '');
        const response = await api.get(
          `/usuario/valida-cpf-existe/${cpfFormatted}`,
        );
        return !response.data.status;
      } catch (error) {
        return true;
      }
    }),
  email: yup
    .string()
    .required('Campo obrigatório.')
    .email('Insira um endereço de e-mail válido.')
    .test('email-exists', 'Este e-mail já está em uso.', async (value) => {
      if (!value) return true;

      try {
        const response = await api.get(`/usuario/valida-email-existe/${value}`);
        return !response.data.status;
      } catch (error) {
        return true;
      }
    }),
  celular: yup
    .string()
    .required('Campo obrigatório')
    .matches(/^\(\d{2}\) \d{4,5}-\d{4}$/, 'Número de telefone inválido'),
  password: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,14}$/,
      'A senha deve conter pelo menos 8 à 14 caracteres, incluindo letras maiúsculas e minúsculas, números caracteres especiais',
    ),
  password2: yup
    .string()
    .required('Campo obrigatório.')
    .oneOf([yup.ref('password')], 'As senhas devem ser iguais.'),
});

export const FormOne = ({ onNextStep }: any) => {
  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      nome: '',
      sobrenome: '',
      cpf: '',
      email: '',
      celular: '',
      password: '',
      password2: '',
    },
  });

  const onSubmit = async (data: any) => {
    const cleanData = { ...data };

    if (cleanData.cpf || cleanData.celular) {
      cleanData.cpf = cleanData.cpf.replace(/\D/g, '');
      cleanData.celular = cleanData.celular.replace(/\D/g, '');
    }

    onNextStep(cleanData);
  };

  return (
    <main>
      <Center>
        <Box
          background="#EEE"
          borderRadius="37px"
          boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="36px"
            fontWeight="500"
            padding="38px 0px 41px 0px"
          >
            Cadastro
          </Text>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Stack px="49px" spacing={15} paddingBottom="38px">
                <InputComponent
                  name="nome"
                  placeholder="Nome"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                />

                <InputComponent
                  name="sobrenome"
                  placeholder="Sobrenome"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                />

                <InputComponent
                  name="cpf"
                  mask="***.***.***-**"
                  placeholder="CPF"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                />

                <InputComponent
                  name="email"
                  placeholder="Digite seu e-mail"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                />

                <InputComponent
                  name="celular"
                  placeholder="DDD + Telefone"
                  mask="(**) *****-****"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                />

                <InputComponent
                  name="password"
                  placeholder="Senha"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  type="password"
                />

                <InputComponent
                  name="password2"
                  placeholder="Confirmar senha"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontWeight: '400',
                    fontSize: 16,
                  }}
                  w="370px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  type="password"
                />

                <Box mt="15px">
                  <Button
                    w="100%"
                    h="52px"
                    background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #31302F"
                    color="#FFF"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                    leftIcon={<Image src={iconRegister} />}
                    boxShadow="0px 15px 30px 0px rgba(117, 76, 20, 0.25)"
                    type="submit"
                  >
                    Proxima etapa
                  </Button>
                </Box>
              </Stack>
            </form>
          </FormProvider>
        </Box>
      </Center>
    </main>
  );
};
