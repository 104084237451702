import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  reopen: () => void;
}

export const ModalConfirm: React.FC<Props> = ({ isOpen, onClose, reopen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent>
        <ModalBody p="20px">
          <Text fontWeight="500" fontSize="18px">
            Deseja reabrir esta AGC?
          </Text>

          <HStack justify="space-between" mt="20px">
            <Button
              variant="outline"
              borderColor="primary"
              borderWidth="2px"
              onClick={onClose}
              fontWeight="500"
              fontSize="16px"
              w="50%"
              h="51px"
            >
              Cancelar
            </Button>

            <Button
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
              boxShadow="none"
              borderWidth="1px"
              fontWeight="500"
              fontSize="15px"
              color="white"
              w="50%"
              h="51px"
              onClick={reopen}
            >
              Confirmar
            </Button>
          </HStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
