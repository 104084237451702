import {
  Box,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { api } from '../../services/api';
import { ModalMember } from './components/modals/modalMembers';

interface Props {
  id: number;
  usuario_id: number;
  usuario_nome: string | undefined;
  usuario_cpf: string | undefined;
  usuario_saldo_atual: number;
  autorizado: boolean;
}

export const Ajs: React.FC = () => {
  const [ajs, setAjs] = useState<Props[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  const [id, setId] = useState<number>(0);

  useEffect(() => {
    getAjs();
  }, []);

  const tableHead = [
    { name: 'Nome', id: 'usuario_nome' },
    { name: 'CPF', id: 'usuario_cpf' },
    { name: 'Ações', id: 'acoes' },
  ];

  const getAjs = async () => {
    try {
      const response = await api.get('/aj/');
      if (response.status === 200 || response.status === 201) {
        setAjs(response.data);
      }
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      background="#EEE"
      boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 15px 30px rgba(0, 0, 0, 0.20)"
      borderRadius="30px"
      minH="86vh"
      marginTop="25px"
    >
      <Box p="32px 37px 15px 64px">
        <Text color="#222" fontFamily="Rubik" fontSize="30px" fontWeight="500">
          AJ's
        </Text>
      </Box>
      <Box
        mt="20px"
        borderRadius="30px"
        fill="#DADADA"
        boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset"
        p="20px 10px 0px 10px"
      >
        <TableContainer>
          <Table variant="simple" size="sm">
            <Thead borderBottom="2px solid #9B9B9B">
              <Tr>
                {tableHead.map((values) => (
                  <Th
                    key={values.id}
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    {values.name}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {ajs &&
                ajs.length > 0 &&
                ajs.map((item) => {
                  return (
                    <Tr
                      _hover={{
                        bg: '#E4E4E4',
                        borderLeft: '3px solid #DCAC36',
                        borderBottom: 'none',
                      }}
                      borderBottom="2px solid #CCC"
                      fontWeight="400"
                      fontFamily="Rubik"
                      color="#000"
                      fontSize="14px"
                      key={item.id}
                    >
                      <Td>{item.usuario_nome}</Td>
                      <Td>{item.usuario_cpf}</Td>
                      <Td>
                        <Button
                          h="25px"
                          borderRadius="71px"
                          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                          fontSize="14px"
                          fontFamily="Rubik"
                          fontWeight="400"
                          color="#FFF"
                          onClick={() => {
                            setOpen(true);
                            setId(item.id);
                          }}
                        >
                          Equipe
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      <ModalMember isOpen={open} onClose={() => setOpen(false)} id={id} />
    </Box>
  );
};
