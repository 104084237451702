/* eslint-disable no-plusplus */
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiCheckSquare } from 'react-icons/fi';
import { FaEllipsisH } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../services/api';
import { ModalCreate } from './components/modalCreate';

interface PropsAgcs {
  nome: string;
  convocacao: string;
  data_credenciamento: string;
  hora_instalacao: string;
  encerrada: boolean;
  suspensa: boolean;
  id: number;
}

export const AllAgcsList: React.FC = () => {
  const navigate = useNavigate();

  const [agcs, setAgcs] = useState<PropsAgcs[]>([]);

  const [filterText, setFilterText] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const itemsPerPage = 10;

  useEffect(() => {
    if (filterText.length >= 5 || filterText.length === 0) {
      allAgcs();
    }
  }, [currentPage, filterText]);

  const tableHead = [
    { name: 'Nome', id: 'nome' },
    { name: 'Convocação', id: 'convocacao' },
    { name: 'Credenciamento', id: 'credenciamento' },
    { name: 'Instalação', id: 'instalacao' },
    { name: 'Encerrada', id: 'encerrada' },
    { name: 'Suspensa', id: 'suspensa' },
    { name: 'Ações', id: 'acoes' },
  ];

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const allAgcs = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/agc/?limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }&search=${filterText}&tipo_pesquisa=todas`,
      );
      const { results, count } = response.data;
      if (response.status === 200 || response.status === 201) {
        setAgcs(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handleFilterChange = (event: any) => {
    const newFilterText = event.target.value;
    setFilterText(newFilterText);
  };

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 3;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const paginationButtons = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
    }

    if (startPage !== 1) {
      paginationButtons.push(
        <Button
          key={1}
          onClick={() => handlePageChange(1)}
          fontWeight={currentPage === 1 ? 'bold' : 'normal'}
          color={currentPage === 1 ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === 1
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          1
        </Button>,
      );
    }

    if (startPage > 2) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          fontWeight={currentPage === i ? 'bold' : 'normal'}
          color={currentPage === i ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === i
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {i}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    if (endPage !== totalPages) {
      paginationButtons.push(
        <Button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          fontWeight={currentPage === totalPages ? 'bold' : 'normal'}
          color={currentPage === totalPages ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === totalPages
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {totalPages}
        </Button>,
      );
    }

    return paginationButtons;
  };

  return (
    <Box p="20px 0px 0px 0px">
      <Flex p="0px 0px 36px 0px">
        <InputGroup>
          <InputLeftElement mt="4px" pl="36px">
            <SearchIcon color="#AAA" w="20px" h="20px" />
          </InputLeftElement>
          <Input
            boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.05)"
            placeholder="Pesquisa por nome"
            _placeholder={{
              color: '#888',
              fontFamily: 'Rubik',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '28px',
              letterSpacing: '1.6px',
            }}
            pl="79px"
            h="56px"
            mr="32px"
            onChange={handleFilterChange}
          />
        </InputGroup>

        <Button
          color="#FFF"
          fontFamily="Rubik"
          fontSize="14px"
          fontWeight="500"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%)"
          h="56px"
          w="20%"
          leftIcon={<FiCheckSquare size="22px" />}
          onClick={() => setOpen(true)}
        >
          Criar nova AGC
        </Button>
      </Flex>

      {loading && (
        <Flex justify="center" mt="10%">
          <Spinner size="xl" color="#DF8E25" />
        </Flex>
      )}

      {!loading && (
        <Box>
          <TableContainer>
            <Table variant="simple" size="sm">
              {agcs.length > 0 ? (
                <Thead borderBottom="2px solid #9B9B9B">
                  <Tr>
                    {tableHead.map((values) => (
                      <Th
                        key={values.id}
                        color="#000"
                        fontFamily="Rubik"
                        fontSize="12px"
                        fontWeight="700"
                      >
                        {values.name}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
              ) : (
                <Stack align="center" spacing="15px">
                  <Text
                    color="#222"
                    fontFamily="Rubik"
                    fontSize="30px"
                    fontWeight="400"
                  >
                    Você não é credenciado em nenhuma AGC
                  </Text>
                  <Text
                    color="#AAA"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                    letterSpacing="1.6px"
                  >
                    Credenciei em alguma agc abaixo
                  </Text>

                  <Button
                    bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                    boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                    color="#FFF"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="500"
                    h="34px"
                  >
                    Credenciar
                  </Button>
                </Stack>
              )}
              <Tbody>
                {agcs &&
                  agcs.length > 0 &&
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
                  agcs.map((values, index) => {
                    const formattedData = (data: any) => {
                      if (typeof data !== 'undefined') {
                        const today = new Date(data);
                        const month =
                          today.getMonth() + 1 < 10
                            ? `0${today.getMonth() + 1}`
                            : today.getMonth() + 1;
                        const day =
                          today.getDate() < 10
                            ? `0${today.getDate()}`
                            : today.getDate();
                        const hours =
                          today.getHours() < 10
                            ? `0${today.getHours()}`
                            : today.getHours();
                        const minutes =
                          today.getMinutes() < 10
                            ? `0${today.getMinutes()}`
                            : today.getMinutes();

                        const dateNow = `${day}/${month}/${today.getFullYear()} às ${hours}:${minutes}`;

                        return dateNow;
                      }
                      return '';
                    };

                    const formatTime = (time: any) => {
                      if (typeof time !== 'undefined') {
                        const timeNow = time.split(':');

                        return `${timeNow[0]}:${timeNow[1]}`;
                      }

                      return '';
                    };

                    return (
                      <Tr
                        _hover={{
                          bg: '#E4E4E4',
                          borderLeft: '3px solid #DCAC36',
                          borderBottom: 'none',
                        }}
                        borderBottom="2px solid #CCC"
                        fontWeight="400"
                        fontFamily="Rubik"
                        color="#000"
                        fontSize="14px"
                        key={values.id}
                        onClick={() => navigate(`/agcs/${values.id}`)}
                        cursor="pointer"
                      >
                        <Td>{values.nome}</Td>
                        <Td>
                          {values.convocacao === '1a-convocacao'
                            ? '1° Convocação'
                            : '2° Convocação'}
                        </Td>
                        <Td>{formattedData(values.data_credenciamento)}</Td>
                        <Td>{formatTime(values.hora_instalacao)}</Td>
                        <Td>{values.encerrada ? 'Sim' : 'Não'}</Td>
                        <Td>{values.suspensa ? 'Sim' : 'Não'}</Td>
                        <Td>
                          <Button
                            h="25px"
                            borderRadius="71px"
                            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                            fontSize="14px"
                            fontFamily="Rubik"
                            fontWeight="400"
                            color="#FFF"
                          >
                            Ver
                          </Button>
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>

            <HStack py="20px" align="center" justify="flex-end">
              {renderPaginationButtons()}
            </HStack>
          </TableContainer>
        </Box>
      )}

      <ModalCreate
        isOpen={open}
        onClose={() => setOpen(false)}
        stateAgc={setAgcs}
      />
    </Box>
  );
};
