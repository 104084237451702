import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '@phosphor-icons/react';
import background from '../../../assets/images/backgroundLogin.png';
import logo from '../../../assets/images/logoBig.png';
import quotation from '../../../assets/images/quotation.png';

const Start: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      h="100vh"
      backgroundImage={`url(${background})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <header>
        <Flex justifyContent="flex-end" padding="40px">
          <Button
            leftIcon={<User color="#DCAC36" weight="bold" />}
            onClick={() => navigate('/login')}
            color="#FFFFFF"
            paddingX="35px"
            backgroundColor="#31302F"
          >
            Fazer Login
          </Button>
        </Flex>
      </header>
      <Box display="flex" justifyContent="center">
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Image src={logo} />
          <Box
            alignItems="center"
            display="flex"
            flexDir="column"
            justifyContent="center"
          >
            <Box width="60%">
              <Image src={quotation} position="absolute" mt={2} />
              <Text
                fontFamily="Rubik"
                fontWeight="400"
                fontSize={44}
                color="#FFFFFF"
                textAlign="center"
                ml={3}
              >
                Concentre-se em ser produtivo em vez de estar ocupado
              </Text>
            </Box>
          </Box>
          <Box w="30%" mt="40px">
            <Button
              background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #DCAC36"
              w="full"
              color="#FFFFFF"
              fontWeight="400"
              fontSize="20px"
              height="50px"
              onClick={() => navigate('/login')}
            >
              Entrar
            </Button>

            <Button
              w="full"
              mt="20px"
              h="50px"
              color="#222"
              fontWeight="400"
              fontSize="18px"
              boxShadow="0px 15px 25px 0px rgba(0, 0, 0, 0.15)"
              onClick={() => navigate('/register')}
            >
              Cadastre-se
            </Button>
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        height="38%"
        alignItems="flex-end"
      >
        <Box
          w="40%"
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="column"
        >
          <Box w="100%" h="2px" background="rgba(70, 80, 81, 0.50)" />
          <Text color="#FFFFFF" fontSize="18px" fontWeight="400" mt="40px">
            Todos os direitos reservados a E-AGC © 2021
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default Start;
