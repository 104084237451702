import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputComponent from '../../../../../../components/forms/input';
import Close from '../../../../../../assets/icons/close.svg';
import Arrow from '../../../../../../assets/icons/arrowRight.svg';
import { api } from '../../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  // eslint-disable-next-line no-unused-vars
  stateAgc: (prevState: any) => void;
}

interface FieldError {
  message: string;
}

const schema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório.'),
  numero_processo: yup.string().required('Campo obrigatório.'),
  natureza: yup.string().required('Campo obrigatório.'),
  data_credenciamento: yup.string().required('Campo obrigatório.'),
  hora_instalacao: yup.string().required('Campo obrigatório.'),
  convocacao: yup.string().required('Campo obrigatório.'),
  hora_credenciamento: yup.string().required('Campo obrigatório.'),
});

export const ModalCreate: React.FC<Props> = ({ isOpen, onClose, stateAgc }) => {
  const [ajId, setAjId] = useState(0);

  useEffect(() => {
    if (isOpen) {
      getAj();
    }
  }, [isOpen]);

  const getAj = async () => {
    try {
      const response = await api.get('aj/');
      if (response.status === 200 || response.status === 201) {
        setAjId(response.data[0].id);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  console.log(ajId);

  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      aj_id: 0,
      nome: '',
      numero_processo: '',
      natureza: '',
      data_credenciamento: '',
      hora_credenciamento: '',
      hora_instalacao: '',
      convocacao: '',
      observacao: '',
    },
  });

  const onSubmit = async (dataAgc: any) => {
    try {
      const dataCredenciamentoFormatted = `${dataAgc.data_credenciamento}T${dataAgc.hora_credenciamento}`;

      const dataOnSubmit = {
        aj_id: ajId,
        numero_processo: dataAgc.numero_processo,
        nome: dataAgc.nome,
        natureza: dataAgc.natureza,
        observacao: dataAgc.observacao,
        data_credenciamento: dataCredenciamentoFormatted,
        hora_instalacao: dataAgc.hora_instalacao,
        convocacao: dataAgc.convocacao,
      };

      const response = await api.post('agc/', dataOnSubmit);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        onClose();
        stateAgc((prevState: any) => [data, ...prevState]);
        formMethods.reset();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const { formState } = formMethods;
  const { errors } = formState;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody p="45px 20px" bg="#EEE" borderRadius="8px">
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Stack spacing="17px">
                <HStack spacing="37px">
                  <InputComponent
                    name="nome"
                    w="100%"
                    placeholder="Nome da AGC"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                      fontWeight: '400',
                      letterSpacing: '0.8px',
                    }}
                    h="53px"
                  />
                  <InputComponent
                    name="numero_processo"
                    placeholder="N° Processo"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontSize: '16px',
                      fontWeight: '400',
                      letterSpacing: '0.8px',
                    }}
                    h="53px"
                  />
                </HStack>

                <Controller
                  name="natureza"
                  control={formMethods.control}
                  render={({ field }) => (
                    <Box>
                      <Select
                        {...field}
                        placeholder="Natureza"
                        _placeholder={{
                          color: '#AAA',
                          fontFamily: 'Rubik',
                          fontSize: '16px',
                          fontWeight: '400',
                          letterSpacing: '0.8px',
                        }}
                        h="53px"
                        bg="#FFF"
                        borderRadius="43px"
                        boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                        color="#AAA"
                        fontFamily="Rubik"
                        fontSize="16px"
                        fontWeight="400"
                        _focus={{ border: 'none' }}
                        _focusVisible={{ border: 'none' }}
                        iconColor="#8A8A8A"
                        iconSize="40px"
                        w="237px"
                      >
                        <option value="recuperacao-judicial">
                          Recuperação Judicial
                        </option>
                        <option value="falencia">Falência</option>
                      </Select>
                      {errors.natureza !== undefined && (
                        <Text
                          textAlign="start"
                          marginLeft="15px"
                          marginTop="4px !important"
                          lineHeight="12px"
                          fontSize="12px"
                          color="red.400"
                          textStyle="body.small"
                        >
                          {(errors.natureza as FieldError).message}
                        </Text>
                      )}
                    </Box>
                  )}
                />

                <Stack spacing="0px">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Data e hora de credenciamento
                  </Text>

                  <HStack spacing="18px" mt="7px">
                    <InputComponent
                      type="date"
                      h="54px"
                      color="#AAA"
                      placeholder="DD / MM / AAAA"
                      name="data_credenciamento"
                      w="100%"
                    />
                    <InputComponent
                      type="time"
                      h="54px"
                      color="#AAA"
                      name="hora_credenciamento"
                      w="100%"
                    />
                  </HStack>
                </Stack>

                <Box w="48%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Hora instalação
                  </Text>

                  <Box mt="7px">
                    <InputComponent
                      type="time"
                      h="54px"
                      color="#AAA"
                      name="hora_instalacao"
                    />
                  </Box>
                </Box>

                <Controller
                  name="convocacao"
                  control={formMethods.control}
                  render={({ field }) => (
                    <Box>
                      <Select
                        {...field}
                        placeholder="Convocação"
                        _placeholder={{
                          color: '#AAA',
                          fontFamily: 'Rubik',
                          fontSize: '16px',
                          fontWeight: '400',
                          letterSpacing: '0.8px',
                        }}
                        h="53px"
                        bg="#FFF"
                        borderRadius="43px"
                        boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                        color="#AAA"
                        fontFamily="Rubik"
                        fontSize="16px"
                        fontWeight="400"
                        _focus={{ border: 'none' }}
                        _focusVisible={{ border: 'none' }}
                        iconColor="#8A8A8A"
                        iconSize="40px"
                        w="48%"
                      >
                        <option value="1a-convocacao">1° Convocação</option>
                        <option value="2a-convocacao">2° Convocação</option>
                      </Select>
                      {errors.convocacao !== undefined && (
                        <Text
                          textAlign="start"
                          marginLeft="15px"
                          marginTop="4px !important"
                          lineHeight="12px"
                          fontSize="12px"
                          color="red.400"
                          textStyle="body.small"
                        >
                          {(errors.convocacao as FieldError).message}
                        </Text>
                      )}
                    </Box>
                  )}
                />

                <InputComponent
                  placeholder="Observações"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: '400',
                    letterSpacing: '0.8px',
                  }}
                  h="53px"
                  w="100%"
                  name="observacao"
                />

                <HStack mt="45px" justify="space-between">
                  <Button
                    h="51px"
                    borderRadius="45px"
                    bg="none"
                    border="1px solid #DF8E25"
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="14px"
                    fontWeight="400"
                    onClick={() => {
                      onClose();
                      formMethods.reset();
                    }}
                  >
                    <Image src={Close} pr="10px" />
                    Cancelar
                  </Button>

                  <Button
                    h="51px"
                    borderRadius="31px"
                    color="white"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                    bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                    p="16px 27px"
                    letterSpacing="0.8px"
                    type="submit"
                  >
                    Continuar
                    <Image src={Arrow} ml="17px" />
                  </Button>
                </HStack>
              </Stack>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
