import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiFolderWarningLine } from 'react-icons/ri';
import Attorneys from '../../../../../assets/icons/archive.svg';
import { api } from '../../../../../services/api';

interface Props {
  id: string | undefined;
  idAgc: string | undefined;
}

interface PropsAttorneys {
  procurador_nome: string | undefined;
  data_credenciamento: string | null;
}

export const QualifiedAttorneys: React.FC<Props> = ({ id, idAgc }) => {
  const [attorneys, setAttorneys] = useState<PropsAttorneys[]>([]);

  const [profileUser, setProfileUser] = useState<string | undefined>('');

  useEffect(() => {
    getCreditorsAttorneys();
    typeProfile();
  }, [id]);

  const getCreditorsAttorneys = async () => {
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_credor_id=${id}&agc_id=${idAgc}&habilitado=true`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setAttorneys(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const typeProfile = async () => {
    try {
      const response = await api.get(`/agc/${idAgc}/perfil-usuario-logado/`);
      if (response.status === 200 || response.status === 201) {
        const { perfil } = response.data;
        setProfileUser(perfil);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p="24px 16px" bg="#FFF" borderRadius="31px" h="481px" w="100%">
      <Stack
        spacing="16px"
        maxH="100%"
        overflowY="auto"
        overflowX="hidden"
        pr="10px"
        h="100%"
        sx={{
          '&::-webkit-scrollbar': {
            width: '3px',
            marginLeft: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
            borderRadius: 'full',
          },
          '&::-webkit-scrollbar-track': {
            width: '8px',
            bg: '#CCC',
            borderRadius: '10px',
          },
        }}
      >
        <HStack spacing="33px">
          <Image src={Attorneys} />{' '}
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Representantes Aprovados
          </Text>
        </HStack>

        {profileUser !== 'procurador-credor' && (
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="80%"
          >
            <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
            <Text
              color="#222222"
              fontFamily="Rubik"
              fontWeight="500"
              textAlign="center"
            >
              Você não tem permissão para visualizar tais Representantes
            </Text>
          </Box>
        )}

        {profileUser === 'procurador-credor' && attorneys.length === 0 && (
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="80%"
          >
            <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
            <Text
              color="#222222"
              fontFamily="Rubik"
              fontWeight="500"
              textAlign="center"
            >
              Nenhum procurador aprovado encontrado
            </Text>
          </Box>
        )}

        {profileUser === 'procurador-credor' &&
          attorneys &&
          attorneys.length > 0 &&
          attorneys.map((item) => (
            <Stack
              p="9px 10px"
              borderRadius="12px"
              border="1px solid #E7E7E7"
              w="100%"
            >
              <Text
                color="#515151"
                fontFamily="Rubik"
                fontSize="18px"
                fontWeight="400"
                letterSpacing="0.45px"
              >
                {item.procurador_nome}
              </Text>

              <Text color="#A0A0A0" fontSize="12px" fontWeight="400">
                {item.data_credenciamento}
              </Text>
            </Stack>
          ))}
      </Stack>
    </Box>
  );
};
