import { Box, Button, HStack, Image, Stack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Archive from '../../../../assets/icons/archive.svg';
import Folder from '../../../../assets/icons/FolderNotchOpen.svg';
import StackIcon from '../../../../assets/icons/Stack.svg';
import { CheckboxAgc } from '../../components/checkbox';
import Close from '../../../../assets/icons/close.svg';
import Arrow from '../../../../assets/icons/arrowRight.svg';
import ArrowLeft from '../../../../assets/icons/leftArrow.svg';

export const FormTwoAgc = ({ handlePrevious, handleNext }: any) => {
  const [classesSelected, setClassesSelected] = useState<string[]>([]);

  const classes = [
    { name: 'Trabalhista', value: 'trabalhista' },
    { name: 'Garantia Real', value: 'garantia-real' },
    { name: 'Quirografário', value: 'quirografario' },
    { name: 'Microempresa', value: 'microempresa' },
  ];

  const navigate = useNavigate();

  const handleCheckboxChange = (value: string, checked: boolean) => {
    if (checked) {
      setClassesSelected([...classesSelected, value]);
    } else {
      setClassesSelected(classesSelected.filter((item) => item !== value));
    }
  };

  const nextStep = () => {
    handleNext(classesSelected);
  };

  return (
    <Box>
      <Stack mt="33px" spacing="51px">
        <Stack spacing="22px" w="193px">
          <HStack spacing="33px">
            <Image src={Archive} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              letterSpacing="1.8px"
              fontWeight="400"
            >
              Pendências
            </Text>
          </HStack>

          <Button
            color="#FFF"
            fontFamily="Rubik"
            fontSize="12px"
            fontWeight="500"
            borderRadius="19px"
            background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            h="32px"
            w="80%"
          >
            Adicionar documento
          </Button>
        </Stack>

        <Stack spacing="22px" w="193px">
          <HStack spacing="33px">
            <Image src={Folder} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              letterSpacing="1.8px"
              fontWeight="400"
            >
              Documentos
            </Text>
          </HStack>

          <Button
            color="#FFF"
            fontFamily="Rubik"
            fontSize="12px"
            fontWeight="500"
            borderRadius="19px"
            background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            h="32px"
            w="80%"
          >
            Adicionar documento
          </Button>
        </Stack>

        <Stack spacing="22px">
          <HStack spacing="33px">
            <Image src={StackIcon} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              letterSpacing="1.8px"
              fontWeight="400"
            >
              Classes
            </Text>
          </HStack>

          <HStack spacing="36px">
            {classes.map((item) => {
              return (
                <CheckboxAgc
                  name={item.name}
                  key={item.value}
                  value={item.value}
                  onChange={(e) =>
                    handleCheckboxChange(item.value, e.target.checked)
                  }
                />
              );
            })}
          </HStack>
        </Stack>
      </Stack>
      <HStack mt="77px" spacing="29px">
        <Button
          h="51px"
          borderRadius="45px"
          bg="none"
          border="1px solid #DF8E25"
          color="#000"
          fontFamily="Rubik"
          fontSize="14px"
          fontWeight="400"
          onClick={() => navigate('/agcs')}
        >
          <Image src={Close} pr="10px" />
          Cancelar
        </Button>

        <Button
          h="51px"
          borderRadius="31px"
          color="white"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          p="16px 27px"
          letterSpacing="0.8px"
          onClick={handlePrevious}
        >
          <Image src={ArrowLeft} paddingRight="17px" />
          Voltar
        </Button>

        <Button
          h="51px"
          borderRadius="31px"
          color="white"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          p="16px 27px"
          letterSpacing="0.8px"
          onClick={nextStep}
        >
          Continuar
          <Image src={Arrow} ml="17px" />
        </Button>
      </HStack>
    </Box>
  );
};
