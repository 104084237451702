import {
  Box,
  Center,
  Flex,
  HStack,
  Icon,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { PiFileDocDuotone } from 'react-icons/pi';
import moment from 'moment';
import { useDocs } from '../../hooks/useDocs';
import { useStream } from '../../context/stream.context';

const DocsTabComponent: React.FC = () => {
  const { callId } = useStream();

  const { docs, getDocs, loading } = useDocs(Number(callId));

  useEffect(() => {
    getDocs();
  }, [callId]);

  const downloadFile = (url: string, filename: string) => {
    fetch(url).then((t) =>
      t.blob().then((b) => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('target', '_blank');
        a.setAttribute('download', filename);
        a.click();
      }),
    );
  };

  return (
    <Flex
      flex="0.6"
      flexDirection="column"
      bg="white"
      borderRight="1px solid #ddd"
      h="full"
      shadow="0px 15px 30px 0px rgba(0, 0, 0, 0.1)"
    >
      <Flex p="30px" flexDir="column" flex="1 1 0" overflowX="auto">
        <HStack justify="space-between" pb="10px">
          <Text fontSize="20px">Documentos</Text>
        </HStack>

        {docs?.length === 0 && !loading && (
          <Text fontSize="14px" opacity={0.4}>
            Nenhum documento encontrado
          </Text>
        )}

        {loading && (
          <Center p="30px">
            <Spinner />
          </Center>
        )}

        {docs.map((doc) => (
          <Box
            cursor="pointer"
            onClick={() => {
              downloadFile(doc.arquivo_link, doc.data_documento);
            }}
            p="10px 20px"
            borderRadius="8px"
            border="1px solid #ddd"
            mt="10px"
          >
            <HStack>
              <Flex
                p="10px"
                bg="gray.200"
                borderColor="gray.300"
                borderWidth="2px"
                borderRadius="50px"
              >
                <Icon as={PiFileDocDuotone} color="gray.600" fontSize="22px" />
              </Flex>
              <Stack spacing="0px">
                <Text fontSize="18px">
                  {doc.tipo_documento}-{doc.id}
                </Text>
                <Text opacity={0.7} fontSize="10px">
                  ARQUIVO | 0.5MB
                </Text>
                {doc?.data_documento && (
                  <Text fontSize="12px" opacity={0.4}>
                    {moment(doc?.data_documento).format('DD/MM/yyyy')}
                  </Text>
                )}
              </Stack>
            </HStack>
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default DocsTabComponent;
