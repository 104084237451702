import {
  Box,
  Button,
  CSSReset,
  ChakraProvider,
  HStack,
  Image,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { RiFolderWarningLine } from 'react-icons/ri';
import iconTitle from '../../../../assets/icons/archive.svg';
import { api } from '../../../../services/api';
import { ModalView } from './modalView';

interface Props {
  id: string | undefined;
}

interface PropsDocuments {
  agc_credor_nome: string | undefined;
  agc_credor_procurador_nome: string | undefined;
  agc_credor_documento: string | undefined;
  agc_credor_procurador_documento_data: string | undefined;
  agc_credor_procurador_documento_id: number;
}

export const Pendencies: React.FC<Props> = ({ id }) => {
  const [documents, setDocuments] = useState<PropsDocuments[]>([]);

  const [idDoc, setIdDoc] = useState<number>(0);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    analysisDocument();
  }, []);

  const analysisDocument = async () => {
    try {
      const response = await api.get(
        `agc/${id}/analise-documento/?status=em-analise`,
      );
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        setDocuments(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openDoc = (value: number) => {
    setOpen(true);
    setIdDoc(value);
  };

  return (
    <Box
      bg="#FFF"
      borderRadius="31px"
      p="25px 21px 25px 25px"
      h="380px"
      w="100%"
    >
      <CSSReset />
      <ChakraProvider theme={theme}>
        <Stack
          spacing="22px"
          maxH="100%"
          overflowY="auto"
          overflowX="hidden"
          pr="10px"
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
              height: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
              borderRadius: 'full',
            },
            '&::-webkit-scrollbar-track': {
              width: '8px',
              bg: '#CCC',
              borderRadius: '10px',
            },
          }}
          h="100%"
        >
          <HStack spacing="33px" align="center">
            <Image src={iconTitle} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              Pendências
            </Text>
          </HStack>

          {documents.length === 0 && (
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              h="50%"
            >
              <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
              <Text color="#222222" fontFamily="Rubik" fontWeight="500">
                Nenhuma pendência encontrada!
              </Text>
            </Box>
          )}

          {documents &&
            documents?.length > 0 &&
            documents?.map((item) => {
              const formattedData = () => {
                if (item.agc_credor_procurador_documento_data) {
                  const today: Date = new Date(
                    item.agc_credor_procurador_documento_data,
                  );
                  const month =
                    today.getMonth() + 1 < 10
                      ? `0${today.getMonth() + 1}`
                      : today.getMonth() + 1;
                  const day =
                    today.getDate() < 10
                      ? `0${today.getDate()}`
                      : today.getDate();
                  const hours =
                    today.getHours() < 10
                      ? `0${today.getHours()}`
                      : today.getHours();
                  const minutes =
                    today.getMinutes() < 10
                      ? `0${today.getMinutes()}`
                      : today.getMinutes();

                  const dateNow = `${day}/${month}/${today.getFullYear()} - ${hours}:${minutes} AM`;

                  return dateNow;
                }

                return '';
              };
              return (
                <HStack
                  borderRadius="12px"
                  border="1px solid #E7E7E7"
                  p="9px 10px"
                  justify="space-between"
                >
                  <Stack spacing="10px">
                    <Text color="#515151" fontSize="16px" fontWeight="500">
                      {item.agc_credor_nome} - {item.agc_credor_procurador_nome}
                    </Text>
                    <Text color="#A0A0A0" fontSize="12px" fontWeight="400">
                      {formattedData()}
                    </Text>
                  </Stack>

                  <Button
                    bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                    color="white"
                    borderRadius="71px"
                    w="68px"
                    h="30px"
                    onClick={() =>
                      openDoc(item.agc_credor_procurador_documento_id)
                    }
                  >
                    Ver
                  </Button>
                </HStack>
              );
            })}
        </Stack>
      </ChakraProvider>

      <ModalView id={idDoc} isOpen={open} onClose={() => setOpen(false)} />
    </Box>
  );
};
