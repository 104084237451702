import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  // Center,
  Flex,
  HStack,
  Icon,
  Image,
  // Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { BsCheck2All } from 'react-icons/bs';
import { MdOutlineCoPresent, MdOutlineLogout } from 'react-icons/md';
import { FaRegAddressCard } from 'react-icons/fa';
import { TbUsersGroup } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
// import VideoBox from '../components/VideoBox';
import moment from 'moment';
import { useStream } from '../context/stream.context';
import LeftMenuChatAndPeoples from '../components/LeftMenu';
import PainelVotacao from '../components/Votacao';
import DocsTabComponent from '../components/Docs';
import { CredoresModal } from '../components/Credores/credores-modal';
import { CredenciamentoModal } from '../components/Credores/credenciamento-modal';
import ModalVotar from '../components/Votacao/ModalVotar';
import { useAGC } from '../hooks/useAGC';
import { useWebsocket } from '../hooks/useWebsocket';
import SuspenderModal from '../components/SuspenderModal';
import { VideoSDKView } from '../../VideoSDKView';
import { ZOOM_SDK_KEY, ZOOM_SDK_SECRET } from '../../../services/zoom-skd';
import { generateSignature } from '../utils/singature';

export function ZoomComponent() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tab, setTab] = useState('principal');
  const push = useNavigate();
  const { callId, currentUserBackend, sairDaAssembleia, encerrarAssembleia } =
    useStream();

  const { iniciarPerguntas, deslogarTodos, suspensao } = useWebsocket();
  const { listarVotantes, entrar, encerrar } = useAGC(Number(callId));
  const isAdmin = currentUserBackend?.admin === true;

  useEffect(() => {
    if (callId) {
      entrar();
    }
  }, [callId]);

  useEffect(() => {
    if (iniciarPerguntas?.id) {
      listarVotantes(iniciarPerguntas?.id).then((votantes) => {
        if ((votantes ?? []).includes(Number(currentUserBackend?.id))) {
          onOpen();
        }
      });
    }
  }, [iniciarPerguntas, currentUserBackend]);

  const {
    isOpen: isCredoresModalOpen,
    onOpen: onCredoresModalOpen,
    onClose: onCredoresModalClose,
  } = useDisclosure();

  const {
    isOpen: isCredenciamentoModalOpen,
    onOpen: onCredenciamentoModalOpen,
    onClose: onCredenciamentoModalClose,
  } = useDisclosure();

  const {
    isOpen: isSuspenderModalOpen,
    onOpen: onSuspenderModalOpen,
    onClose: onSuspenderModalClose,
  } = useDisclosure();

  const signature = generateSignature(
    ZOOM_SDK_KEY,
    ZOOM_SDK_SECRET,
    callId!,
    currentUserBackend?.admin ? 1 : 0,
  );

  const [isSupensed, setIsSuspensed] = useState<boolean>(false);

  const activeSuspensao = suspensao?.find((item) =>
    moment().isBefore(moment(item.data_retomada).add(3, 'hours')),
  );

  useEffect(() => {
    if (activeSuspensao) {
      setIsSuspensed(true);
    }
  }, [activeSuspensao]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const isSus = suspensao?.find((item) =>
        moment().isBefore(moment(item.data_retomada).add(3, 'hours')),
      );
      setIsSuspensed(!!isSus);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [suspensao]);

  return (
    <Box w="full" h="full">
      <HStack
        bg="#F2F2F2"
        px="50px"
        h="70px"
        w="full"
        shadow="lg"
        zIndex={10}
        pos="relative"
        justify="space-between"
      >
        <Image src="/logo.png" w="53px" h="53px" />

        <HStack>
          {isAdmin && (
            <>
              <Button
                rightIcon={<Icon as={MdOutlineLogout} fontSize="20px" />}
                fontSize="16px"
                h="44px"
                borderRadius="10px"
                bg="white"
                onClick={onSuspenderModalOpen}
              >
                Suspender AGC
              </Button>
              <Button
                rightIcon={<Icon as={MdOutlineLogout} fontSize="20px" />}
                fontSize="16px"
                h="44px"
                borderRadius="10px"
                bg="white"
                onClick={async () => {
                  await deslogarTodos();
                  await encerrarAssembleia();
                  await encerrar();
                  push('/agcs');
                }}
              >
                Encerrar AGC
              </Button>
            </>
          )}
          <Button
            rightIcon={<Icon as={MdOutlineLogout} fontSize="20px" />}
            fontSize="16px"
            h="44px"
            borderRadius="10px"
            bg="white"
            onClick={async () => {
              await sairDaAssembleia();
              push('/agcs');
            }}
          >
            Sair da assembleia
          </Button>
        </HStack>
      </HStack>

      <HStack
        flex={1}
        h="full"
        w="full"
        spacing={0}
        align="start"
        flexFlow="row"
      >
        <Box flex={1}>
          <Stack
            display={!isSupensed ? 'none' : 'flex'}
            align="center"
            justify="center"
            bg="gray.300"
            flex={1}
            h="calc(100vh - 70px)"
            w="full"
          >
            <Text fontSize="30px">AGC Suspensa</Text>

            <Text fontSize="16px">
              Voltamos{' '}
              {moment(activeSuspensao?.data_retomada)
                .add(3, 'hours')
                .format('LLLL')}
            </Text>
          </Stack>

          {callId && signature && currentUserBackend?.name && (
            <Box display={isSupensed ? 'none' : 'block'}>
              <VideoSDKView
                meetingArgs={{
                  sdkKey: ZOOM_SDK_KEY,
                  sdkSecret: ZOOM_SDK_SECRET,
                  webEndpoint: 'zoom.us',
                  topic: callId,
                  name: currentUserBackend?.name,
                  password: '8wfb1x',
                  signature,
                  sessionKey: '',
                  userIdentity: '',
                  role: 1,
                }}
              />
            </Box>
          )}
        </Box>

        {/* <VideoBox users={users} currentUser={currentUser!} isAdmin={isAdmin} /> */}

        {tab === 'principal' && <LeftMenuChatAndPeoples isAdmin={isAdmin} />}

        {tab === 'votacao' && <PainelVotacao isAdmin={isAdmin} />}

        {tab === 'docs' && <DocsTabComponent />}

        <CredoresModal
          isOpen={isCredoresModalOpen}
          onClose={onCredoresModalClose}
        />

        <CredenciamentoModal
          isOpen={isCredenciamentoModalOpen}
          onClose={onCredenciamentoModalClose}
        />

        <Flex bg="white" h="full" maxW="100px">
          <Stack spacing={0}>
            {[
              {
                icon: MdOutlineCoPresent,
                title: 'Principal',
                name: 'principal',
              },
              {
                icon: FaRegAddressCard,
                title: 'Painel de Credenciamento',
                name: 'credenciamento',
              },
              {
                icon: BsCheck2All,
                title: 'Painel de Votações',
                name: 'votacao',
              },
              {
                icon: TbUsersGroup,
                title: 'Credores',
                name: 'credores',
              },
              {
                icon: MdOutlineCoPresent,
                title: 'Documentos Importantes',
                name: 'docs',
              },
            ].map((item, index) => {
              const active = item.name === tab;
              return (
                <Stack
                  userSelect="none"
                  _hover={{
                    bg: '#ddd',
                    cursor: 'pointer',
                  }}
                  transition="all 0.2s ease-in-out"
                  onClick={() => {
                    if (item.name === 'credores') {
                      onCredoresModalOpen();
                      return;
                    }
                    if (item.name === 'credenciamento') {
                      onCredenciamentoModalOpen();
                      return;
                    }
                    setTab(item.name);
                  }}
                  key={String(`${index}a`)}
                  bg={active ? '#FDCA5F' : 'white'}
                  color={active ? '#000000' : '#222222'}
                  align="center"
                  py="20px"
                  spacing={0}
                >
                  <Icon as={item.icon} fontSize="26px" />
                  <Text textAlign="center" fontSize="11px" px="5px">
                    {item.title}
                  </Text>
                </Stack>
              );
            })}
          </Stack>
        </Flex>
      </HStack>

      {isOpen && (
        <ModalVotar
          perguntaId={iniciarPerguntas?.id}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}

      <SuspenderModal
        isOpen={isSuspenderModalOpen}
        onClose={onSuspenderModalClose}
      />
    </Box>
  );
}
