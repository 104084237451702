import { ChatMessage } from '@zoom/videosdk';
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useStream } from '../context/stream.context';

type ChatMessageType = Omit<ChatMessage, 'message'> & {
  message: SendMessage;
};

interface IContext {
  sendMessage: (_message: SendMessage) => void;
  messages: ChatMessageType[];
}

const ChatContext = createContext<IContext>(null as any);

export const ChatProvider: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const { chatClient, client } = useStream();

  const removeDuplicate = (_messages: ChatMessageType[]) => {
    const map = new Map<string, ChatMessageType>();
    _messages.forEach((m) => {
      map.set(m.id!, m);
    });
    return Array.from(map.values());
  };

  const sendMessage = (message: SendMessage) => {
    console.log('send message', message);
    chatClient?.sendToAll(JSON.stringify(message));
  };

  useEffect(() => {
    client?.on('chat-on-message', (payload: any) => {
      setMessages((_messages) => [
        ..._messages,
        {
          ...payload,
          message: JSON.parse(payload.message),
        },
      ]);
    });
  }, [client]);

  return (
    <ChatContext.Provider
      value={{
        sendMessage,
        messages: removeDuplicate(messages),
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);

  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }

  return context;
};

interface SendMessage {
  text?: string;
  image?: string;
  doc?: any;
}
