import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import InputComponent from '../../../../components/forms/input';
import ArrowRight from '../../../../assets/icons/rightArrow.png';
import { api } from '../../../../services/api';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,14}$/,
      'A senha deve conter pelo menos 8 à 14 caracteres, incluindo letras maiúsculas e minúsculas, números caracteres especiais',
    ),
  password2: yup
    .string()
    .required('Campo obrigatório.')
    .oneOf([yup.ref('password')], 'As senhas devem ser iguais.'),
});

export const ResetPassword = ({ handlePrevious, formData }: any) => {
  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      password2: '',
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (value: any) => {
    try {
      const data = {
        token: formData.token,
        password: value.password,
      };

      const response = await api.post('/login/password-reset/confirm/', data);
      if (response.status === 200) {
        navigate('/login');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Box display="flex" flexDir="column" alignItems="center">
      <Text color="#222" fontFamily="Rubik" fontSize="36px" fontWeight="500">
        Resetar senha
      </Text>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack mt="41px" spacing="15px">
            <InputComponent
              w="370px"
              h="53px"
              placeholder="Senha"
              _placeholder={{
                color: '#AAA',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: '400',
              }}
              name="password"
              type="password"
            />
            <InputComponent
              w="370px"
              h="53px"
              placeholder="Confirmar senha"
              _placeholder={{
                color: '#AAA',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: '400',
              }}
              name="password2"
              type="password"
            />
            <Button
              borderRadius="31px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 15px 30px 0px rgba(117, 76, 20, 0.25)"
              color="white"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              type="submit"
            >
              Criar nova senha
              <Image src={ArrowRight} ml="17px" />
            </Button>
          </Stack>
        </form>
      </FormProvider>

      <Stack
        mt="30px"
        w="100%"
        display="flex"
        flexDir="column"
        alignItems="center"
        spacing="15px"
      >
        <Box bg="#DDD" h="1px" w="100%" />
        <Text
          color="#222"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          onClick={() => handlePrevious()}
          cursor="pointer"
        >
          Voltar
        </Text>
      </Stack>
    </Box>
  );
};
