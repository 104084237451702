import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Image,
  Input,
  Select,
  Stack,
  Text,
  Skeleton,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import CheckIcon from '../../../../../assets/icons/checkIcon.png';
import Dollar from '../../../../../assets/icons/dollar.svg';
import { api } from '../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (data: any) => void;
  // eslint-disable-next-line no-unused-vars
  statePrices: (data: Data) => void;
  estadoMoedas: any;
}

interface Data {
  id: number;
  agc_id: number;
  moeda: string;
  valor_cotacao: string;
}

export const DrawerEdit: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  onChange,
  statePrices,
  estadoMoedas,
}) => {
  const [prices, setPrices] = useState<Data[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const [errorExist, setErrorExist] = useState('');

  const [moedas, setMoedas] = useState(['USD', 'EUR', 'BRL', 'JPY', 'CNY']);

  const teste = prices.map((item) => item.moeda);

  useEffect(() => {
    if (isOpen) {
      getPrices();
    }
  }, [isOpen]);

  useEffect(() => {
    setMoedas((prevState) => prevState.filter((item) => !teste.includes(item)));
  }, [prices]);

  const getPrices = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/agc-cotacao/?agc_id=${id}`);
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setPrices(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deletePrices = async (values: any) => {
    setLoading(true);
    try {
      const response = await api.delete(`/agc-cotacao/${values.id}`);
      if (response.status === 204) {
        const updatedPrices = prices.filter((item) => item.id !== values.id);
        setPrices(updatedPrices);
        setMoedas((prevState) => [values.moeda, ...prevState]);
        onChange(updatedPrices);
        setLoading(false);
        setErrorExist('');
      }
    } catch (error: any) {
      if (error.response.status === 403) {
        setErrorExist('Existem objetos relacionados a esta classe');
      }
      const { detail } = error.response.data;
      setErrorExist(detail);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: any, index: any) => {
    const { value } = event.target;

    const updatedPrices = [...prices];
    updatedPrices[index].moeda = value;

    setPrices(updatedPrices);
  };

  const updatedPrices = async () => {
    try {
      const handleEditPrice = prices.map(async (item, index) => {
        if (index === 0) {
          return;
        }
        const response = await api.put(`/agc-cotacao/${item.id}`, {
          ...item,
          valor_cotacao: item.valor_cotacao.replace(',', '.'),
        });
        if (response.status === 200 || response.status === 201) {
          const { data } = response;
          statePrices(data);
          onClose();
        }
      });

      await Promise.all(handleEditPrice);
    } catch (error) {
      console.error(error);
    }
  };

  const postPrices = async () => {
    try {
      const data = {
        agc_id: id,
        moeda: moedas[0],
        valor_cotacao: 0,
      };
      const response = await api.post('/agc-cotacao/', data);
      if (response.status === 200 || response.status === 201) {
        setPrices((prevState) => [...prevState, response.data]);
        setMoedas((prevState) =>
          prevState.filter((item) => !teste.includes(item)),
        );
        estadoMoedas((prevState: any) => [...prevState, response.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        onClose();
        setErrorExist('');
      }}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent bg="#EEE">
        <DrawerCloseButton />

        <Stack p="68px 38px" spacing="36px">
          <Skeleton isLoaded={!loading} w="30%">
            <HStack spacing="10px" align="center">
              <Image src={Dollar} />
              <Text
                color="#222"
                fontFamily="Rubik"
                fontSize="22px"
                fontWeight="400"
              >
                Cotação
              </Text>
            </HStack>
          </Skeleton>

          {prices &&
            prices.length > 0 &&
            prices.map((item, index) => {
              const editValue = (value: string) => {
                const updatedValue = [...prices];
                updatedValue[index] = {
                  ...updatedValue[index],
                  valor_cotacao: value,
                };
                setPrices(updatedValue);
              };

              return (
                <HStack spacing="20px" key={item.id}>
                  <Skeleton w="100%" isLoaded={!loading} borderRadius="43px">
                    <Select
                      borderRadius="43px"
                      bg="#FFF"
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      h="54px"
                      _placeholder={{
                        color: '#AAA',
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        fontWeight: '400',
                        letterSpacing: '0.8px',
                      }}
                      color="#8A8A8A"
                      placeholder="Moeda"
                      _focus={{ border: 'none' }}
                      _focusVisible={{ border: 'none' }}
                      value={item.moeda}
                      onChange={(event) => handleChange(event, index)}
                    >
                      <option value="USD">USD</option>
                      <option value="EUR">EUR</option>
                      <option value="BRL">BRL</option>
                      <option value="JPY">JPY</option>
                      <option value="CNY">CNY</option>
                    </Select>
                  </Skeleton>

                  <Skeleton w="100%" borderRadius="43px" isLoaded={!loading}>
                    <Input
                      color="#AAA"
                      p="17px 30px"
                      placeholder="Valor"
                      value={item.valor_cotacao}
                      onChange={(e) => editValue(e.target.value)}
                    />
                  </Skeleton>

                  <Skeleton isLoaded={!loading}>
                    <button onClick={() => deletePrices(item)}>
                      <FaTrash color="#D89320" size={20} />
                    </button>
                  </Skeleton>
                </HStack>
              );
            })}
          {errorExist && (
            <Text color="red" fontSize="14px">
              {errorExist}
            </Text>
          )}

          <HStack spacing="20px">
            <Skeleton isLoaded={!loading} borderRadius="45px">
              <Button
                borderRadius="45px"
                border="2px solid #EBD87F"
                bg="rgba(255, 255, 255, 0.00)"
                color="#000"
                fontFamily="Rubik"
                fontSize="14px"
                fontWeight="400"
                onClick={() => {
                  onClose();
                  setErrorExist('');
                }}
                leftIcon={
                  <CloseIcon w="10px" h="10px" color="#000" opacity="0.25" />
                }
              >
                <Text>Cancelar</Text>
              </Button>
            </Skeleton>

            <Skeleton isLoaded={!loading} borderRadius="105px">
              <Button
                borderRadius="53px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                color="#FFF"
                fontSize="14px"
                fontWeight="500"
                leftIcon={<AddIcon w="12.5px" h="12.5px" />}
                onClick={postPrices}
              >
                Adicionar
              </Button>
            </Skeleton>

            <Skeleton isLoaded={!loading} borderRadius="105px">
              <Button
                borderRadius="105px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                fontFamily="Rubik"
                fontSize="14px"
                color="#FFF"
                p="14px 25px"
                w="170px"
                onClick={updatedPrices}
                leftIcon={<Image src={CheckIcon} />}
              >
                <Text>Salvar</Text>
              </Button>
            </Skeleton>
          </HStack>
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};
