import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Input,
  Select,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { FiCheckSquare } from 'react-icons/fi';
import * as yup from 'yup';
import { api } from '../../../../services/api';

export interface Data {
  id: number;
  aj_id: number;
  nome: string;
  numero_processo: string;
  natureza: string;
  data_credenciamento: string;
  hora_credenciamento: string;
  hora_instalacao: string;
  convocacao: string;
  observacao: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onSave: (agc: Data) => void;
}

const validationSchema = yup.object().shape({
  nome: yup.string().required('Este campo não pode estar em branco.'),
  numero_processo: yup
    .string()
    .required('Este campo não pode estar em branco.'),
  natureza: yup.string().required('Este campo não pode estar em branco.'),
  data_credenciamento: yup
    .string()
    .required('Este campo não pode estar em branco.'),
  hora_credenciamento: yup
    .string()
    .required('Este campo não pode estar em branco.'),
  hora_instalacao: yup
    .string()
    .required('Este campo não pode estar em branco.'),
  convocacao: yup.string().required('Este campo não pode estar em branco.'),
});

export const EditAgc: React.FC<Props> = ({ isOpen, onClose, id, onSave }) => {
  const [dataAgc, setDataAgc] = useState({
    id: 0,
    aj_id: 0,
    nome: '',
    numero_processo: '',
    natureza: '',
    data_credenciamento: '',
    hora_credenciamento: '',
    hora_instalacao: '',
    convocacao: '',
    observacao: '',
  });

  const [loading, setLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    if (isOpen) {
      getAgcs();
    } else {
      setDataAgc({ ...dataAgc });
      setLoading(false);
    }
  }, [id, isOpen]);

  const getAgcs = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/agc/${id}/`);
      if (response.status === 200) {
        const dataFormattData = {
          ...response.data,
          hora_credenciamento: formatTime(response.data.data_credenciamento),
          data_credenciamento: formatDate(response.data.data_credenciamento),
        };
        setDataAgc(dataFormattData);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditAgc = async () => {
    try {
      await validationSchema.validate(dataAgc, { abortEarly: false });

      const dataCredenciamento = `${dataAgc.data_credenciamento}T${dataAgc.hora_credenciamento}`;
      const data = { ...dataAgc, data_credenciamento: dataCredenciamento };

      const response = await api.put(`/agc/${id}`, data);
      if (response.status === 200) {
        onClose();
        onSave(dataAgc);
        setValidationErrors({});
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        console.error(error);
      }
    }
  };

  const handleChange = (e: any) => {
    const nameEdit = e.target.name;

    setDataAgc({
      ...dataAgc,
      [nameEdit]: e.target.value,
    });
  };

  const formatDate = (date: any) => {
    if (typeof date !== 'undefined') {
      const today = new Date(date);
      const month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      const day =
        today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();

      const dateNow = `${today.getFullYear()}-${month}-${day}`;

      return dateNow;
    }

    return '';
  };

  const formatTime = (dateTime: any) => {
    if (typeof dateTime !== 'undefined') {
      const today = new Date(dateTime);
      const hours =
        today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes =
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();

      const dateNow = `${hours}:${minutes}`;

      return dateNow;
    }

    return '';
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="xl">
      <DrawerOverlay />
      <DrawerContent bg="#EEE">
        <DrawerCloseButton />

        <Stack p="68px 38px" spacing="36px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="22px"
            fontWeight="400"
          >
            Editar AGC
          </Text>
          <Stack spacing="17px">
            <HStack spacing="37px">
              <Skeleton w="100%" borderRadius="45px" isLoaded={!loading}>
                <Input
                  w="100%"
                  name="nome"
                  placeholder="Nome da AGC"
                  onChange={handleChange}
                  value={dataAgc.nome}
                  color="#AAAAAA"
                />
                {validationErrors.nome ? (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="8px !important"
                    lineHeight="12px"
                    fontSize="14px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {validationErrors.nome}
                  </Text>
                ) : null}
              </Skeleton>

              <Skeleton w="100%" borderRadius="45px" isLoaded={!loading}>
                <Input
                  w="100%"
                  name="numero_processo"
                  placeholder="Nª Processo"
                  color="#AAAAAA"
                  value={dataAgc.numero_processo}
                  onChange={handleChange}
                />
                {validationErrors.numero_processo ? (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="8px !important"
                    lineHeight="12px"
                    fontSize="14px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {validationErrors.numero_processo}
                  </Text>
                ) : null}
              </Skeleton>
            </HStack>

            <Box>
              <Skeleton w="237px" borderRadius="45px" isLoaded={!loading}>
                <Select
                  placeholder="Natureza"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: '400',
                    letterSpacing: '0.8px',
                  }}
                  h="53px"
                  bg="#FFF"
                  borderRadius="43px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  color="#AAA"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  _focus={{ border: 'none' }}
                  _focusVisible={{ border: 'none' }}
                  iconColor="#8A8A8A"
                  iconSize="40px"
                  w="237px"
                  value={dataAgc.natureza}
                  onChange={handleChange}
                  name="natureza"
                >
                  <option value="recuperacao-judicial">
                    Recuperação Judicial
                  </option>
                  <option value="falencia">Falência</option>
                </Select>
                {validationErrors.natureza ? (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="8px !important"
                    lineHeight="12px"
                    fontSize="14px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {validationErrors.natureza}
                  </Text>
                ) : null}
              </Skeleton>
            </Box>

            <HStack spacing="37px">
              <Stack spacing="7px" w="100%">
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Data e hora de credenciamento
                </Text>

                <HStack spacing="18px">
                  <Skeleton borderRadius="45px" isLoaded={!loading} w="100%">
                    <Input
                      type="date"
                      h="54px"
                      color="#AAA"
                      placeholder="DD / MM / AAAA"
                      name="data_credenciamento"
                      w="100%"
                      value={dataAgc.data_credenciamento}
                      onChange={handleChange}
                    />
                    {validationErrors.data_credenciamento ? (
                      <Text
                        textAlign="start"
                        marginLeft="15px"
                        marginTop="8px !important"
                        lineHeight="12px"
                        fontSize="14px"
                        color="red.400"
                        textStyle="body.small"
                      >
                        {validationErrors.data_credenciamento}
                      </Text>
                    ) : null}
                  </Skeleton>

                  <Skeleton borderRadius="45px" isLoaded={!loading} w="100%">
                    <Input
                      type="time"
                      h="54px"
                      color="#AAA"
                      name="hora_credenciamento"
                      w="100%"
                      value={dataAgc.hora_credenciamento}
                      onChange={handleChange}
                    />
                    {validationErrors.hora_credenciamento ? (
                      <Text
                        textAlign="start"
                        marginLeft="15px"
                        marginTop="8px !important"
                        lineHeight="12px"
                        fontSize="14px"
                        color="red.400"
                        textStyle="body.small"
                      >
                        {validationErrors.hora_credenciamento}
                      </Text>
                    ) : null}
                  </Skeleton>
                </HStack>
              </Stack>

              <Stack spacing="7px" w="54%">
                <Text color="#06152B" fontFamily="Rubik" fontWeight="400">
                  Hora instalação
                </Text>
                <Skeleton borderRadius="45px" isLoaded={!loading} w="100%">
                  <Input
                    type="time"
                    h="54px"
                    color="#AAA"
                    name="hora_instalacao"
                    w="100%"
                    value={dataAgc.hora_instalacao}
                    onChange={handleChange}
                  />
                  {validationErrors.hora_instalacao ? (
                    <Text
                      textAlign="start"
                      marginLeft="15px"
                      marginTop="8px !important"
                      lineHeight="12px"
                      fontSize="14px"
                      color="red.400"
                      textStyle="body.small"
                    >
                      {validationErrors.hora_instalacao}
                    </Text>
                  ) : null}
                </Skeleton>
              </Stack>
            </HStack>

            <Box>
              <Skeleton w="30%" borderRadius="45px" isLoaded={!loading}>
                <Select
                  placeholder="Convocação"
                  _placeholder={{
                    color: '#AAA',
                    fontFamily: 'Rubik',
                    fontSize: '16px',
                    fontWeight: '400',
                    letterSpacing: '0.8px',
                  }}
                  h="53px"
                  bg="#FFF"
                  borderRadius="43px"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  color="#AAA"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                  _focus={{ border: 'none' }}
                  _focusVisible={{ border: 'none' }}
                  iconColor="#8A8A8A"
                  iconSize="40px"
                  w="100%"
                  value={dataAgc.convocacao}
                  onChange={handleChange}
                  name="convocacao"
                >
                  <option value="1a-convocacao">1° Convocação</option>
                  <option value="2a-convocacao">2° Convocação</option>
                </Select>
                {validationErrors.convocacao ? (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="8px !important"
                    lineHeight="12px"
                    fontSize="14px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {validationErrors.convocacao}
                  </Text>
                ) : null}
              </Skeleton>
            </Box>

            <Skeleton w="50%" borderRadius="45px" isLoaded={!loading}>
              <Input
                placeholder="Observações"
                _placeholder={{
                  color: '#AAA',
                  fontFamily: 'Rubik',
                  fontSize: '16px',
                  fontWeight: '400',
                  letterSpacing: '0.8px',
                }}
                h="53px"
                w="100%"
                name="observacao"
                value={dataAgc.observacao}
                onChange={handleChange}
              />
            </Skeleton>
          </Stack>

          <Flex justify="space-between">
            <Button
              bg="none"
              boxShadow="none"
              border="2px solid #DF8E25"
              color="#000"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              leftIcon={<CloseIcon w="9px" h="9px" opacity={0.5} />}
              onClick={onClose}
            >
              Cancelar
            </Button>

            <Button
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              color="#FFF"
              fontFamily="Rubik"
              fontSize="14px"
              fontWeight="400"
              leftIcon={<FiCheckSquare size="20px" />}
              w="170px"
              onClick={handleEditAgc}
            >
              Salvar
            </Button>
          </Flex>
        </Stack>
      </DrawerContent>
    </Drawer>
  );
};
