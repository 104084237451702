import { useState } from 'react';
import { api } from '../../../services/api';

export const useClasses = () => {
  const [loading, setLoading] = useState(false);
  const [classes, stClasses] = useState<IClasse[]>([]);

  const getClasse = async (callId: string) => {
    try {
      setLoading(true);
      const res = await api.get<IClasses>('/agc-classe', {
        params: { agc_id: callId },
      });
      stClasses(res.data?.results ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return { getClasse, classes, loading };
};

export interface IClasse {
  id: number;
  agc_id: number;
  classe: string;
}

export interface IClasses {
  count: number;
  next: string;
  previous: string;
  results: IClasse[];
}
