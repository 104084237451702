/* eslint-disable no-plusplus */
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';
import { api } from '../../../../../../services/api';

interface PropsAgcs {
  id: number;
  nome: string;
  convocacao: string;
  data_credenciamento: string;
  hora_instalacao: string;
  encerrada: boolean;
  suspensa: boolean;
}

interface Props {
  index: number;
}

export const MemberTeamAgcs: React.FC<Props> = ({ index }) => {
  const [agcs, setAgcs] = useState<PropsAgcs[]>([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  const [loading, setLoading] = useState(false);

  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (index === 1) {
      getAgcs();
    }
  }, [currentPage, index]);

  useEffect(() => {
    if (filterText.length >= 5 || filterText.length === 0) {
      getAgcs();
    }
  }, [filterText]);

  const itemsPerPage = 10;

  const handleFilterChange = (e: any) => {
    const filter = e.target.value;
    setFilterText(filter);
  };

  const getAgcs = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `/agc/?limit=${itemsPerPage}&offset=${
          (currentPage - 1) * itemsPerPage
        }&search=${filterText}&tipo_pesquisa=aj-equipe`,
      );
      if (response.status === 200) {
        const { results, count } = response.data;
        setAgcs(results);
        setTotalRecords(count);
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };

  const renderPaginationButtons = () => {
    const maxButtonsToShow = 3;
    const totalPages = Math.ceil(totalRecords / itemsPerPage);

    const paginationButtons = [];
    let startPage = Math.max(currentPage - Math.floor(maxButtonsToShow / 2), 1);
    const endPage = Math.min(startPage + maxButtonsToShow - 1, totalPages);

    if (endPage - startPage < maxButtonsToShow - 1) {
      startPage = Math.max(endPage - maxButtonsToShow + 1, 1);
    }

    if (startPage !== 1) {
      paginationButtons.push(
        <Button
          key={1}
          onClick={() => handlePageChange(1)}
          fontWeight={currentPage === 1 ? 'bold' : 'normal'}
          color={currentPage === 1 ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === 1
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          1
        </Button>,
      );
    }

    if (startPage > 2) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <Button
          key={i}
          onClick={() => handlePageChange(i)}
          fontWeight={currentPage === i ? 'bold' : 'normal'}
          color={currentPage === i ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === i
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {i}
        </Button>,
      );
    }

    if (endPage < totalPages - 1) {
      paginationButtons.push(<FaEllipsisH color="#DF8E25" />);
    }

    if (endPage !== totalPages) {
      paginationButtons.push(
        <Button
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          fontWeight={currentPage === totalPages ? 'bold' : 'normal'}
          color={currentPage === totalPages ? 'white' : '#DF8E25'}
          disabled={loading}
          bg={
            currentPage === totalPages
              ? 'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)'
              : 'none'
          }
          borderRadius="100px"
          p="20px"
          boxShadow="none"
        >
          {totalPages}
        </Button>,
      );
    }

    return paginationButtons;
  };

  const headTable = [
    { title: 'Nome' },
    { title: 'Convocação' },
    { title: 'Credenciamento' },
    { title: 'Instalação' },
    { title: 'Encerrada' },
    { title: 'Suspensa' },
    { title: 'Ações' },
  ];

  return (
    <Box>
      <InputGroup>
        <InputLeftElement mt="4px" pl="36px">
          <SearchIcon color="#AAA" w="20px" h="20px" />
        </InputLeftElement>
        <Input
          boxShadow="0px 10px 20px 0px rgba(0, 0, 0, 0.05)"
          placeholder="Pesquisa por nome"
          _placeholder={{
            color: '#888',
            fontFamily: 'Rubik',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '28px',
            letterSpacing: '1.6px',
          }}
          pl="79px"
          h="56px"
          mr="32px"
          onClick={handleFilterChange}
        />
      </InputGroup>

      {loading && (
        <Flex justify="center" mt="10%">
          <Spinner size="xl" color="#DF8E25" />
        </Flex>
      )}

      {agcs && agcs.length > 0 && (
        <TableContainer mt="36px">
          <Table variant="simple">
            <Thead borderBottom="2px solid #9B9B9B">
              <Tr>
                {headTable.map((item) => (
                  <Th
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="700"
                  >
                    {item.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {agcs.map((item) => {
                const formattedData = () => {
                  if (typeof item.data_credenciamento !== 'undefined') {
                    const today = new Date(item.data_credenciamento);
                    const month =
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1;
                    const day =
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate();
                    const hours =
                      today.getHours() < 10
                        ? `0${today.getHours()}`
                        : today.getHours();
                    const minutes =
                      today.getMinutes() < 10
                        ? `0${today.getMinutes()}`
                        : today.getMinutes();

                    const dateNow = `${day}/${month}/${today.getFullYear()} às ${hours}:${minutes}`;

                    return dateNow;
                  }
                  return '';
                };

                const formatTime = () => {
                  if (typeof item.hora_instalacao !== 'undefined') {
                    const timeNow = item.hora_instalacao.split(':');

                    return `${timeNow[0]}:${timeNow[1]}`;
                  }

                  return '';
                };

                return (
                  <Tr
                    color="#000"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    <Td>{item.nome}</Td>
                    <Td>
                      {item.convocacao === '1a-convocacao'
                        ? '1° Convocação'
                        : '2° Convocação'}
                    </Td>
                    <Td>{formattedData()}</Td>
                    <Td>{formatTime()}</Td>
                    <Td>{item.encerrada ? 'Sim' : 'Não'}</Td>
                    <Td>{item.suspensa ? 'Sim' : 'Não'}</Td>
                    <Td>
                      <Button
                        bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                        color="#FFF"
                        fontFamily="Rubik"
                        fontSize="16px"
                        fontWeight="400"
                        h="25px"
                        onClick={() => {
                          window.location.href = `/agcs/${item.id}`;
                        }}
                      >
                        Ver
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Flex>{renderPaginationButtons()}</Flex>
        </TableContainer>
      )}

      {!loading && agcs.length < 1 && (
        <Stack align="center" spacing="15px" mt="36px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="30px"
            fontWeight="400"
            w="40%"
            textAlign="center"
          >
            Você não é credenciado em nenhuma AGC do tipo Membro de Equipe
          </Text>
        </Stack>
      )}
    </Box>
  );
};
