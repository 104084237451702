import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  type: 'dados' | 'endereco' | 'senha';
}

export const ModalEdits: React.FC<Props> = ({ isOpen, onClose, type }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {type === 'dados' && 'Dados editados com sucesso!'}
          {type === 'endereco' && 'Endereço editado com sucesso!'}
          {type === 'senha' && 'Senha editada com sucesso!'}
        </ModalHeader>

        <ModalFooter>
          <Button
            w="170px"
            h="51px"
            borderRadius="105px"
            background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            onClick={onClose}
          >
            <Text
              color="#FFF"
              fontFamily="Rubik"
              fontSize="14px"
              fontWeight="400"
            >
              Fechar
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
