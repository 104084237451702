import { Button, HStack, Image, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import CheckIcon from '../../../../../assets/icons/checkIcon.png';

interface Props {
  onClose: () => void;
  prevStep: () => void;
}
export const StepTwo: React.FC<Props> = () => {
  return (
    <Stack spacing="36px">
      <Text
        color="#222"
        fontFamily="Roboto"
        fontSize="30px"
        fontWeight="400"
        letterSpacing="-0.3px"
        w="257px"
      >
        Solicitação de Vínculo em Análise
      </Text>

      <Text
        color="#222"
        fontFamily="Rubik"
        fontSize="18px"
        fontWeight="400"
        lineHeight="161.5%"
      >
        A solicitação de vínculo com este credor está em análise pelo
        administrador judicial. Se aprovada, o status exibido na tela será
        <span style={{ fontWeight: '700' }}> VINCULADO</span>. Se rejeitada, uma
        notificação aparecerá na tela de pendências desta Recuperação Judicial.
      </Text>

      <HStack w="100%" justify="center">
        <Button
          color="#FFF"
          fontFamily="Rubik"
          fontSize="14px"
          fontWeight="400"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
          w="170px"
          onClick={() => {
            window.location.reload();
          }}
        >
          <HStack spacing="10px" alignItems="center">
            <Image src={CheckIcon} /> <Text>Ok</Text>
          </HStack>
        </Button>
      </HStack>
    </Stack>
  );
};
