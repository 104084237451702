import React, { useEffect } from 'react';
import {
  Box,
  Center,
  Flex,
  Input,
  Spinner,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Table,
} from '@chakra-ui/react';
import { delay } from 'lodash';

import { useCredores } from '../../../../hooks/useCredores';
import { useStream } from '../../../../context/stream.context';
import { useDialog } from '../../../../context/useDialog';
import CardCredor from '../../card-credor';

interface Props {
  isOpen: boolean;
}

const CredoresCredenciados: React.FC<Props> = ({ isOpen }) => {
  const { callId, currentUserBackend, isAdmin } = useStream();
  const showDialog = useDialog();

  const {
    filters,
    getCredores,
    credores,
    updateCredor,
    loading,
    deleteCredor,
    setFilters,
    credenciamento,
    getCredenciamento,
  } = useCredores(Number(callId));

  useEffect(() => {
    if (isOpen) {
      getCredores();
      getCredenciamento();
    }
  }, [isOpen, filters]);

  useEffect(() => {
    if (!isAdmin) {
      setFilters({
        ...filters,
        usuario_id: currentUserBackend?.id,
        limit: 10000,
      });
    } else {
      setFilters({
        ...filters,
        limit: 10000,
      });
    }
  }, [currentUserBackend, isAdmin]);

  const deleteCredorDialog = (id: number) => {
    showDialog({
      title: 'Excluir credor',
      message: 'Deseja realmente excluir este credor?',
      buttonCancel: 'Cancelar',
      buttonOK: 'Excluir',
      onClick: () => {
        deleteCredor(id);
      },
    });
  };

  const credenciados = credores.filter((credor) =>
    credenciamento.find((c) => c.credor === credor.nome),
  );

  return (
    <Flex flex={1} flexDir="column">
      <Box pb="20px">
        <Input
          size="sm"
          w="full"
          bg="white"
          color="gray.600"
          placeholder="Pesquisar credor"
          onChange={async (e) => {
            delay(
              () =>
                setFilters({
                  ...filters,
                  search: e.target.value,
                }),
              500,
            );
          }}
        />
      </Box>

      {loading && (
        <Center w="full" py="30px">
          <Spinner />
        </Center>
      )}

      {!loading && (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>nome</Th>
                <Th>CNPJ / CPF</Th>
                <Th>classe</Th>
                <Th isNumeric>moeda</Th>
                <Th isNumeric>credito</Th>
                <Th isNumeric>ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {credenciados?.map((credor) => (
                <CardCredor
                  updateCredor={updateCredor}
                  credor={credor}
                  key={String(credor.id)}
                  deleteCredor={deleteCredorDialog}
                />
              ))}

              {credenciados.length === 0 && (
                <Tr>
                  <Th opacity={0.5} pt="30px" colSpan={6} textAlign="center">
                    Nenhum credor encontrado
                  </Th>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Flex>
  );
};

export default CredoresCredenciados;
