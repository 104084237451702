import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Stack,
  Text,
  Image,
} from '@chakra-ui/react';
import React from 'react';
import QrCode from '../../../../assets/images/qrCode.png';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const DrawerPagament: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody p="34px 34px">
          <Stack h="100%">
            <Text color="#222" fontSize="22px" fontWeight="500">
              Pagamento
            </Text>

            <Stack h="60%" align="center" justify="center">
              <Image src={QrCode} />
              <Text fontSize="22px" textAlign="center">
                Explicação de todo o pagamento
              </Text>
            </Stack>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
