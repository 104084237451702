export const colors = {
  primary: '#DCAC36',
  background: '',
  secondary: '',

  text: {
    primary: '',
    secondary: '',
  },
};
