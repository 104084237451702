import {
  Box,
  HStack,
  Icon,
  IconButton,
  Select,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import React, { memo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MdAdd, MdRemove } from 'react-icons/md';
import InputComponent from '../../../../forms/input';

const RespostasCard: React.FC = memo(() => {
  const [, setAddMore] = useState(false);
  const formMethods = useFormContext();

  const respostas = formMethods.watch('respostas') as any[];

  const addRespostas = () => {
    setAddMore((a) => !a);
    formMethods.setValue('respostas', [...respostas, { resposta: '' }]);
  };

  const removeReposta = (respostaId: number) => {
    setAddMore((a) => !a);
    formMethods.setValue(
      'respostas',
      respostas.filter((_, index) => index !== respostaId),
    );
  };

  return (
    <div>
      <Text fontWeight="bold" fontSize="14px" pb="10px" pt="30px">
        Respostas
      </Text>
      {respostas.map((resposta, index) => {
        const isLast = index === respostas.length - 1;

        return (
          <HStack pb="10px" key={`${resposta.resposta}-${String(index)}`}>
            <InputComponent
              placeholder="Texto da Resposta"
              name={`respostas.${index}.resposta`}
            />

            <Stack spacing="2px">
              <Text fontSize="8px" fontWeight="bold">
                Abstenção
              </Text>
              <Switch
                size="lg"
                isChecked={resposta.abstencao}
                onChange={(e) => {
                  formMethods.setValue(
                    `respostas.${index}.abstencao`,
                    e.target.checked,
                  );
                }}
              />
            </Stack>

            <Select
              value={resposta.cor_resposta}
              defaultValue={resposta.cor_resposta}
              onChange={(e) => {
                formMethods.setValue(
                  `respostas.${index}.cor_resposta`,
                  e.target.value,
                );
              }}
              w="120px"
            >
              {[
                'verde',
                'vermelho',
                'azul',
                'amarelo',
                'roxo',
                'preto',
                'laranja',
                'cinza',
                'branco',
              ].map((option) => (
                <Box as="option" textTransform="capitalize" value={option}>
                  {option}
                </Box>
              ))}
            </Select>

            {!isLast && respostas.length > 1 && (
              <IconButton
                icon={<Icon as={MdRemove} />}
                onClick={() => {
                  removeReposta(index);
                }}
                aria-label=""
              />
            )}

            {isLast && (
              <IconButton
                icon={<Icon as={MdAdd} />}
                onClick={() => {
                  addRespostas();
                }}
                aria-label=""
              />
            )}
          </HStack>
        );
      })}
    </div>
  );
});

export default RespostasCard;
