import { Participant } from '@zoom/videosdk';
import React, { createContext, useEffect, useState } from 'react';
import { useStream } from './stream.context';
import { useParticipantsChange } from '../../VideoSDKView/feature/video/hooks/useParticipantsChange';

interface IVideoActionsContext {
  toggleAudio: (_userId?: number, _ligar?: boolean) => void;
  isMuted: boolean;
  usersWithAudio: number[];
  requestedMicrophone: boolean;
  setRequestedMicrophone: React.Dispatch<React.SetStateAction<boolean>>;
  requestedMicrophones: Participant[];
  setRequestMicrophones: React.Dispatch<React.SetStateAction<Participant[]>>;
  removeRequestedMicrophone: (_participant: Participant) => void;
}

const VideoActionsContext = createContext<IVideoActionsContext>(
  {} as IVideoActionsContext,
);

interface Props {
  children: React.ReactNode;
}

export const VideoActionProvider: React.FC<Props> = ({ children }) => {
  const { client, stream, getUsers, currentUser } = useStream();
  const [usersWithAudio, setUsersWithAudio] = useState<number[]>([]);
  const [isMuted, setIsMuted] = useState(true);
  const [requestedMicrophone, setRequestedMicrophone] = useState(false);
  const [requestedMicrophones, setRequestMicrophones] = useState<Participant[]>(
    [],
  );

  useParticipantsChange(client!, (users) => {
    for (const user of users) {
      if (user.muted) {
        setUsersWithAudio((old) => old.filter((id) => id !== user.userId));
        if (user.userId === currentUser?.userId) {
          setIsMuted(true);
        }
      } else {
        setUsersWithAudio((old) => [...old, user.userId]);
        if (user.userId === currentUser?.userId) {
          setIsMuted(false);
        }
      }
    }
  });

  useEffect(() => {
    client?.on('current-audio-change', async (payload) => {
      getUsers();
      if (payload.action === 'unmuted') {
        setIsMuted(false);
      } else {
        setIsMuted(true);
      }
    });

    client?.on('host-ask-unmute-audio', async () => {
      getUsers();
      await toggleAudio(undefined, true);
      setRequestedMicrophone(false);
    });
  }, [client, stream]);

  const toggleAudio = async (userId?: number, ligar?: boolean) => {
    try {
      if (ligar) {
        await stream?.unmuteAudio(userId);
        setIsMuted(false);
        if (userId) {
          setUsersWithAudio((old) => {
            const filtered = old.filter((id) => id !== userId);
            return [...filtered, userId];
          });
        }
        return;
      }

      if (userId) {
        if (!usersWithAudio.includes(userId)) {
          await stream?.unmuteAudio(userId);
          setUsersWithAudio((old) => [...old, userId]);
        } else {
          await stream?.muteAudio(userId);
          setUsersWithAudio((old) => old.filter((id) => id !== userId));
        }
      } else if (isMuted) {
        await stream?.unmuteAudio(userId);
        setIsMuted(false);
      } else {
        await stream?.muteAudio(userId);
        setIsMuted(true);
      }
    } catch (error) {
      console.log('audio ', error);
    }
  };

  const removeRequestedMicrophone = (participant: Participant) => {
    const updatedRequestedMicrophones = requestedMicrophones.filter(
      (p) => p.userId !== participant.userId,
    );
    setRequestMicrophones(updatedRequestedMicrophones);
  };

  return (
    <VideoActionsContext.Provider
      value={{
        toggleAudio,
        isMuted,
        usersWithAudio,
        requestedMicrophone,
        setRequestedMicrophone,
        requestedMicrophones,
        setRequestMicrophones,
        removeRequestedMicrophone,
      }}
    >
      {children}
    </VideoActionsContext.Provider>
  );
};

export const useVideoActions = () => {
  const context = React.useContext(VideoActionsContext);

  if (context === undefined) {
    throw new Error(
      'useVideoActions must be used within a VideoActionsProvider',
    );
  }

  return context;
};
