import {
  Button,
  Center,
  Flex,
  HStack,
  Spinner,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import CriarPerguntaModal from './criar-pergunta';
import CardPergunta from './CardPergunta';
import { usePerguntas } from '../../hooks/usePerguntas';
import { useStream } from '../../context/stream.context';

interface Props {
  isAdmin: boolean;
}

const PainelVotacao: React.FC<Props> = ({ isAdmin }) => {
  const { callId } = useStream();
  const { getPerguntas, perguntas, loading } = usePerguntas(Number(callId));
  const [isOpen, { off: fecharModal, on: obrirModal }] = useBoolean();

  useEffect(() => {
    getPerguntas();
  }, []);

  return (
    <Flex
      flex="0.6"
      flexDirection="column"
      bg="white"
      borderRight="1px solid #ddd"
      h="full"
      shadow="0px 15px 30px 0px rgba(0, 0, 0, 0.1)"
    >
      <Flex p="30px" flexDir="column" flex="1 1 0" overflowX="auto">
        <HStack pb="10px">
          <Text flex={1} fontSize="20px">
            Perguntas
          </Text>

          {isAdmin && (
            <HStack justify="flex-end">
              <Button
                variant="outline"
                borderColor="primary"
                h="40px"
                fontSize="14px"
                onClick={obrirModal}
              >
                Nova pergunta
              </Button>
            </HStack>
          )}
        </HStack>

        <Flex flexDir="column">
          {!loading && perguntas?.length === 0 && (
            <Center p="30px">
              <Text opacity={0.4}>Nenhuma pergunta cadastrada</Text>
            </Center>
          )}

          {loading && (
            <Center p="30px">
              <Spinner />
            </Center>
          )}

          {perguntas?.map((pergunta) => (
            <CardPergunta
              isAdmin={isAdmin}
              key={String(pergunta.id)}
              pergunta={pergunta}
            />
          ))}
        </Flex>
      </Flex>

      <CriarPerguntaModal
        isOpen={isOpen}
        onClose={() => {
          getPerguntas();
          fecharModal();
        }}
      />
    </Flex>
  );
};

export default PainelVotacao;
