import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { getAuthStorage } from '../../../../services/storage';
import { api } from '../../../../services/api';
import CheckIcon from '../../../../assets/icons/checkIcon.png';
import { ModalEdits } from '../../../../components/modals/modalEdits';

const schema = yup.object().shape({
  nome: yup.string().required('Este campo não pode estar em branco.'),
  sobrenome: yup.string().required('Este campo não pode estar em branco.'),
  email: yup.string().required('Este campo não pode estar em branco.'),
});

export const MyData: React.FC = () => {
  const [dataUser, setDataUser] = useState({
    id: 0,
    nome: '',
    sobrenome: '',
    email: '',
    celular: '',
    cpf: '',
  });

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    const getDataUser = async () => {
      const user = await getAuthStorage();
      try {
        const response = await api.get(`/usuario/registro/${user.user_id}/`);
        const { data } = response;
        if (response.status === 200 || response.status === 201) {
          setDataUser(data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };

    getDataUser();
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    setDataUser({
      ...dataUser,
      [name]: value,
    });
  };

  const updateDataUser = async () => {
    try {
      await schema.validate(dataUser, { abortEarly: false });

      const data = {
        nome: dataUser.nome,
        sobrenome: dataUser.sobrenome,
        cpf: dataUser.cpf,
        email: dataUser.email,
        celular: dataUser.celular,
      };
      const response = await api.put(`/usuario/registro/${dataUser.id}/`, data);
      if (response.status === 200 || response.status === 201) {
        onOpen();
        setValidationErrors({});
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <Stack mt="30px" spacing="35px">
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Nome
          </Text>
          <Box>
            <Input
              fill="#FFF"
              filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
              w="360px"
              h="56px"
              value={dataUser.nome}
              onChange={handleChange}
              name="nome"
            />
            {validationErrors.nome ? (
              <Text
                textAlign="start"
                marginLeft="15px"
                marginTop="8px !important"
                lineHeight="12px"
                fontSize="14px"
                color="red.400"
                textStyle="body.small"
              >
                {validationErrors.nome}
              </Text>
            ) : null}
          </Box>
        </Flex>
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Sobrenome
          </Text>
          <Box>
            <Input
              fill="#FFF"
              filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
              w="360px"
              h="56px"
              value={dataUser.sobrenome}
              onChange={handleChange}
              name="sobrenome"
            />
            {validationErrors.nome ? (
              <Text
                textAlign="start"
                marginLeft="15px"
                marginTop="8px !important"
                lineHeight="12px"
                fontSize="14px"
                color="red.400"
                textStyle="body.small"
              >
                {validationErrors.nome}
              </Text>
            ) : null}
          </Box>
        </Flex>

        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            CPF
          </Text>
          <Box>
            <Input
              fill="#FFF"
              filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
              w="360px"
              h="56px"
              value={dataUser.cpf}
              onChange={handleChange}
              name="cpf"
              readOnly
            />
          </Box>
        </Flex>

        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            E-mail
          </Text>
          <Box>
            {' '}
            <Input
              fill="#FFF"
              filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
              w="360px"
              h="56px"
              value={dataUser.email}
              onChange={handleChange}
              name="email"
            />
            {validationErrors.email ? (
              <Text
                textAlign="start"
                marginLeft="15px"
                marginTop="8px !important"
                lineHeight="12px"
                fontSize="14px"
                color="red.400"
                textStyle="body.small"
              >
                {validationErrors.email}
              </Text>
            ) : null}
          </Box>
        </Flex>
        <Flex align="center" justify="space-between" p="0px 51px 0px 65px">
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="20px"
            fontWeight="400"
          >
            Celular
          </Text>
          <Input
            fill="#FFF"
            filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
            w="360px"
            h="56px"
            value={dataUser.celular}
            onChange={handleChange}
            name="celular"
          />
        </Flex>
      </Stack>
      <Button
        w="170px"
        h="51px"
        borderRadius="105px"
        background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
        m="35px 51px 48px 0px"
        leftIcon={<Image src={CheckIcon} />}
        onClick={updateDataUser}
        boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
      >
        <Text color="#FFF" fontFamily="Rubik" fontSize="14px" fontWeight="400">
          Salvar
        </Text>
      </Button>

      <ModalEdits isOpen={isOpen} onClose={onClose} type="dados" />
    </Box>
  );
};
