import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '../../../../assets/icons/checkIcon.png';
import { getAuthStorage } from '../../../../services/storage';
import { api } from '../../../../services/api';
import InputComponent from '../../../../components/forms/input';
import { ModalEdits } from '../../../../components/modals/modalEdits';

const schema = yup.object().shape({
  password: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,14}$/,
      'A senha deve conter pelo menos 8 à 14 caracteres, incluindo letras maiúsculas e minúsculas, números caracteres especiais',
    ),
  password2: yup
    .string()
    .required('Campo obrigatório.')
    .oneOf([yup.ref('password')], 'As senhas devem ser iguais.'),
});
export const Security: React.FC = () => {
  const [id, setId] = useState(0);

  useEffect(() => {
    const getId = async () => {
      const user = await getAuthStorage();
      setId(user.user_id);
    };

    getId();
  }, []);

  const updatePassword = async (data: any) => {
    try {
      const response = await api.put(`/usuario/alterar-password/${id}`, data);
      if (response.status === 200 || response.status === 201) {
        onOpen();
        formMethods.reset();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      password2: '',
    },
  });

  const handleSubmit = async (formdata: any) => {
    updatePassword(formdata);
  };

  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Stack spacing="35px" p="51px 51px 110px 65px">
            <Flex align="center" justify="space-between">
              <Text
                color="#222"
                fontFamily="Rubik"
                fontSize="20px"
                fontWeight="400"
              >
                Senha
              </Text>
              <InputComponent
                name="password"
                fill="#FFF"
                filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
                w="360px"
                h="56px"
                placeholder="Senha"
                type="password"
              />
            </Flex>

            <Flex align="center" justify="space-between">
              <Text
                color="#222"
                fontFamily="Rubik"
                fontSize="20px"
                fontWeight="400"
              >
                Confirmar senha
              </Text>
              <InputComponent
                name="password2"
                fill="#FFF"
                filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
                w="360px"
                h="56px"
                placeholder="Confirmar senha"
                type="password"
              />
            </Flex>

            <Box>
              <Button
                w="170px"
                h="51px"
                borderRadius="105px"
                background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
                leftIcon={<Image src={CheckIcon} />}
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                type="submit"
              >
                <Text
                  color="#FFF"
                  fontFamily="Rubik"
                  fontSize="14px"
                  fontWeight="400"
                >
                  Salvar
                </Text>
              </Button>
            </Box>
          </Stack>
        </form>
        <ModalEdits isOpen={isOpen} onClose={onClose} type="senha" />
      </FormProvider>
    </Box>
  );
};
