/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import {
  useEffect,
  useContext,
  useState,
  useCallback,
  useReducer,
  useMemo,
} from 'react';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import { message, Modal } from 'antd';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import { useNavigate } from 'react-router-dom';
import Video from './feature/video/video';
import ZoomMediaContext from './context/media-context';

import { MediaStream } from './index-types';
import './App.css';
import zoomContext from '../meet-sdk/context/zoom.context';
import { useStream } from '../meet-sdk/context/stream.context';

interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
    customerJoinId?: string;
    lang?: string;
  };
}
const mediaShape = {
  audio: {
    encode: false,
    decode: false,
  },
  video: {
    encode: false,
    decode: false,
  },
  share: {
    encode: false,
    decode: false,
  },
};
const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
  }
}

function VideoSDKZoom(props: AppProps) {
  const {
    meetingArgs: {
      sdkKey,
      topic,
      signature,
      name,
      password,
      webEndpoint: webEndpointArg,
      enforceGalleryView,
      customerJoinId,
    },
  } = props;
  const [loading, setIsLoading] = useState(true);
  const [, setLoadingText] = useState('');
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [, setStatus] = useState<string>('closed');
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const { setStream, setCurrentUser, setLive, setChatClient, setCloudRecord } =
    useStream();

  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const [isSupportGalleryView, setIsSupportGalleryView] =
    useState<boolean>(true);

  const push = useNavigate();
  const zmClient = useContext(zoomContext);

  let webEndpoint: any;

  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }

  const mediaContext = useMemo(
    () => ({ ...mediaState, mediaStream }),
    [mediaState, mediaStream],
  );

  const galleryViewWithoutSAB =
    Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;

  useEffect(() => {
    if (topic && name && signature) {
      const init = async () => {
        await zmClient.init('en-US', `${window.location.origin}/lib`, {
          webEndpoint,
          enforceMultipleVideos: isSupportGalleryView,
          enforceVirtualBackground: galleryViewWithoutSAB,
          stayAwake: true,
        });
        try {
          setLoadingText('Entrando na assembleia...');
          await zmClient.join(topic, signature, name, password).catch((e) => {
            //
          });
          const newStream = zmClient.getMediaStream();

          setIsSupportGalleryView(newStream.isSupportMultipleVideos());
          setMediaStream(newStream);
          setStream(newStream);
          setCurrentUser(zmClient.getCurrentUserInfo());
          setLive(zmClient.getLiveStreamClient());
          setChatClient(zmClient.getChatClient());
          setCloudRecord(zmClient.getRecordingClient());
          setIsLoading(false);
        } catch (e: any) {
          setIsLoading(false);
          message.error(e.reason);
        }
      };

      init();
    }

    return () => {
      ZoomVideo.destroyClient();
    };
  }, [
    sdkKey,
    signature,
    zmClient,
    topic,
    name,
    password,
    webEndpoint,
    galleryViewWithoutSAB,
    customerJoinId,
  ]);

  const onConnectionChange = useCallback(
    (payload: { state?: any; reason: any; subsessionName?: any }) => {
      if (payload.state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus('connecting');
        const { reason, subsessionName } = payload;
        if (reason === ReconnectReason.Failover) {
          setLoadingText('Session Disconnected,Try to reconnect');
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
          setLoadingText(`Joining ${subsessionName}...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText('Returning to Main Session...');
        }
      } else if (payload.state === ConnectionState.Connected) {
        setStatus('connected');
        if (isFailover) {
          setIsLoading(false);
        }
        window.zmClient = zmClient;
        window.mediaStream = zmClient.getMediaStream();

        console.log('getSessionInfo', zmClient.getSessionInfo());
      } else if (payload.state === ConnectionState.Closed) {
        setStatus('closed');
        dispatch({ type: 'reset-media' });
        if (payload.reason === 'ended by host') {
          push('/agcs');
        }
      }
    },
    [isFailover, zmClient],
  );

  const onMediaSDKChange = useCallback(
    (payload: { action: any; type: any; result: any }) => {
      const { action, type, result } = payload;
      dispatch({ type: `${type}-${action}`, payload: result === 'success' });
    },
    [],
  );

  const onDialoutChange = useCallback((payload: any) => {
    console.log('onDialoutChange', payload);
  }, []);

  const onAudioMerged = useCallback((payload: any) => {
    console.log('onAudioMerged', payload);
  }, []);

  useEffect(() => {
    zmClient?.on('connection-change', onConnectionChange);
    zmClient?.on('media-sdk-change', onMediaSDKChange);
    zmClient?.on('dialout-state-change', onDialoutChange);
    zmClient?.on('merged-audio', onAudioMerged);

    return () => {
      zmClient.off('connection-change', onConnectionChange);
      zmClient.off('media-sdk-change', onMediaSDKChange);
      zmClient.off('dialout-state-change', onDialoutChange);
      zmClient.off('merged-audio', onAudioMerged);
    };
  }, [
    zmClient,
    onConnectionChange,
    onMediaSDKChange,
    onDialoutChange,
    onAudioMerged,
  ]);

  return (
    <div className="App" style={{ backgroundColor: 'black' }}>
      {!loading && (
        <ZoomMediaContext.Provider value={mediaContext}>
          <Video />
        </ZoomMediaContext.Provider>
      )}
    </div>
  );
}

export default VideoSDKZoom;
