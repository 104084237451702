import React, { useEffect } from 'react';
import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useCredores } from '../../hooks/useCredores';
import { useStream } from '../../context/stream.context';
import { formatClasse } from '../../utils/formatClasse';
import { toCurrency } from '../../utils/toCurrency';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const CredenciamentoModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { callId } = useStream();

  const {
    getAcompanhamentoCredenciamento,
    credenciamento,
    getCredores,
    count,
  } = useCredores(Number(callId));

  useEffect(() => {
    if (isOpen) {
      getCredores();
      getAcompanhamentoCredenciamento();
      setInterval(() => {
        getAcompanhamentoCredenciamento();
      }, 10000);
    }
  }, [isOpen]);

  const confirmados = credenciamento?.reduce((acc, curr) => {
    return acc + (curr?.quantidade_confirmado || 0);
  }, 0);

  return (
    <Modal isOpen={isOpen} onClose={onClose} variant="unstyled" size="4xl">
      <ModalOverlay bg="blackAlpha.600" backdropFilter="blur(10px)" />
      <ModalContent bg="#EEEEEE">
        <ModalHeader textAlign="center" fontSize="20px" fontWeight="normal">
          Acompanhamento de Credenciamento
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flex={1} flexDir="column">
            <Text fontSize="20px">Confirmações</Text>
            <Text fontSize="14px">
              <b>{count ?? 0}</b> credores cadastrados /{' '}
              <b>{confirmados ?? 0}</b> credores confirmados
            </Text>

            <TableContainer pt="20px">
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>classe</Th>
                    <Th>Crédito confirmado</Th>
                    <Th>Crédito total</Th>
                    <Th>Presenças</Th>
                    <Th>%</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {credenciamento?.map((cred) => (
                    <Tr>
                      <Td>{formatClasse(cred.classe)}</Td>
                      <Td>{toCurrency(cred.valor_confirmado)}</Td>
                      <Td>{toCurrency(cred.valor_total)}</Td>
                      <Td>{cred.quantidade_confirmado}</Td>
                      <Td>
                        <HStack>
                          <Text fontSize="12px">{cred.percentual}%</Text>
                          <Box
                            h="20px"
                            w={`${cred.percentual}px`}
                            bg="green.500"
                          />
                        </HStack>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
