import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Warning } from '@phosphor-icons/react';
import Cookies from 'js-cookie';
import { DrawerPagament } from './drawerPagament';
import { api } from '../../../services/api';
import { ModalConfirm } from './modal';
import { useAuth } from '../../../context/AuthContext';

interface Props {
  amountPaid: number | undefined;
  appointment: string;
  profile: string | undefined;
  paymentMade: boolean;
  agcId: number;
  data: string | undefined;
  hourCred: string | undefined;
  ableCred: boolean;
  // onClick: () => void;
  closed: boolean;
  suspended: boolean;
  // eslint-disable-next-line no-unused-vars
  reopen: (onCloseConfirm: any) => void;
}

interface PropsParticipants {
  id: number;
  usuario_nome: string | undefined;
}

export const PaymentMade: React.FC<Props> = ({
  amountPaid,
  appointment,
  profile,
  paymentMade,
  agcId,
  data,
  hourCred,
  ableCred,
  closed,
  suspended,
  reopen,
}) => {
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [message, setMessage] = useState(false);
  const { auth } = useAuth();
  const [isUserEnabled, setIsUserEnabled] = useState(false);
  const [isAttorney, setIsAttorney] = useState(false);
  const [participantsAgc, setParticipantsAgc] = useState<PropsParticipants[]>(
    [],
  );

  const [remainingTime, setRemainingTime] = useState(0);

  useEffect(() => {
    participants();
    getUserStatus();
    getUserAttorney();
  }, [agcId]);

  const compareDate = (date: any) => {
    if (date !== undefined) {
      const date1 = new Date();
      const date2 = new Date(date);

      return date1 >= date2;
    }
    return false;
  };

  /* eslint no-else-return: "error" */
  // const time = () => {
  //   if (hours > 1) {
  //     return `Faltam ${hours} horas e ${minutes} minutos para o início do credenciamento`;
  //   } else if (hours === 1) {
  //     return `Falta ${hours} hora e ${minutes} minutos para o início do credenciamento`;
  //   }
  //   return `Faltam ${minutes} minutos para o início do credenciamento`;
  // };

  const participants = async () => {
    try {
      const response = await api.get(`/agc-participante/?agc_id=${agcId}`);
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setParticipantsAgc(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserStatus = async () => {
    try {
      const response = await api.get(
        `/agc-credor-procurador/?agc_id=${agcId}&habilitado=true&usuario_id=${auth.user_id}`,
      );

      if (response.status === 200 && response.data?.results.length > 0)
        setIsUserEnabled(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserAttorney = async () => {
    try {
      const response = await api.get(
        `/procurador/?tipo_procurador=recuperanda&usuario_id=${auth.user_id}`,
      );

      if (response.status === 200 && response.data?.results.length > 0) {
        const attorney = response.data.results.shift();

        const responseTeam = await api.get(
          `/agc-recuperanda-equipe/?agc_id=${agcId}&limit=100`,
        );

        if (
          responseTeam.status === 200 &&
          responseTeam.data?.results.length > 0
        ) {
          const isTeamAttorney = responseTeam.data.results.some(
            (item: any) => item.procurador_id === attorney.id,
          );
          setIsAttorney(isTeamAttorney);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAccessMeet = () => {
    console.log('aqui1');
    Cookies.set('token', auth.access, {
      domain: '.e-agc.com.br',
      secure: true,
      sameSite: 'None',
      path: '/',
    });
    Cookies.set('refresh', auth.refresh, {
      domain: '.e-agc.com.br',
      secure: true,
      sameSite: 'None',
      path: '/',
    });
    console.log('aqui2');
    console.log(Cookies.get('token'));
    // document.cookie = `token=${auth.access}; SameSite=None; Secure; Domain=.localtest.me; path=/"`;
    // document.cookie = `refresh=${auth.refresh}; SameSite=None; Secure; Domain=.localtest.me; path=/"`;
    window.location.href = `${process.env.REACT_APP_MEET_LINK}?callId=${agcId}&userId=${auth.user_id}`;
  };

  const hourSplit = hourCred?.split(':');
  const targetTime = new Date();
  if (hourSplit) {
    targetTime.setHours(Number(hourSplit[0]));
    targetTime.setMinutes(Number(hourSplit[1]));
    targetTime.setSeconds(0);
  }

  useEffect(() => {
    const timerId = setInterval(() => {
      const now = new Date();
      const timeDiff = (targetTime.getTime() - now.getTime()) / 1000;
      if (timeDiff <= 0) {
        clearInterval(timerId);
        setRemainingTime(0);
      } else {
        setRemainingTime(timeDiff);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [targetTime]);

  const hours = Math.floor(remainingTime / 3600);
  const minutes = Math.floor((remainingTime % 3600) / 60);

  return (
    <Box bg="#FFF" borderRadius="31px" p="35px">
      <Flex justify="space-between" align="center">
        <HStack spacing="67px">
          {profile === 'aj' && (
            <Stack spacing="10px">
              <Text
                color="#06152B"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
              >
                {paymentMade ? 'Pagamento realizado' : 'Pagamento Pendente'}
              </Text>
              <Text
                color="#444"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
                display="flex"
                alignItems="flex-end"
                letterSpacing="1px"
              >
                Valor pago:
                <Text
                  color="#444"
                  fontFamily="Rubik"
                  fontSize="20px"
                  fontWeight="700"
                  lineHeight="28px"
                  ml="8px"
                >
                  {amountPaid?.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </Text>
              </Text>
            </Stack>
          )}

          <Stack spacing="10px">
            <Text
              color="#06152B"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              Horário marcado
            </Text>

            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {appointment}
            </Text>
          </Stack>

          {closed && (
            <Stack spacing="0px">
              <Text color="#06152B" fontFamily="Rubik" fontWeight="400">
                Participantes
              </Text>
              <HStack spacing="0px">
                {participantsAgc.map(
                  (values, index) =>
                    index < 3 && (
                      <Avatar
                        size="sm"
                        name="Dan Abrahmov"
                        src="https://bit.ly/dan-abramov"
                        border="1px solid #FFF"
                        ml={index > 0 ? '-10px' : '0px'}
                      />
                    ),
                )}
                {participantsAgc.length > 3 && (
                  <Center
                    borderRadius="33.5px"
                    border="1px solid #FFF"
                    bg="linear-gradient(0deg, #DF992C 0%, #DF992C 100%)"
                    boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
                    color="#FFF"
                    fontFamily="Rubik"
                    fontSize="12px"
                    fontWeight="500"
                    h="30px"
                    w="30px"
                    ml="-11px"
                    zIndex={100}
                  >
                    +{participantsAgc.length - 3}
                  </Center>
                )}
              </HStack>
            </Stack>
          )}
        </HStack>

        {compareDate(data) &&
          paymentMade === true &&
          profile !== 'aj' &&
          profile !== 'nenhum' &&
          (isUserEnabled || isAttorney) &&
          !suspended &&
          !closed &&
          ableCred && (
            <Button
              borderRadius="33px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              h="57px"
              color="#FFF"
              fontFamily="Rubik"
              fontSize="14px"
              fontWeight="500"
              px="43px"
              onClick={handleAccessMeet}
            >
              Entrar na Assembleia
            </Button>
          )}

        {ableCred === true &&
          paymentMade === true &&
          profile === 'aj' &&
          !suspended &&
          !closed && (
            <Button
              borderRadius="33px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              h="57px"
              color="#FFF"
              fontFamily="Rubik"
              fontSize="14px"
              fontWeight="500"
              px="43px"
              onClick={handleAccessMeet}
            >
              Entrar na Assembleia
            </Button>
          )}

        {!ableCred && profile !== 'aj' && profile !== 'nenhum' && (
          <Text fontFamily="Rubik" fontWeight="500">
            Assembleia ainda não está habilitada!
          </Text>
        )}

        {paymentMade === false && profile === 'aj' && (
          <Button
            borderRadius="33px"
            bg="linear-gradient(0deg, #198754 0%, #198754 100%)"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            color="#FFF"
            fontFamily="Rubik"
            fontSize="14px"
            fontWeight="500"
            h="57px"
            w="126px"
            onClick={() => setOpen(true)}
          >
            Pagar
          </Button>
        )}

        {ableCred === false &&
          paymentMade === true &&
          profile === 'aj' &&
          !closed && (
            <Button
              borderRadius="33px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
              h="57px"
              color="#FFF"
              fontFamily="Rubik"
              fontSize="14px"
              fontWeight="500"
              px="43px"
              onClick={() => setMessage(true)}
            >
              Habilitar Assembleia
            </Button>
          )}

        {paymentMade === true && profile !== 'aj' && !compareDate(data) && (
          <Box
            p="20px 43px"
            border="1px solid #DF8E25"
            borderRadius="33px"
            w="26%"
          >
            <Text
              fontFamily="Rubik"
              fontSize="14px"
              color="#DCAC36"
              fontWeight="500"
              textAlign="center"
            >
              {hours > 0
                ? `Faltam ${hours} ${hours > 1 ? 'horas' : 'hora'} e ${minutes}
              ${minutes > 1 ? 'minutos' : 'minuto'}
              para o início do credenciamento`
                : `Faltam ${minutes + 1} ${
                    minutes + 1 > 1 ? 'minutos' : 'minuto'
                  } para o início do credenciamento`}
            </Text>
          </Box>
        )}

        {suspended && !closed && (
          <Button
            borderRadius="33px"
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            h="57px"
            color="#FFF"
            fontFamily="Rubik"
            fontSize="14px"
            fontWeight="500"
            px="43px"
            isDisabled
            cursor="not-allowed"
            _disabled={{ opacity: 1 }}
          >
            SUSPENSA
          </Button>
        )}

        {closed && profile !== 'aj' && (
          <Button
            borderRadius="33px"
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            h="57px"
            color="#FFF"
            fontFamily="Rubik"
            fontSize="14px"
            fontWeight="500"
            px="43px"
            isDisabled
            cursor="not-allowed"
            _disabled={{ opacity: 1 }}
          >
            ENCERRADA
          </Button>
        )}

        {closed && profile === 'aj' && (
          <Button
            borderRadius="33px"
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
            h="57px"
            color="#FFF"
            fontFamily="Rubik"
            fontSize="14px"
            fontWeight="500"
            px="43px"
            onClick={() => setOpenConfirm(true)}
          >
            REABRIR
          </Button>
        )}
      </Flex>

      <DrawerPagament isOpen={open} onClose={() => setOpen(false)} />
      <ModalConfirm
        isOpen={openConfirm}
        onClose={() => setOpenConfirm(false)}
        reopen={() => reopen(() => setOpenConfirm(false))}
      />

      <Modal isOpen={message} onClose={() => setMessage(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            p="20px"
            display="flex"
            alignItems="center"
            flexDir="column"
            textAlign="center"
          >
            <Warning size={42} weight="bold" />
            <Text fontWeight="500" fontSize="18px" fontFamily="Rubik">
              Ao menos um Procurador deve estar aprovado para habilitar a AGC.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
