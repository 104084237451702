import { Box } from '@chakra-ui/react';
import React from 'react';

interface Props {
  title: string;
  total: string;
  yesVotes: string;
  yesVotesPercentage: number;
  noVotes: string;
  noVotesPercentage: number;
  abstentionVotes: string;
  abstentionVotesPercentage: number;
}

export function ChartComponent({
  title,
  total,
  yesVotes,
  yesVotesPercentage,
  noVotes,
  noVotesPercentage,
  abstentionVotes,
  abstentionVotesPercentage,
}: Props) {
  return (
    <div className="flex flex-1 flex-col gap-5">
      <div className="flex flex-col">
        <span className="text-lg text-gray-500">{title}</span>
        <span className="text-3xl font-semibold">{total}</span>
      </div>

      <div>
        <div className="flex flex-col gap-2">
          <Box w={`${yesVotesPercentage}%`}>
            <div
              className={`py-2 ${yesVotesPercentage && 'px-2'} bg-blue-500`}
            />
          </Box>

          <Box w={`${noVotesPercentage}%`}>
            <div className={`py-2 ${noVotesPercentage && 'px-2'} bg-red-500`} />
          </Box>

          <Box w={`${abstentionVotesPercentage}%`}>
            <div
              className={`py-2 ${
                abstentionVotesPercentage && 'px-2'
              } bg-gray-200`}
            />
          </Box>
        </div>

        <div className="flex justify-between">
          <div>
            <span>Total SIM</span>
            <div className="flex flex-1 items-center gap-1">
              <div className="w-3 h-3 rounded-lg bg-red-500" />

              <div className="flex flex-col">
                <span className="font-semibold">{yesVotesPercentage}%</span>
                <span className="text-gray-500">{yesVotes}</span>
              </div>
            </div>
          </div>

          <div>
            <span>Total NÃO</span>
            <div className="flex flex-1 items-center gap-1">
              <div className="w-3 h-3 rounded-lg bg-blue-500" />

              <div className="flex flex-col">
                <span className="font-semibold">{noVotesPercentage}%</span>
                <span className="text-gray-500">{noVotes}</span>
              </div>
            </div>
          </div>

          <div>
            <span>Total Abstenção</span>
            <div className="flex flex-1 items-center gap-1">
              <div className="w-3 h-3 rounded-lg bg-gray-200" />

              <div className="flex flex-col">
                <span className="font-semibold">
                  {abstentionVotesPercentage}%
                </span>
                <span className="text-gray-500">{abstentionVotes}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
