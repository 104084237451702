import React from 'react';
import './index.css';
import App from './App';

interface AppProps {
  meetingArgs: any;
}

export const VideoSDKView: React.FC<AppProps> = ({ meetingArgs }) => {
  return (
    <React.StrictMode>
      <App meetingArgs={meetingArgs as any} />
    </React.StrictMode>
  );
};
