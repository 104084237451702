import { Box, Button, Center, Flex, Image } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/images/backgroundStart.png';
import logo from '../../assets/images/logoWhite.png';
import user from '../../assets/icons/user.png';
import { InsertEmail } from './steps/email';
import { InsertCode } from './steps/code';
import { ResetPassword } from './steps/resetPassword';

export const LostPassword: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({ token: '' });

  const navigate = useNavigate();

  const handleNextStep = (data: any) => {
    setStep(step + 1);
    setFormData(data);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  return (
    <Box
      minH="100vh"
      backgroundImage={`url(${Background})`}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      backgroundSize="cover"
    >
      <header>
        <Flex justifyContent="space-between" padding="40px">
          <Image src={logo} w="53px" h="53px" />

          <Button
            leftIcon={<Image src={user} />}
            color="#FFFFFF"
            padding="16px 27px"
            backgroundColor="#31302F"
            boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
            onClick={() => navigate('/login')}
          >
            Fazer Login
          </Button>
        </Flex>
      </header>

      <Center mt="25px">
        <Box
          bg="#EEE"
          borderRadius="37px"
          boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
          p="38px 49px"
        >
          {step === 1 && <InsertEmail handleNextStep={handleNextStep} />}
          {step === 2 && (
            <InsertCode
              handlePrevious={handlePreviousStep}
              handleNext={handleNextStep}
            />
          )}
          {step === 3 && (
            <ResetPassword
              handlePrevious={handlePreviousStep}
              formData={formData}
            />
          )}
        </Box>
      </Center>
    </Box>
  );
};
