import {
  Box,
  Button,
  ChakraProvider,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { ChangeEvent, useState } from 'react';
import { api } from '../../../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  agcId: string | undefined;
  stateDocument: any;
}

export const ModalToAdd: React.FC<Props> = ({
  isOpen,
  onClose,
  agcId,
  stateDocument,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [typeDocumento, setTypeDocument] = useState<string>('');

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0] || null;
    setSelectedFile(file);
  };

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      if (agcId) {
        formData.append('agc_id', agcId);
      }
      if (typeDocumento) {
        formData.append('tipo_documento', typeDocumento);
      }
      if (selectedFile) {
        formData.append('arquivo_link', selectedFile);
      }

      const response = await api.post('agc-documento/', formData);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        stateDocument((prevState: any) => [data, ...prevState]);
        onClose();
        setTypeDocument('');
        setSelectedFile(null);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />

        <ModalBody p="35px 45px">
          <Text fontSize="22px" fontFamily="Rubik" color="#222222">
            Cadastrar Documento
          </Text>

          <Stack mt="20px" spacing="15px">
            <Input
              fill="#FFF"
              filter="drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.10))"
              placeholder="Nome"
              onChange={(e) => setTypeDocument(e.target.value)}
            />

            <ChakraProvider>
              <Box>
                <Input
                  type="file"
                  variant="unstyled"
                  fontSize="16px"
                  bg="#FFF"
                  border="1px solid #CED4DA"
                  p="6px 12px 6px 12px"
                  _hover={{
                    borderColor: 'none',
                  }}
                  _focus={{
                    outline: 'none',
                    boxShadow: 'none',
                  }}
                  onChange={handleFileChange}
                  accept=".xls,.xlsx,.xlsb,.txt,.csv,.tsv,.pdf"
                />
              </Box>
            </ChakraProvider>

            <HStack justify="space-between">
              <Button
                bg="transparent"
                boxShadow="none"
                borderColor="#DF8E25"
                borderWidth="1px"
                fontWeight="500"
                fontSize="15px"
                w="100%"
                onClick={() => {
                  onClose();
                  setTypeDocument('');
                  setSelectedFile(null);
                }}
              >
                Cancelar
              </Button>

              <Button
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
                boxShadow="none"
                borderWidth="1px"
                fontWeight="500"
                fontSize="15px"
                color="white"
                w="100%"
                onClick={onSubmit}
              >
                Confirmar
              </Button>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
