import { Box, Center, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import UserCardRequest from '../UserCardRequest';
import ChatBox from '../ChatBox';
import { useStream } from '../../context/stream.context';
import { useWebsocket } from '../../hooks/useWebsocket';
import UserCard from '../UserCard';
import { useVideoActions } from '../../context/video-actions.context';

interface Props {
  isAdmin: boolean;
}

const LeftMenuChatAndPeoples: React.FC<Props> = ({ isAdmin }) => {
  const { users, currentUser } = useStream();
  const { requestedMicrophones } = useWebsocket();
  const { toggleAudio, usersWithAudio } = useVideoActions();

  return (
    <Flex h="full" flex="0.6">
      <Flex
        flexDirection="column"
        bg="white"
        borderRight="1px solid #ddd"
        h="full"
        w="full"
        shadow="0px 15px 30px 0px rgba(0, 0, 0, 0.1)"
      >
        <Flex flexDir="column" flex={1}>
          <Flex flexDir="column" p="30px" overflow="auto" flex="1 1 0">
            {isAdmin && (
              <Box position="relative">
                <Text fontSize="18px" pb="20px">
                  Lista de espera para falar
                </Text>

                {requestedMicrophones?.length === 0 && (
                  <Center pb="20px" opacity={0.5}>
                    <Text>Nenhum participante na lista de espera</Text>
                  </Center>
                )}

                {requestedMicrophones?.map((user, index) => (
                  <UserCardRequest
                    index={index}
                    user={user}
                    key={String(user.userId)}
                  />
                ))}

                <Divider mb="10px" />
              </Box>
            )}

            <Text fontSize="18px" pb="20px">
              Pessoas
            </Text>

            {users?.length === 0 && (
              <Center pb="50px" opacity={0.5}>
                <Text>Nenhum participante</Text>
              </Center>
            )}

            <Flex flexDir="column" flex={1}>
              {users?.map((user) => (
                <UserCard
                  currentUser={currentUser!}
                  isAdmin={isAdmin}
                  isMuted={!usersWithAudio.includes(user.userId)}
                  toggleAudio={toggleAudio}
                  user={user}
                  key={String(user.userId)}
                />
              ))}
            </Flex>
          </Flex>

          <Divider borderColor="#ddd" borderWidth="1px" />

          <Flex flexDir="column" flex={1.4}>
            <ChatBox />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LeftMenuChatAndPeoples;
