import React from 'react';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import { MyData } from './tabs/myData';
import { Address } from './tabs/address';
import { Security } from './tabs/security';

const Profile: React.FC = () => {
  return (
    <Box p="20px" zIndex={-1}>
      <Box
        boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
        background="#EEE"
        borderRadius="30px"
      >
        <Text
          color="#222"
          fontFamily="Rubik"
          fontSize="30px"
          fontWeight="500"
          ml="65px"
          pt="38px"
        >
          Editar Perfil
        </Text>
        <Tabs align="end" variant="unstyled">
          <TabList pr="17px">
            <Tab
              border="none"
              _selected={{
                bg: '#F2F2F2',
                boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              }}
              w="269px"
              background="#FFFFFF"
              borderTopRadius="30px"
              boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            >
              <Text
                py="6px"
                color="#222"
                fontFamily="Rubik"
                fontSize="18px"
                fontWeight="400"
                letterSpacing="1.8px"
              >
                Meus dados
              </Text>
            </Tab>
            <Tab
              _selected={{
                bg: '#F2F2F2',
                boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              }}
              border="none"
              w="269px"
              background="#FFFFFF"
              borderTopRadius="30px"
              boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            >
              <Text
                py="6px"
                color="#222"
                fontFamily="Rubik"
                fontSize="18px"
                fontWeight="400"
                letterSpacing="1.8px"
              >
                Endereço
              </Text>
            </Tab>
            <Tab
              _selected={{
                bg: '#F2F2F2',
                boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
              }}
              border="none"
              w="269px"
              background="#FFFFFF"
              borderTopRadius="30px"
              pr="13px"
              boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
            >
              <Text
                py="6px"
                color="#222"
                fontFamily="Rubik"
                fontSize="18px"
                fontWeight="400"
                letterSpacing="1.8px"
              >
                Segurança
              </Text>
            </Tab>
          </TabList>

          <TabPanels
            borderRadius="30px"
            fill="#DADADA"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset"
          >
            <TabPanel>
              <MyData />
            </TabPanel>

            <TabPanel>
              <Address />
            </TabPanel>

            <TabPanel>
              <Security />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default Profile;
