import {
  Box,
  Button,
  CSSReset,
  ChakraProvider,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Skeleton,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  theme,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AddIcon, CloseIcon, UpDownIcon } from '@chakra-ui/icons';
import { FaSearch, FaTrash } from 'react-icons/fa';
import { useForm, FormProvider } from 'react-hook-form';
import { RiFolderWarningLine } from 'react-icons/ri';
import { api } from '../../../services/api';
import CheckIcon from '../../../assets/icons/checkIcon.png';
import LeftArrow from '../../../assets/icons/leftArrowBlack.svg';
import InputComponent from '../../../components/forms/input';
import { ModalAddRecover } from './components/modalAddRecover';
import { ModalConfirm } from './components/mondalConfirm';

interface Props {
  title: string;
  iconTitle: string;
  id: string | undefined;
  profile: string | undefined;
}

interface PropsRecover {
  nome: string;
  tipo_documento: string;
  documento: string;
}

interface PropsAttorney {
  id: number;
  procurador_nome: string;
  texto: string;
}

export const Recover: React.FC<Props> = ({ title, iconTitle, id, profile }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [recover, setRecover] = useState<PropsRecover[]>([]);
  const [attorneys, setAttorneys] = useState<PropsAttorney[]>([]);

  const [step, setStep] = useState(1);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openAddRecover, setOpenAddRecover] = useState<boolean>(false);

  const [errorExists, setErrorExists] = useState('');

  const formState = useForm({
    mode: 'onChange',
    defaultValues: {
      cpf: '',
      texto: '',
      procurador_id: undefined,
      procurador_info: undefined,
    },
  });

  useEffect(() => {
    getRecover();
    if (step === 1 || open) {
      getReorganizationAttorneys();
    }
  }, [open, step]);

  const getRecover = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/agc-recuperanda/?agc_id=${id}`);
      const { results } = response.data;
      if (response.status === 200) {
        setRecover(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReorganizationAttorneys = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/agc-recuperanda-equipe/?agc_id=${id}`);
      const { results } = response.data;
      if (response.status === 200) {
        setAttorneys(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteReorganizationAttorneys = async (values: any) => {
    setLoading(true);
    try {
      const response = await api.delete(`/agc-recuperanda-equipe/${values.id}`);
      if (response.status === 204) {
        setAttorneys((prevState) =>
          prevState.filter((e) => e.id !== values.id),
        );
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchUser = async () => {
    try {
      const response = await api.get(
        `/usuario/buscar-cpf/${formState.watch('cpf').replace(/\D/g, '')}/`,
      );
      const responseProcurator = await api.get(
        `/procurador/?tipo_procurador=recuperanda&usuario_id=${response.data.id}`,
      );
      if (responseProcurator.data.length === 0) {
        formState.reset({ cpf: formState.watch('cpf') });
        toast({
          title: 'Procurador não encontrado!',
          description:
            'Nenhum procurador de recuperanda encontrado para este CPF',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      } else {
        formState.clearErrors();
        setErrorExists('');
        formState.setValue(
          'procurador_id',
          responseProcurator.data.results[0].id,
        );
        formState.setValue('procurador_info', response.data);
        toast({
          title: 'Procurador encontrado!',
          description:
            'Procurador encontrado com sucesso, confirme as informações!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      console.log(error);
      // const { status } = error.response;
      formState.reset({ cpf: formState.watch('cpf') });
      if (error.response.status === 404) {
        setErrorExists('Nenhum procurador encontrado para o CPF informado!');
      }
    }
  };

  const onSubmit = async (data: any) => {
    onClose();
    setLoading(true);
    try {
      const dataForm = {
        cpf: undefined,
        agc_id: id,
        procurador_id: data.procurador_id,
        texto: data.texto,
      };
      const response = await api.post('/agc-recuperanda-equipe/', dataForm);
      if (response.status === 201) {
        setLoading(false);
        setStep(step - 1);
        setAttorneys((prevAttorneys) => [response.data, ...prevAttorneys]);
        toast({
          title: 'Procurador adicionado!',
          description: 'Procurador adicionado a recuperanda com sucesso!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        formState.reset();
      }
    } catch (error: any) {
      const { status } = error.response;
      if (status === 400) {
        setErrorExists('Usuário selecionado já é um procurador da recuperanda');
      }
    }
  };

  const formatCnpjCpf = (value: string) => {
    /* eslint no-else-return: "error" */
    if (value && value.length === 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (value && value.length === 14) {
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
    }
    return value;
  };

  return (
    <Box
      bg="#FFF"
      borderRadius="31px"
      p="25px 21px 25px 25px"
      h="380px"
      w="100%"
    >
      <CSSReset />
      <ModalConfirm
        isOpen={isOpen}
        onClose={onClose}
        name={`${(formState.watch('procurador_info') as any)?.nome} ${(
          formState.watch('procurador_info') as any
        )?.sobrenome}`}
        onConfirm={formState.handleSubmit(onSubmit)}
      />
      <ChakraProvider theme={theme}>
        <Stack
          spacing="22px"
          maxH={profile !== 'aj' ? '100%' : '280px'}
          overflowY="auto"
          overflowX="hidden"
          pr="10px"
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
              height: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
              borderRadius: 'full',
            },
            '&::-webkit-scrollbar-track': {
              width: '8px',
              bg: '#CCC',
              borderRadius: '10px',
            },
          }}
          h="80%"
        >
          <HStack spacing="33px" align="center">
            <Image src={iconTitle} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              {title}
            </Text>
          </HStack>

          {recover && recover.length === 0 ? (
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              h="60%"
            >
              <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
              <Text color="#222222" fontFamily="Rubik" fontWeight="500">
                Nenhuma recuperanda encontrada!
              </Text>
            </Box>
          ) : (
            recover.map((item) => (
              <Box borderRadius="12px" border="1px solid #E7E7E7" p="9px 10px">
                <Stack spacing="10px">
                  <Stack spacing="5px">
                    <Text
                      color="#515151"
                      fontFamily="Rubik"
                      fontSize="18px"
                      fontWeight="400"
                    >
                      {item.nome}
                    </Text>
                    <Text
                      color="#515151"
                      fontFamily="Rubik"
                      fontSize="14px"
                      fontWeight="400"
                      letterSpacing="0.45px"
                    >
                      {item.tipo_documento === 'cnpj'
                        ? `CNPJ: ${formatCnpjCpf(item.documento)}`
                        : `CPF: ${formatCnpjCpf(item.documento)}`}
                    </Text>
                  </Stack>
                </Stack>
              </Box>
            ))
          )}
        </Stack>
        <HStack mt="22px" spacing="10px" justify="space-between">
          {profile === 'aj' && (
            <button
              style={{
                color: '#FFF',
                fontFamily: 'Rubik',
                fontSize: '12px',
                fontWeight: '500',
                background:
                  'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
                boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '19px',
                padding: '9px 5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              onClick={() => setOpenAddRecover(true)}
            >
              Adicionar Recuperanda
            </button>
          )}

          <button
            style={{
              borderRadius: '19px',
              border: '1px solid #DF8E25',
              boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)',
              color: '#DF8E25',
              fontSize: '14px',
              fontWeight: '500',
              width: '46%',
              lineHeight: '88.5%',
              height: 32,
            }}
            onClick={() => setOpen(true)}
          >
            Procuradores das Recuperandas
          </button>
        </HStack>
      </ChakraProvider>

      <Drawer
        isOpen={open}
        onClose={() => {
          setOpen(false);
          setStep(1);
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent bg="#EEE">
          <DrawerCloseButton />

          <Box p="68px 38px">
            {step === 1 && (
              <Stack spacing="36px">
                <Stack spacing="24px">
                  <Skeleton isLoaded={!loading} w="40%">
                    <Text
                      color="#222"
                      fontFamily="Rubik"
                      fontSize="22px"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      Procuradores
                      <Text>das Recuperandas</Text>
                    </Text>
                  </Skeleton>

                  <Skeleton borderRadius="53px" isLoaded={!loading} w="244px">
                    {profile === 'aj' && (
                      <Button
                        leftIcon={<AddIcon w="10px" h="10px" color="#FFF" />}
                        color="#FFF"
                        fontFamily="Rubik"
                        fontSize="12px"
                        fontWeight="500"
                        borderRadius="53px"
                        bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                        boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                        size="xs"
                        w="244px"
                        onClick={() => setStep(step + 1)}
                      >
                        Novo procurador da recuperanda
                      </Button>
                    )}
                  </Skeleton>
                </Stack>

                <Skeleton isLoaded={!loading}>
                  <TableContainer>
                    <Table>
                      <Thead borderBottom="2px solid #9B9B9B">
                        <Tr>
                          <Th
                            color="#000"
                            fontFamily="Rubik"
                            fontSize="12px"
                            fontWeight="700"
                          >
                            <HStack spacing="8px">
                              <Text>Nome</Text>
                              <button>
                                <UpDownIcon
                                  fontWeight="400"
                                  w="15px"
                                  h="15px"
                                />
                              </button>
                            </HStack>
                          </Th>
                          <Th
                            color="#000"
                            fontFamily="Rubik"
                            fontSize="12px"
                            fontWeight="700"
                          >
                            <HStack spacing="8px">
                              <Text>Documento</Text>
                              <button>
                                <UpDownIcon
                                  fontWeight="400"
                                  w="15px"
                                  h="15px"
                                />
                              </button>
                            </HStack>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {attorneys &&
                          attorneys.length > 0 &&
                          attorneys.map((item) => (
                            <Tr borderBottom="2px solid #CCC">
                              <Td>{item.procurador_nome}</Td>
                              <Td>{item.texto}</Td>
                              <Td>
                                <button
                                  onClick={() =>
                                    deleteReorganizationAttorneys(item)
                                  }
                                >
                                  <FaTrash color="#D89320" size={20.5} />
                                </button>
                              </Td>
                            </Tr>
                          ))}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Skeleton>

                <Flex justify="space-between">
                  <Skeleton isLoaded={!loading} borderRadius="53px">
                    <Button
                      borderRadius="45px"
                      border="2px solid #DF8E25"
                      bg="rgba(255, 255, 255, 0.00)"
                      color="#000"
                      fontFamily="Rubik"
                      fontSize="14px"
                      fontWeight="400"
                      onClick={() => setOpen(false)}
                    >
                      <HStack spacing="10px" align="center">
                        <CloseIcon
                          w="10px"
                          h="10px"
                          color="#000"
                          opacity="0.25"
                        />
                        <Text>Cancelar</Text>
                      </HStack>
                    </Button>
                  </Skeleton>

                  <Skeleton isLoaded={!loading} borderRadius="53px">
                    <Button
                      borderRadius="105px"
                      bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                      boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                      fontFamily="Rubik"
                      fontSize="14px"
                      color="#FFF"
                      p="14px 25px"
                      w="170px"
                      onClick={() => setOpen(false)}
                    >
                      <HStack spacing="10px">
                        <Image src={CheckIcon} />
                        <Text>Confirmar</Text>
                      </HStack>
                    </Button>
                  </Skeleton>
                </Flex>
              </Stack>
            )}

            {step === 2 && (
              <FormProvider {...formState}>
                <form onSubmit={formState.handleSubmit(onSubmit)}>
                  <Stack spacing="36px">
                    <HStack w="34%" spacing="18px">
                      <button
                        onClick={() => {
                          setStep(step - 1);
                          setErrorExists('');
                        }}
                      >
                        <Image src={LeftArrow} />
                      </button>
                      <Text
                        color="#222"
                        fontFamily="Rubik"
                        fontSize="22px"
                        fontWeight="400"
                      >
                        Selecionar procurador da recuperanda
                      </Text>
                    </HStack>

                    <Stack spacing="17px">
                      <Stack spacing="2px">
                        <div className="flex w-full gap-2 items-end">
                          <InputComponent
                            name="cpf"
                            label="Buscar por CPF"
                            placeholder="Busque pelo CPF"
                            mask="***.***.***-**"
                          />

                          <Button
                            borderRadius="105px"
                            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                            boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                            fontFamily="Rubik"
                            fontSize="14px"
                            color="#FFF"
                            p="14px 25px"
                            leftIcon={<FaSearch />}
                            onClick={() => handleSearchUser()}
                          >
                            <HStack spacing="10px">
                              {/* <Image src={CheckIcon} /> */}
                              <Text>Buscar procurador</Text>
                            </HStack>
                          </Button>
                        </div>

                        {/* {formState.watch('procurador_id') && (
                          <InputComponent
                            name="texto"
                            placeholder="Texto"
                            mt={2}
                          />
                        )} */}

                        {errorExists && (
                          <Text fontSize="12px" color="red" ml="20px">
                            {errorExists}
                          </Text>
                        )}
                      </Stack>

                      {formState.watch('procurador_id') && (
                        <div className="flex flex-col items-start gap-1">
                          <Text fontSize={16}>Procurador encontrado:</Text>
                          <div className="flex text-lg gap-2">
                            Nome:
                            <strong>
                              {
                                (formState.watch('procurador_info') as any)
                                  ?.nome
                              }{' '}
                              {
                                (formState.watch('procurador_info') as any)
                                  ?.sobrenome
                              }
                            </strong>
                          </div>
                          <div className="flex text-lg gap-2">
                            CPF:
                            <strong>
                              {(formState.watch('procurador_info') as any)?.cpf}
                            </strong>
                          </div>
                          <div className="flex text-lg gap-2">
                            Email:
                            <strong>
                              {
                                (formState.watch('procurador_info') as any)
                                  ?.email
                              }
                            </strong>
                          </div>
                          <div className="flex text-lg gap-2">
                            Celular:
                            <strong>
                              {
                                (formState.watch('procurador_info') as any)
                                  ?.celular
                              }
                            </strong>
                          </div>
                        </div>
                      )}
                    </Stack>

                    <Flex justify="space-between">
                      <Button
                        borderRadius="45px"
                        border="2px solid #DF8E25"
                        bg="rgba(255, 255, 255, 0.00)"
                        color="#000"
                        fontFamily="Rubik"
                        fontSize="14px"
                        fontWeight="400"
                        onClick={() => {
                          setOpen(false);
                          setStep(1);
                        }}
                      >
                        <HStack spacing="10px" align="center">
                          <CloseIcon
                            w="10px"
                            h="10px"
                            color="#000"
                            opacity="0.25"
                          />
                          <Text>Cancelar</Text>
                        </HStack>
                      </Button>

                      <Button
                        borderRadius="105px"
                        bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                        boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                        fontFamily="Rubik"
                        fontSize="14px"
                        color="#FFF"
                        p="14px 25px"
                        w="170px"
                        isDisabled={!formState.watch('procurador_id')}
                        onClick={onOpen}
                      >
                        <HStack spacing="10px">
                          <Image src={CheckIcon} />
                          <Text>Confirmar</Text>
                        </HStack>
                      </Button>
                    </Flex>
                  </Stack>
                </form>
              </FormProvider>
            )}
          </Box>
        </DrawerContent>
      </Drawer>

      <ModalAddRecover
        isOpen={openAddRecover}
        onClose={() => setOpenAddRecover(false)}
        id={id}
        stateRecover={setRecover}
      />
    </Box>
  );
};
