import React, { useState } from 'react';
import { Box, Button, Image, Stack, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import InputComponent from '../../../../components/forms/input';
import { api } from '../../../../services/api';
import ArrowRight from '../../../../assets/icons/arrowRight.svg';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Campo obrigatório.')
    .test('email-no-exists', 'Este email não existe.', async (value) => {
      if (!value) return true;

      try {
        const response = await api.get(`/usuario/valida-email-existe/${value}`);
        return response.data.status;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return false;
      }
    }),
});

export const InsertEmail = ({ handleNextStep }: any) => {
  const [loading, setLoading] = useState(false);

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  });
  const navigate = useNavigate();

  const sendEmail = async (data: any) => {
    setLoading(true);
    try {
      const response = await api.post('/login/password-reset/', data);
      if (response.status === 200 || response.status === 201) {
        handleNextStep();
        setLoading(false);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box display="flex" flexDir="column" alignItems="center">
      <Text color="#222" fontFamily="Rubik" fontSize="36px" fontWeight="500">
        Perdeu a senha?
      </Text>

      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(sendEmail)}>
          <Stack mt="41px" spacing="15px">
            <InputComponent
              w="370px"
              h="53px"
              placeholder="E-mail"
              _placeholder={{
                color: '#AAA',
                fontFamily: 'Rubik',
                fontSize: '16px',
                fontWeight: '400',
              }}
              name="email"
            />
            <Button
              borderRadius="31px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              boxShadow="0px 15px 30px 0px rgba(117, 76, 20, 0.25)"
              color="white"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              type="submit"
              isLoading={loading}
            >
              Continuar
              <Image src={ArrowRight} ml="17px" />
            </Button>
          </Stack>
        </form>
      </FormProvider>

      <Stack
        mt="30px"
        w="100%"
        display="flex"
        flexDir="column"
        alignItems="center"
        spacing="15px"
      >
        <Box bg="#DDD" h="1px" w="100%" />
        <Text
          color="#222"
          fontFamily="Rubik"
          fontSize="16px"
          fontWeight="400"
          onClick={() => navigate('/login')}
          cursor="pointer"
        >
          Cancelar
        </Text>
      </Stack>
    </Box>
  );
};
