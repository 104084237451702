import { AddIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Warning } from '@phosphor-icons/react';
import { api } from '../../services/api';
import { DrawerBond } from './components/drawerBond';
import { QualifiedAttorneys } from './components/cards/qualifiedAttorneys';
import { Comments } from './components/cards/comments';
import { DocsSentAttorney } from './components/cards/docsSentAttorney';

interface PropsCreditor {
  id: string;
  nome: string;
  tipo_documento: string;
  documento: string;
  moeda: string;
  valor_original: number;
  valor: number;
  agc_classe_classe: string;
}

export const InternalCreditorBond: React.FC = () => {
  const [agcNome, setAgcNome] = useState('');

  const [creditor, setCreditor] = useState<PropsCreditor>();

  const [open, setOpen] = useState(false);
  const [creditorExist, setCreditorExist] = useState(false);
  const [openUnrealized, setOpenUnrealized] = useState(false);

  const [paymentMade, setPaymentMade] = useState(false);

  const { internalCreditorBondId, agcId } = useParams();

  useEffect(() => {
    getNameAgc();
    getCreditor();
    creditorAttorney();
    paymentAgc();
  }, []);

  const getNameAgc = async () => {
    const response = await api.get(`/agc/${agcId}`);

    if (response.status === 200) {
      setAgcNome(response.data.nome);
    }
  };

  const getCreditor = async () => {
    const response = await api.get(`/agc-credor/${internalCreditorBondId}`);
    if (response.status === 200) {
      setCreditor(response.data);
    }
  };

  const classeFormatted = () => {
    /* eslint no-else-return: "error" */
    if (creditor?.agc_classe_classe === 'trabalhista') {
      return 'Trabalhista';
    } else if (creditor?.agc_classe_classe === 'garantia-real') {
      return 'Garantia Real';
    } else if (creditor?.agc_classe_classe === 'Quirografário') {
      return 'Quirografário';
    }
    return 'Microempresa';
  };

  const formatCnpjCpf = (value: any) => {
    if (value && value.length === 11) {
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (value && value.length === 14) {
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      );
    }
    return value;
  };

  const formattedValue = {
    coin: creditor?.moeda,
    valor: `${creditor?.valor.toLocaleString('pt-BR', {
      style: 'currency',
      currency: creditor && creditor.moeda,
    })}`,
  };

  const userId = localStorage.getItem('userId');

  const creditorAttorney = async () => {
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_credor_id=${internalCreditorBondId}&agc_id=${agcId}&usuario_id=${userId}`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        if (results.length > 0) {
          setCreditorExist(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const paymentAgc = async () => {
    try {
      const response = await api.get(`agc/${agcId}`);
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        setPaymentMade(data.pagamento_realizado);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p="33px 32px 13.5px 20px">
      <Flex justify="space-between" align="center">
        <Breadcrumb
          spacing="7px"
          separator={<ChevronRightIcon w="22px" h="22px" color="#AAAAAA" />}
          display="flex"
          alignItems="flex-start"
        >
          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to="/agcs"
              color="#224141"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              AGC's
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              as={Link}
              to={`/agcs/${agcId}`}
              color="#224141"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              {agcNome}
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink
              color="#848484"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
            >
              Detalhes do Credor
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        {!creditorExist && (
          <Button
            leftIcon={
              <AddIcon
                w="10px"
                h="10px"
                color="#FFF"
                width="15px"
                height="15px"
              />
            }
            bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
            color="#FFF"
            p="8px 27px"
            onClick={
              paymentMade ? () => setOpen(true) : () => setOpenUnrealized(true)
            }
          >
            Solicitar vinculo
          </Button>
        )}
        {creditorExist && (
          <Text fontSize="18px" fontWeight="500">
            Vinculo solicitado.
          </Text>
        )}
      </Flex>

      <Flex
        p="35px"
        bg="#FFF"
        borderRadius="31px"
        justify="space-between"
        mt="14px"
      >
        <Box>
          <Text
            color="#06152B"
            fontSize="16px"
            fontWeight="400"
            fontFamily="Rubik"
          >
            Nome
          </Text>
          <Text>{creditor?.nome}</Text>
        </Box>

        <HStack spacing="57px">
          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              CNPJ / CPF
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {formatCnpjCpf(creditor?.documento)}
            </Text>
          </Box>

          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              Classe
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {classeFormatted()}
            </Text>
          </Box>
        </HStack>

        <HStack spacing="57px">
          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              Moeda
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {formattedValue.coin}
            </Text>
          </Box>

          <Box>
            <Text
              color="#06152B"
              fontSize="16px"
              fontWeight="400"
              fontFamily="Rubik"
            >
              Crédito
            </Text>
            <Text
              color="#444"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="1px"
            >
              {formattedValue.valor}
            </Text>
          </Box>
        </HStack>
      </Flex>

      <HStack mt="33px" spacing="16px">
        <QualifiedAttorneys id={internalCreditorBondId} idAgc={agcId} />
        <DocsSentAttorney id={internalCreditorBondId} idAgc={agcId} />
        <Comments id={internalCreditorBondId} idAgc={agcId} />
      </HStack>

      <DrawerBond
        isOpen={open}
        onClose={() => setOpen(false)}
        id={internalCreditorBondId}
      />

      <Modal
        isOpen={openUnrealized}
        onClose={() => setOpenUnrealized(false)}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody
            p="20px"
            display="flex"
            alignItems="center"
            flexDir="column"
            textAlign="center"
          >
            <Warning size={42} weight="bold" />
            <Text fontWeight="500" fontSize="18px" fontFamily="RubikF">
              Por favor, entre em contato com o Administrador Judicial, pois as
              habilitações para esta AGC ainda não foram liberadas.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
