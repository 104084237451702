/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from 'react';
// import { STORAGE_KEYS } from '../constants/Config';
import { useNavigate } from 'react-router-dom';
import {
  getAuthStorage,
  removeAuthStorage,
  setAuthStorage,
} from '../services/storage';
import { api } from '../services/api';

interface AuthContextData {
  isAuthenticated: boolean;
  user: UserType | object | any;
  loading: boolean;
  error: ErrorType | string | null | unknown;
  login(user: object): Promise<void> | any;
  logout: () => void;
  getUser(id: number): any;
  register(data: any): any;
  auth: {
    user_id: number;
    access: string;
    refresh: string;
  };
}

interface UserImageType {
  large: string;
  medium: string;
  original: string;
  small: string;
}

interface UserType {
  id: number;
  cpf: string;
  cell_phone: string;
  email: string;
  genre: string;
  name: string;
  status: boolean;
  image: UserImageType | null;
}

interface ErrorType {
  status: string;
  msg: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [error, setError] = useState<ErrorType | string | null>(null);
  const [user, setUser] = useState<UserType | object | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [auth, setAuth] = useState<any>(null);

  useEffect(() => {
    const verifyLogged = async () => {
      const storageAuth = await getAuthStorage();
      try {
        if (storageAuth !== null) {
          setAuth(storageAuth);
          api.defaults.headers.common.Authorization = `Bearer ${storageAuth.access}`;
          await getUser(storageAuth.user_id);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    verifyLogged();
  }, []);

  const getUser: AuthContextData['getUser'] = async (id: number) => {
    const res = await api.get(`/usuario/registro/${id}/`);
    if (res.status !== 200) {
      return false;
    }
    setUser(res.data);
    localStorage.setItem('user', JSON.stringify(res.data));
    return res.data;
  };

  const login: AuthContextData['login'] = async (data) => {
    setLoading(true);
    setError(null);

    api.defaults.headers.common.Authorization = process.env
      .REACT_APP_BASIC_ADMIN as string;
    const res = await api.post('/login/', {
      grant_type: 'password',
      ...data,
    });

    if (res.status === 200) {
      setAuth(res.data);
      localStorage.setItem('userId', res.data.user_id);
      await setAuthStorage(res.data);
      api.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
      await getUser(res.data.user_id);
      navigate('/agcs');
      setLoading(false);
    }
  };

  const register = async (data: any) => {
    const response = await api.post('/usuario/registro/', data);

    if (response.status === 201) {
      login(data);
    }
  };

  const logout = () => {
    removeAuthStorage();
    setUser(null);
    localStorage.removeItem('user');
    localStorage.removeItem('userId');
    localStorage.removeItem('savedPassword');
    localStorage.removeItem('rememberPassword');
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: Boolean(localStorage.getItem('user')),
        user: Object(user),
        loading,
        error,
        getUser,
        login,
        logout,
        register,
        auth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const retorno = useContext(AuthContext);
  return retorno;
};
