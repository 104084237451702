import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputMask from 'react-input-mask';
import InputComponent from '../../../../../components/forms/input';
import iconArrow from '../../../../../assets/icons/rightArrow.png';
import { ListStates } from '../../../../../constants/listStates';
import { api } from '../../../../../services/api';
import { CheckboxAgc } from '../../../../registerAgc/components/checkbox';
import { useAuth } from '../../../../../context/AuthContext';

const schema = yup.object().shape({
  endereco: yup.string().required('Campo obrigatório.'),
  numero: yup.string().required('Campo obrigatório.'),
  complemento: yup.string().required('Campo obrigatório.'),
  bairro: yup.string().required('Campo obrigatório.'),
  cidade: yup.string().required('Campo obrigatório.'),
  uf: yup.string().required('Campo obrigatório.').max(2, 'UF inválido'),
  cep: yup
    .string()
    .required('Campo obrigatório.')
    .matches(/^\d{5}-\d{3}$/, 'CEP inválido'),
});

interface Props {
  id: number;
  descricao: string | undefined;
  status_termo: string | undefined;
  tipo_documento_termo: string | undefined;
  texto: string | undefined;
}

export const FormTwo = ({ formData }: any) => {
  const [termsUse, setTermsUse] = useState<Props[]>([]);
  const [checkBoxStates, setCheckboxStates] = useState<boolean[]>([]);

  const [addressData, setAddressData] = useState<any>(null);

  useEffect(() => {
    terms();
  }, []);

  const formMethods = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      endereco: '',
      numero: '',
      complemento: '',
      bairro: '',
      cidade: '',
      uf: '',
      cep: '',
    },
  });

  const { login } = useAuth();

  const {
    formState: { errors },
  } = formMethods;

  const error = formMethods.formState.errors.cep;

  const onSubmit = async (dataForm: any) => {
    try {
      if (addressData) {
        dataForm.endereco = addressData.logradouro;
        dataForm.bairro = addressData.bairro;
        dataForm.cidade = addressData.localidade;
        dataForm.uf = addressData.uf;
      }

      const updatedFormData = { ...formData, ...dataForm };
      const responseUser = await api.post('usuario/registro/', updatedFormData);
      const { data } = responseUser;
      const promises = termsUse.map(async (item) => {
        const dataTerms = {
          usuario: data.id,
          termo_id: item.id,
        };
        const response = await api.post('usuario/usuario_termos/', dataTerms);
        if (response.status === 200 || response.status === 201) {
          login(formData);
        }
      });

      await Promise.all(promises);
    } catch (e) {
      console.error(e);
    }
  };

  const ufs = Object.entries(ListStates);

  const terms = async () => {
    try {
      const response = await api.get('usuario/termos');
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setTermsUse(results);
        setCheckboxStates(new Array(results.length).fill(false));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleCheckboxChange = (index: number, checked: boolean) => {
    const updatedStates = [...checkBoxStates];
    updatedStates[index] = checked;
    setCheckboxStates(updatedStates);
  };

  const isButtonEnabled = checkBoxStates.every((isChecked) => isChecked);

  const fetchCEPDataTeste = async (cep: string) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      console.log(response);
      if (response.ok) {
        const data = await response.json();
        setAddressData(data);
        formMethods.setValue('endereco', data.logradouro);
        formMethods.setValue('bairro', data.bairro);
        formMethods.setValue('cidade', data.localidade);
        formMethods.setValue('uf', data.uf);
      } else {
        setAddressData(null);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <main>
      <Center>
        <Box display="flex" flexDir="column" alignItems="center" mb="20px">
          <Box
            background="#EEE"
            borderRadius="37px"
            boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
          >
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="36px"
              fontWeight="500"
              textAlign="center"
              p="38px 0px 41px 0px"
            >
              Ta quase!
            </Text>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <Stack px="49px" spacing="15px" pb="38px">
                  <Box>
                    <Input
                      w="370px"
                      h="53px"
                      placeholder="CEP"
                      _placeholder={{
                        color: '#AAA',
                        fontFamily: 'Rubik',
                        fontWeight: '400',
                      }}
                      {...formMethods.register('cep')}
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      onChange={(e: any) => {
                        const cep = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos do CEP
                        if (cep.length >= 8) {
                          // Se o CEP tiver 8 dígitos, faz a solicitação à API do ViaCEP
                          fetchCEPDataTeste(cep);
                        }
                      }}
                      as={InputMask}
                      mask="*****-***"
                    />
                    {error && (
                      <Text
                        color="red.400"
                        fontSize="12px"
                        lineHeight="12px"
                        marginLeft="15px"
                        textStyle="body.small"
                        fontFamily="Rubik"
                        mt="4px"
                      >
                        {String(error.message)}
                      </Text>
                    )}
                  </Box>

                  <InputComponent
                    name="endereco"
                    w="370px"
                    h="53px"
                    placeholder="Endereço"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontWeight: '400',
                    }}
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  />
                  <InputComponent
                    name="numero"
                    w="370px"
                    h="53px"
                    placeholder="Número"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontWeight: '400',
                    }}
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  />
                  <InputComponent
                    name="complemento"
                    w="370px"
                    h="53px"
                    placeholder="Complemento"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontWeight: '400',
                    }}
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  />
                  <InputComponent
                    name="bairro"
                    w="370px"
                    h="53px"
                    placeholder="Bairro"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontWeight: '400',
                    }}
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  />
                  <InputComponent
                    name="cidade"
                    w="370px"
                    h="53px"
                    placeholder="Cidade"
                    _placeholder={{
                      color: '#AAA',
                      fontFamily: 'Rubik',
                      fontWeight: '400',
                    }}
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  />

                  <Controller
                    name="uf"
                    control={formMethods.control}
                    render={({ field }) => (
                      <Box>
                        <Select
                          {...field}
                          w="370px"
                          h="53px"
                          placeholder="Selecione uma UF"
                          boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                          borderRadius="43px"
                          background="#FFF"
                          _focus={{ border: 'none' }}
                          _focusVisible={{ border: 'none' }}
                        >
                          {ufs.map((states) => {
                            const id = states[0];
                            const label = states[0];
                            return (
                              <option key={id} value={id}>
                                {label}
                              </option>
                            );
                          })}
                        </Select>
                        {errors.uf && (
                          <Text
                            textAlign="start"
                            marginLeft="15px"
                            marginTop="4px !important"
                            lineHeight="12px"
                            fontSize="12px"
                            color="red.400"
                            textStyle="body.small"
                            fontFamily="Rubik"
                          >
                            {String(errors.uf?.message)}
                          </Text>
                        )}
                      </Box>
                    )}
                  />

                  <Box>
                    {termsUse &&
                      termsUse.length > 0 &&
                      termsUse.map((item, index) => (
                        <Stack mt="4px" w="40%" key={item.id}>
                          <CheckboxAgc
                            name={item?.descricao}
                            onChange={(e: any) => {
                              handleCheckboxChange(index, e.target.checked);
                            }}
                          />
                        </Stack>
                      ))}

                    <Button
                      width="100%"
                      h="51px"
                      background="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #31302F"
                      color="#FFF"
                      fontFamily="Rubik"
                      fontSize="16px"
                      fontWeight="400"
                      rightIcon={<Image src={iconArrow} />}
                      type="submit"
                      mt="20px"
                      isDisabled={!isButtonEnabled}
                    >
                      Continuar
                    </Button>
                  </Box>
                </Stack>
              </form>
            </FormProvider>
          </Box>
        </Box>
      </Center>
    </main>
  );
};
