import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { api } from '../../../services/api';
import { ChartComponent } from './Chart';

interface Props {
  agc_pergunta_id: number;
  descricao: string | undefined;
  votos_validos: {
    total_quantidade: number;
    total_valor: number;
    itens: {
      resposta: string | undefined;
      abstencao: boolean;
      quantidade: number;
      percentual_quantidade: number;
      valor: number;
      percentual_valor: number;
    }[];
  };
  resumo_resposta: {
    total_quantidade: number;
    total_valor: number;
    itens: {
      resposta: string | undefined;
      abstencao: boolean;
      quantidade: number;
      percentual_quantidade: number;
      valor: number;
      percentual_valor: number;
    }[];
  };
  resumo_classe: {
    classe: string;
    total_quantidade: number;
    total_valor: number;
    itens: {
      resposta: string;
      quantidade: number;
      percentual_quantidade: number;
      percentual_valor: number;
      valor: number;
    }[];
  }[];
  votos_classe: {
    classe: string;
    itens: {
      credor: string;
      procurador: string;
      valor: number;
      voto: string;
    }[];
  }[];
}

export const Report: React.FC = () => {
  const { agcId } = useParams();

  const [reportAgc, setReportAgc] = useState<Props[]>([]);
  const [agc, setAgc] = useState({
    id: 0,
    aj_id: 0,
    aj_usuario_id: '',
    aj_usuario_nome: '',
    aj_usuario_cpf: '',
    data_cadastro: '',
    numero_processo: '',
    natureza: '',
    nome: '',
    observacao: '',
    data_credenciamento: '',
    hora_credenciamento: '',
    hora_instalacao: '',
    convocacao: '',
    credenciamento_habilitado: false,
    suspensa: false,
    encerrada: false,
    zoom_meeting_id: null,
    zoom_meeting_password: null,
    agc_id_anterior: null,
    pagamento_realizado: false,
    valor_pago: 0,
  });

  useEffect(() => {
    questions();
    getInfosAGC();
  }, []);

  const questions = async () => {
    try {
      const response = await api.get(`agc-pergunta/?agc_id=${agcId}`);
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;

        for (const item of results) {
          const responses = await api.get(
            `agc-pergunta/${item.id}/laudo-votacao/`,
          );
          if (responses.status === 200 || responses.status === 201) {
            setReportAgc((prevState) => [...prevState, responses.data]);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formatTime = (dateTime: any) => {
    if (typeof dateTime !== 'undefined') {
      const today = new Date(dateTime);
      const hours =
        today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes =
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();

      const dateNow = `${hours}:${minutes}`;

      return dateNow;
    }

    return '';
  };

  const getInfosAGC = async () => {
    try {
      const response = await api.get(`/agc/${agcId}/`);
      if (response.status === 200 || response.status === 201) {
        const dataFormattData = {
          ...response.data,
          hora_credenciamento: formatTime(response.data.data_credenciamento),
        };
        setAgc(dataFormattData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="mt-5 pb-5">
      <div className="bg-white shadow-[0_15px_30px_0_rgb(0,0,0,0.10)] rounded-xl p-5 flex gap-16">
        <div className="flex flex-col gap-2">
          <span className="font-medium">Assembleia</span>
          <span>{agc?.nome}</span>
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-medium">Laudo gerado</span>
          <span>{moment().format('DD/MM/yyyy [às] HH:mm')}</span>
        </div>
      </div>

      {reportAgc &&
        reportAgc.length > 0 &&
        reportAgc
          .reduce((unique: Props[], o: Props) => {
            if (
              !unique.some(
                (obj: Props) => obj?.agc_pergunta_id === o.agc_pergunta_id,
              )
            ) {
              unique.push(o);
            }
            return unique;
          }, [])
          .map((item) => {
            const toCurrency = (value: number) => {
              const formatted = value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              });

              return formatted;
            };

            const classesFormatted = (classVotes: string) => {
              if (classVotes === 'garantia-real') {
                return 'Garantia Real';
              } else if (classVotes === 'quirografario') {
                return 'Quirografário';
              } else if (classVotes === 'trabalhista') {
                return 'Trabalhista';
              }
              return 'Microempresa';
            };

            const indexFormatted = (index: number) => {
              /* eslint no-else-return: "error" */
              if (index === 0) {
                return 'I';
              } else if (index === 1) {
                return 'II';
              } else if (index === 2) {
                return 'III';
              }
              return 'IV';
            };

            const yesValidVotes = item.votos_validos.itens.find(
              (answer) => answer.resposta === 'Sim',
            );
            const noValidVotes = item.votos_validos.itens.find(
              (answer) => answer.resposta === 'Não',
            );
            const abstentionValidVotes = item.votos_validos.itens.find(
              (answer) => answer.resposta === 'Abstenção',
            );

            return (
              <div key={item.agc_pergunta_id}>
                <Stack
                  bg="white"
                  mt="20px"
                  p="20px"
                  boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
                  filter="drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.04))"
                  borderRadius="10px"
                  spacing="20px"
                >
                  <div className="flex flex-col gap-2">
                    <span className="font-medium">Pergunta</span>
                    <span className="text-lg">{item.descricao}</span>
                  </div>

                  <div className="border-gray-200 border-[0.5px] border-solid" />

                  <div className="flex flex-col md:flex-row flex-1 gap-12 px-4 py-2">
                    <ChartComponent
                      title="Total de Votos Cabeça"
                      total={String(item.votos_validos.total_quantidade)}
                      yesVotes={`${yesValidVotes?.quantidade ?? 0} voto(s)`}
                      yesVotesPercentage={
                        yesValidVotes?.percentual_quantidade ?? 0
                      }
                      noVotes={`${noValidVotes?.quantidade ?? 0} voto(s)`}
                      noVotesPercentage={
                        noValidVotes?.percentual_quantidade ?? 0
                      }
                      abstentionVotes={`${
                        abstentionValidVotes?.quantidade ?? 0
                      } voto(s)`}
                      abstentionVotesPercentage={
                        abstentionValidVotes?.percentual_quantidade ?? 0
                      }
                    />

                    <div className="hidden md:block border-gray-200 border-[0.5px] border-solid" />

                    <ChartComponent
                      title="Total de Votos Créditos"
                      total={toCurrency(item.votos_validos.total_valor ?? 0)}
                      yesVotes={toCurrency(yesValidVotes?.valor ?? 0)}
                      yesVotesPercentage={yesValidVotes?.percentual_valor ?? 0}
                      noVotes={toCurrency(noValidVotes?.valor ?? 0)}
                      noVotesPercentage={noValidVotes?.percentual_valor ?? 0}
                      abstentionVotes={toCurrency(
                        abstentionValidVotes?.valor ?? 0,
                      )}
                      abstentionVotesPercentage={
                        abstentionValidVotes?.percentual_valor ?? 0
                      }
                    />
                  </div>

                  <div
                    className="grid grid-cols-1 lg:grid-cols-2 gap-5"
                    // className={`grid grid-cols-${
                    //   item.resumo_classe.length > 1 ? 2 : 2
                    // } gap-5`}
                  >
                    {item.resumo_classe.map((itemClass, index) => {
                      const yesVotes = itemClass.itens.find(
                        (answer) => answer.resposta === 'Sim',
                      );
                      const noVotes = itemClass.itens.find(
                        (answer) => answer.resposta === 'Não',
                      );
                      const abstentionVotes = itemClass.itens.find(
                        (answer) => answer.resposta === 'Abstenção',
                      );

                      return (
                        <div>
                          <div className="px-4 py-2 border-[1px] border-gray-200 border-solid border-b-0 text-lg">
                            Classe {indexFormatted(index)} -{' '}
                            {classesFormatted(itemClass.classe)}
                          </div>
                          <div className="flex flex-1 flex-col md:flex-row gap-6 px-4 py-2 border-[1px] border-gray-200 border-solid">
                            <ChartComponent
                              title="Total de Votos Cabeça"
                              total={String(itemClass.total_quantidade ?? 0)}
                              yesVotes={`${yesVotes?.quantidade ?? 0} voto(s)`}
                              yesVotesPercentage={
                                yesVotes?.percentual_quantidade ?? 0
                              }
                              noVotes={`${noVotes?.quantidade ?? 0} voto(s)`}
                              noVotesPercentage={
                                noVotes?.percentual_quantidade ?? 0
                              }
                              abstentionVotes={`${
                                abstentionVotes?.quantidade ?? 0
                              } voto(s)`}
                              abstentionVotesPercentage={
                                abstentionVotes?.percentual_quantidade ?? 0
                              }
                            />

                            <div className="hidden md:block border-gray-200 border-[0.5px] border-solid" />

                            <ChartComponent
                              title="Total de Votos Créditos"
                              total={toCurrency(itemClass.total_valor ?? 0)}
                              yesVotes={toCurrency(yesVotes?.valor ?? 0)}
                              yesVotesPercentage={
                                yesVotes?.percentual_valor ?? 0
                              }
                              noVotes={toCurrency(noVotes?.valor ?? 0)}
                              noVotesPercentage={noVotes?.percentual_valor ?? 0}
                              abstentionVotes={toCurrency(
                                abstentionVotes?.valor ?? 0,
                              )}
                              abstentionVotesPercentage={
                                abstentionVotes?.percentual_valor ?? 0
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {item.votos_classe.length > 0 && (
                    <span className="text-lg">Detalhes</span>
                  )}

                  {item.votos_classe.map((votos, index) => (
                    <div>
                      <div className="bg-gray-200 rounded-t-lg p-2 text-lg">
                        Classe {indexFormatted(index)} -{' '}
                        {classesFormatted(votos.classe)}
                      </div>

                      <TableContainer>
                        <Table variant="simple">
                          <Thead className="bg-gray-200">
                            <Tr>
                              <Th>Nome</Th>
                              <Th>Procurador</Th>
                              <Th>Créditos</Th>
                              <Th>Voto</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {votos.itens.map((vote) => (
                              <Tr key={vote.credor} className="">
                                <Td>{vote.credor}</Td>
                                <Td>{vote.procurador}</Td>
                                <Td>{toCurrency(vote.valor)}</Td>
                                <Td>{vote.voto}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </div>
                  ))}
                </Stack>
              </div>
            );
          })}
    </div>
  );
};
