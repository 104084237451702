import { ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Step from '../../assets/images/stepAgc.png';
import StepTwo from '../../assets/images/stepAgcTwo.png';
import StepThree from '../../assets/images/stepsAgcThree.png';
import { FormOneAgc } from './steps/formOne';
import { FormTwoAgc } from './steps/formTwo';
import { FormThreeAgc } from './steps/formThree';

export const RegisterAGC: React.FC = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<any>({
    numero_processo: '',
    natureza: '',
    nome: '',
    observacao: '',
    data_credenciamento: '',
    hora_instalacao: '',
    convocacao: '',
  });

  const [classes, setClasses] = useState<string[]>([]);

  const handleSubmit = (data: any) => {
    const updatedFormData = { ...formData, ...data };
    setFormData(updatedFormData);
    setStep(step + 1);
  };

  const getClasses = (data: any) => {
    setClasses([...classes, ...data]);
    setStep(step + 1);
  };

  return (
    <Box p="33px 20px 0px 20px">
      <Breadcrumb
        spacing="7px"
        separator={<ChevronRightIcon w="22px" h="22px" color="#AAAAAA" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink
            as={Link}
            to="/agcs"
            color="#224141"
            fontFamily="Rubik"
            fontSize="16px"
            fontWeight="400"
          >
            AGC's
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink
            color="#848484"
            fontFamily="Rubik"
            fontSize="16px"
            fontWeight="400"
          >
            Criar nova AGC
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Box mt="21px">
        <Box
          boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
          filter="drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.10))"
          minH="80vh"
          borderRadius="30px"
          bg="#EEE"
          p="46px 50px 38px 64px"
        >
          <Flex align="center" justify="space-between">
            <Box>
              <Text
                color="#222"
                fontFamily="Rubik"
                fontSize="30px"
                fontWeight="400"
              >
                Criar nova AGC
              </Text>
              <Text
                color="#AAA"
                fontFamily="Rubik"
                fontSize="16px"
                fontWeight="400"
                letterSpacing="1.6px"
              >
                Roberto Carlos
              </Text>
            </Box>

            {step === 1 && <Image src={Step} />}
            {step === 2 && <Image src={StepTwo} />}
            {step === 3 && <Image src={StepThree} />}
          </Flex>

          {step === 1 && <FormOneAgc nextStep={handleSubmit} />}
          {step === 2 && (
            <FormTwoAgc
              handlePrevious={() => setStep(step - 1)}
              handleNext={getClasses}
            />
          )}
          {step === 3 && (
            <FormThreeAgc
              handlePrevious={() => setStep(step - 1)}
              formData={formData}
              formClasses={classes}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
