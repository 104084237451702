import {
  Box,
  CSSReset,
  Center,
  ChakraProvider,
  HStack,
  Image,
  Link,
  Stack,
  Text,
  theme,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { File } from '@phosphor-icons/react';
import { RiFolderWarningLine } from 'react-icons/ri';
import { api } from '../../../../services/api';
import Folder from '../../../../assets/icons/FolderNotchOpen.svg';
import { ModalToAdd } from './components/modal/toAdd';

interface Props {
  profile: string | undefined;
  id: string | undefined;
}

interface PropsDocs {
  tipo_documento: string;
  data_documento: string;
  arquivo_link: string;
}

export const Documents: React.FC<Props> = ({ profile, id }) => {
  const [docs, setDocs] = useState<PropsDocs[]>([]);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    getDocs();
  }, []);

  const getDocs = async () => {
    try {
      const response = await api.get(`/agc-documento/?agc_id=${id}`);
      const { results } = response.data;
      if (response.status === 200) {
        setDocs(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formattedData = (values: string) => {
    if (typeof values !== 'undefined') {
      const today = new Date(values);
      const month =
        today.getMonth() + 1 < 10
          ? `0${today.getMonth() + 1}`
          : today.getMonth() + 1;
      const day =
        today.getDate() < 10 ? `0${today.getDate()}` : today.getDate();
      const hours =
        today.getHours() < 10 ? `0${today.getHours()}` : today.getHours();
      const minutes =
        today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes();

      const dateNow = `${day}/${month}/${today.getFullYear()} às ${hours}:${minutes}`;

      return dateNow;
    }
    return '';
  };

  return (
    <Box
      bg="#FFF"
      borderRadius="31px"
      p="25px 21px 25px 25px"
      h="380px"
      w="100%"
    >
      <CSSReset />
      <ChakraProvider theme={theme}>
        <Stack
          spacing="22px"
          maxH={profile !== 'aj' ? '100%' : '280px'}
          overflowY="auto"
          overflowX="hidden"
          pr="10px"
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
              height: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
              borderRadius: 'full',
            },
            '&::-webkit-scrollbar-track': {
              width: '8px',
              bg: '#CCC',
              borderRadius: '10px',
            },
          }}
          h="100%"
        >
          <HStack spacing="33px" align="center">
            <Image src={Folder} />
            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              Documentos importantes
            </Text>
          </HStack>

          {docs.length === 0 ? (
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              h="60%"
            >
              <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
              <Text color="#222222" fontFamily="Rubik" fontWeight="500">
                Nenhum documento encontrado!
              </Text>
            </Box>
          ) : (
            docs.map((item) => (
              <Box borderRadius="12px" border="1px solid #E7E7E7" p="9px 10px">
                <Stack spacing="10px">
                  <HStack spacing="14px">
                    <Center
                      w="45px"
                      h="45px"
                      bg="#E9E9E9"
                      border="2px solid #D7D7D7"
                      borderRadius="100%"
                    >
                      <File size={16} weight="bold" />
                    </Center>
                    <Box maxW="100%">
                      <Text color="#515151" fontSize="16px" fontWeight="500">
                        {item.tipo_documento}
                      </Text>
                      <HStack spacing="20px">
                        <Link
                          letterSpacing="1.5px"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          maxW="400px"
                          href={item.arquivo_link}
                          color="#515151"
                          fontFamily="Rubik"
                          fontSize="12px"
                          target="_blank"
                        >
                          Download
                        </Link>
                      </HStack>
                    </Box>
                  </HStack>

                  <Text color="#A0A0A0" fontSize="14px" fontWeight="400">
                    {formattedData(item.data_documento)}
                  </Text>
                </Stack>
              </Box>
            ))
          )}
        </Stack>

        {profile === 'aj' && (
          <button
            style={{
              color: '#FFF',
              fontFamily: 'Rubik',
              fontSize: '12px',
              fontWeight: '500',
              background:
                'linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
              boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.10)',
              borderRadius: '19px',
              padding: '9px 17px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 22,
            }}
            onClick={() => setOpen(true)}
          >
            Adicionar documento
          </button>
        )}
      </ChakraProvider>

      <ModalToAdd
        isOpen={open}
        onClose={() => setOpen(false)}
        agcId={id}
        stateDocument={setDocs}
      />
    </Box>
  );
};
