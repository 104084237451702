import { Center, HStack, Stack, Text } from '@chakra-ui/react';
import { File } from '@phosphor-icons/react';
import React from 'react';

interface Props {
  name: string | undefined;
  type: string | undefined;
  data: string | undefined;
}

export const CardDoc: React.FC<Props> = ({ name, type, data }) => {
  return (
    <Stack
      padding="9px 10px"
      borderRadius="12px"
      border="1px solid #E7E7E7"
      bg="#FFF"
      w="100%"
      spacing="10px"
    >
      <HStack>
        <Center
          w="45px"
          h="45px"
          bg="#E9E9E9"
          border="2px solid #D7D7D7"
          borderRadius="100px"
        >
          <File size={22} weight="bold" color="white" />
        </Center>

        <Stack spacing="0px">
          <Text
            color="#515151"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="0.45px"
          >
            {name}
          </Text>
          <Text
            color="#515151"
            fontFamily="Rubik"
            fontSize="12px"
            fontWeight="400"
            lineHeight="28px"
            letterSpacing="1.5px"
          >
            {type}
          </Text>
        </Stack>
      </HStack>
      <Text color="#A0A0A0" fontSize="12px" fontWeight="400">
        {data}
      </Text>
    </Stack>
  );
};
