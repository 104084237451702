import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { differenceInMinutes } from 'date-fns';
import { RiFolderWarningLine } from 'react-icons/ri';
import Chats from '../../../../../assets/icons/chats.svg';
import User from '../../../../../assets/images/participant.png';
import { api } from '../../../../../services/api';

interface Props {
  id: string | undefined;
  idAgc: string | undefined;
}

interface PropsMessages {
  id: number;
  data_mensagem: string | undefined;
  mensagem: string | undefined;
  tipo_remetente: string | undefined;
  remetente_nome: string | undefined;
}

export const Comments: React.FC<Props> = ({ id, idAgc }) => {
  const [listMessages, setListMessages] = useState<PropsMessages[]>([]);

  const [message, setMessage] = useState<string>('');
  const [errorExist, setErrorExist] = useState<string | undefined>('');

  const [procuradorId, setProcuradorId] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    commentAttorney();
    dialogueMessages();
  }, [procuradorId]);

  const profileId = localStorage.getItem('userId');

  const commentAttorney = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_credor_id=${id}&agc_id=${idAgc}&usuario_id=${profileId}`,
      );
      if (response.status === 200 || response.status) {
        const { results } = response.data;
        setProcuradorId(results[0].id);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const dialogue = async () => {
    try {
      const dataMessage = {
        agc_credor_procurador_id: procuradorId,
        mensagem: message,
      };
      const response = await api.post(
        'agc-credor-procurador-dialogo/',
        dataMessage,
      );
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        setListMessages((prevMessage) => [...prevMessage, data]);
        setMessage('');
      }
    } catch (error: any) {
      const { status } = error.response;
      if (status === 404) {
        setErrorExist('Você não solicitou vinculo a este credor.');
      }
    }
  };

  const dialogueMessages = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `agc-credor-procurador-dialogo/?agc_credor_id=${id}&agc_credor_procurador_id=${procuradorId}`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setListMessages(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTimeSinceMessageSent = (dateString: string) => {
    const messageSentAt = new Date(dateString);
    const now = new Date();
    const minutesAgo = differenceInMinutes(now, messageSentAt);

    /* eslint no-else-return: "error" */
    if (minutesAgo < 1) {
      return 'Agora mesmo';
    } else if (minutesAgo === 1) {
      return '1min atrás';
    } else if (minutesAgo < 60) {
      return `${minutesAgo}min atrás`;
    } else if (minutesAgo < 120) {
      return '1 hora atrás';
    } else if (minutesAgo < 1440) {
      const hoursAgo = Math.floor(minutesAgo / 60);
      return `${hoursAgo} horas atrás`;
    }
    const daysAgo = Math.floor(minutesAgo / 1440);
    return `${daysAgo} dias atrás`;
  };

  return (
    <Box
      p="24px 16px"
      bg="#FFF"
      borderRadius="31px"
      h="481px"
      w="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Stack spacing="16px" h="100%">
        <HStack spacing="33px">
          <Image src={Chats} />
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Comentários
          </Text>
        </HStack>

        {loading && (
          <Flex w="100%" align="center" justify="center" h="100%">
            <Spinner size="xl" color="#DF8E25" />
          </Flex>
        )}

        {!loading && listMessages.length > 0 && (
          <Box
            h="100%"
            w="100%"
            maxH="322px"
            overflow="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '3px',
                marginLeft: '10px',
                height: '10px',
              },
              '&::-webkit-scrollbar-thumb': {
                bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
                borderRadius: 'full',
              },
              '&::-webkit-scrollbar-track': {
                width: '8px',
                bg: '#CCC',
                borderRadius: '10px',
              },
            }}
          >
            <Stack spacing="24px" p="8px">
              <Stack spacing="8px">
                {listMessages &&
                  listMessages.length > 0 &&
                  listMessages.map((item) => (
                    <Box>
                      {item.tipo_remetente === 'procurador-credor' ? (
                        <Stack
                          p="8px"
                          bg="linear-gradient(1deg, #DF8E25 4.08%, #DCAC36 95.92%, #EBD87F 100%)"
                          boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
                          borderRadius="10px 10px 0px 10px"
                          spacing="8px"
                          w="90%"
                        >
                          <Text
                            color="#FFF"
                            fontFamily="Rubik"
                            fontSize="14px"
                            fontWeight="400"
                            letterSpacing="0.64px"
                          >
                            {item.mensagem}
                          </Text>
                          <Text
                            color="#FFF"
                            fontFamily="Rubik"
                            fontSize="12px"
                            fontWeight="400"
                          >
                            {item.remetente_nome} -{' '}
                            {getTimeSinceMessageSent(item.data_mensagem!)}
                          </Text>
                        </Stack>
                      ) : (
                        <HStack spacing="8px" align="flex-end">
                          <Image src={User} w="20px" h="20px" />
                          <Stack
                            spacing="8px"
                            p="8px"
                            borderRadius="10px 10px 10px 0px"
                            border="1px solid #DEDEDE"
                            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
                          >
                            <Text
                              color="#000"
                              fontFamily="Rubik"
                              fontSize="14px"
                              fontWeight="400"
                              letterSpacing="0.64"
                            >
                              {item.mensagem}
                            </Text>
                            <Text
                              color="#888888"
                              fontFamily="Rubik"
                              fontSize="12px"
                              fontWeight="400"
                            >
                              {item.remetente_nome} -{' '}
                              {getTimeSinceMessageSent(item.data_mensagem!)}
                            </Text>
                          </Stack>
                        </HStack>
                      )}
                    </Box>
                  ))}
              </Stack>
            </Stack>
          </Box>
        )}

        {!loading && listMessages.length === 0 && (
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="100%"
          >
            <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
            <Text fontWeight="500">Nenhuma mensagem encontrada!</Text>
          </Box>
        )}
      </Stack>

      <Stack
        display="flex"
        flexDir="column"
        justify="flex-end"
        h="100%"
        spacing="19px"
        mt="auto"
      >
        <Box h="1px" w="100%" bg="#CCC" />

        <HStack spacing="10px">
          <Input
            placeholder="Escreva um comentário"
            color="#888"
            fontFamily="Rubik"
            fontSize="16px"
            fontWeight="400"
            letterSpacing="0.8px"
            border="0px"
            boxShadow="none"
            w="100%"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            p="10px"
          />
          <HStack spacing="8px">
            <IconButton
              variant="solid"
              aria-label="Send"
              fontSize="20px"
              h="44px"
              w="44px"
              icon={<FiSend color="white" />}
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              onClick={dialogue}
              isDisabled={!message}
            />
          </HStack>
        </HStack>
        {errorExist && (
          <Text fontSize={12} fontWeight="500" color="red.400">
            {errorExist}
          </Text>
        )}
      </Stack>
    </Box>
  );
};
