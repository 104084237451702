import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Image,
  Link,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { File } from '@phosphor-icons/react';
import { RiFolderWarningLine } from 'react-icons/ri';
import Folder from '../../../../../assets/icons/FolderNotchOpen.svg';
import { api } from '../../../../../services/api';
import { DrawerAddDocs } from '../docsSent/components/drawer';

interface Props {
  id: string | undefined;
  idAgc: string | undefined;
}

interface PropsDocs {
  tipo_documento: string | undefined;
  data_documento: string | undefined;
  arquivo_link: string | undefined;
}

export const DocsSentAttorney: React.FC<Props> = ({ id, idAgc }) => {
  const [docs, setDocs] = useState<PropsDocs[]>([]);

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [profileUser, setProfileUser] = useState<string | undefined>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [creditor, setCreditor] = useState<boolean>(false);

  const [idAttorney, setIdAttorney] = useState<number>(0);

  const profileId = localStorage.getItem('userId');

  useEffect(() => {
    getCreditorsAttorneys();
    typeProfile();
  }, []);

  const getCreditorsAttorneys = async () => {
    try {
      const response = await api.get(
        `agc-credor-procurador/?agc_credor_id=${id}&agc_id=${idAgc}&usuario_id=${profileId}`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        if (results.length > 0) {
          setCreditor(true);
        }
        setIdAttorney(results[0].id);
        const responseDocs = await api.get(
          `agc-credor-procurador-documento/?agc_credor_id=${id}&agc_credor_procurador_id=${results[0].id}`,
        );
        if (responseDocs.status === 200 || responseDocs.status === 201) {
          setDocs(responseDocs.data.results);
          setLoading(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const typeProfile = async () => {
    try {
      const response = await api.get(`/agc/${idAgc}/perfil-usuario-logado/`);
      if (response.status === 200 || response.status === 201) {
        const { perfil } = response.data;
        setProfileUser(perfil);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p="24px 16px" bg="#FFF" borderRadius="31px" h="481px" w="100%">
      <Stack
        spacing="16px"
        maxH="100%"
        overflowY="auto"
        overflowX="hidden"
        pr="10px"
        sx={{
          '&::-webkit-scrollbar': {
            width: '3px',
            marginLeft: '10px',
            height: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
            borderRadius: 'full',
          },
          '&::-webkit-scrollbar-track': {
            width: '8px',
            bg: '#CCC',
            borderRadius: '10px',
          },
        }}
        h="90%"
      >
        <HStack spacing="33px">
          <Image src={Folder} />
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Documentos enviados
          </Text>
        </HStack>

        {loading && (
          <Flex justify="center" pt="20px" align="center" h="60%">
            <Spinner size="xl" color="#DF8E25" />
          </Flex>
        )}

        {!loading && docs?.length === 0 && (
          <Box
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            h="60%"
          >
            <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
            <Text color="#222222" fontFamily="Rubik" fontWeight="500">
              Nenhum documento encontrado!
            </Text>
          </Box>
        )}

        {!loading &&
          docs &&
          docs.length > 0 &&
          docs.map((item) => {
            const formattedData = () => {
              if (item.data_documento) {
                const today = new Date(item.data_documento);
                const month =
                  today.getMonth() + 1 < 10
                    ? `0${today.getMonth() + 1}`
                    : today.getMonth() + 1;
                const day =
                  today.getDate() < 10
                    ? `0${today.getDate()}`
                    : today.getDate();
                const hours =
                  today.getHours() < 10
                    ? `0${today.getHours()}`
                    : today.getHours();
                const minutes =
                  today.getMinutes() < 10
                    ? `0${today.getMinutes()}`
                    : today.getMinutes();

                const dateNow = `${day}/${month}/${today.getFullYear()} - ${hours}:${minutes} AM`;

                return dateNow;
              }

              return '';
            };
            return (
              <Stack
                spacing="10px"
                p="9px 10px"
                borderRadius="12px"
                border="1px solid #E7E7E7"
              >
                <Stack spacing="10px">
                  <HStack align="center">
                    <Center
                      w="45px"
                      h="45px"
                      bg="#E9E9E9"
                      border="2px solid #D7D7D7"
                      borderRadius="100px"
                    >
                      <File size={22} weight="bold" color="white" />
                    </Center>
                    <Box>
                      <Text
                        color="#515151"
                        fontFamily="Rubik"
                        fontSize="18px"
                        fontWeight="400"
                        letterSpacing="0.45px"
                        lineHeight="26px"
                      >
                        {item.tipo_documento}
                      </Text>
                      <HStack>
                        <Link
                          letterSpacing="1.5px"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          maxW="400px"
                          href={item.arquivo_link}
                          color="#515151"
                          fontFamily="Rubik"
                          fontSize="12px"
                          target="_blank"
                        >
                          Download
                        </Link>
                      </HStack>
                    </Box>
                  </HStack>

                  <Text color="#A0A0A0" fontSize="12px" fontWeight="400">
                    {formattedData()}
                  </Text>
                </Stack>
              </Stack>
            );
          })}
      </Stack>

      {creditor && (
        <Button
          borderRadius="19px"
          bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
          boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
          color="#FFF"
          fontFamily="Rubik"
          fontSize="12px"
          fontWeight="500"
          w="158px"
          h="32px"
          onClick={() => setOpen(true)}
        >
          Adicionar documento
        </Button>
      )}
      <DrawerAddDocs
        isOpen={open}
        onClose={() => setOpen(false)}
        id={idAttorney}
        arrayDocs={setDocs}
      />
    </Box>
  );
};
