import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { io } from 'socket.io-client';
import { Participant } from '@zoom/videosdk';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { useVideoActions } from '../context/video-actions.context';
import { ISuspensao, useSuspenderAGC } from './useSuspender';
import { useStream } from '../context/stream.context';

interface IContext {
  dispararPerguntas: (_: number | undefined) => void;
  requestMicrophone: (_: Participant) => void;
  requestedMicrophones: Participant[];
  removeRequestedMicrophone: (_: Participant) => void;
  iniciarPerguntas: { id: number };
  deslogarTodos: () => void;
  setIniciarPerguntas: (_: any) => void;
  suspenderAgc: (_: number) => void;
  suspensao: ISuspensao[];
}

const WebSocketContext = createContext<IContext>(null as any as IContext);

const socket = io('wss://socket.e-agc.com.br');

export const WebsocketProvider: FC<any> = ({ children, callId }) => {
  const push = useNavigate();
  const toast = useToast();
  const {
    getSuspensao,
    suspenderAgc: callApiSuspender,
    suspensao,
  } = useSuspenderAGC();

  const { client } = useStream();

  const {
    setRequestedMicrophone,
    requestedMicrophones,
    setRequestMicrophones,
    removeRequestedMicrophone,
  } = useVideoActions();

  const [iniciarPerguntas, setIniciarPerguntas] = useState({
    id: undefined,
  });

  useEffect(() => {
    getSuspensao(callId);
    socket?.on('connect', () => {
      console.log('websocket agc connected ', socket.connected);
    });

    socket?.on(`suspenderAgc:${callId}`, () => {
      getSuspensao(callId);
    });

    socket?.on(`deslogarTodoMundo:${callId}`, () => {
      client?.leave(true).then(() => {
        toast({
          title: 'Sessão encerrada',
          description: 'A sessão foi encerrada',
          status: 'warning',
          duration: 9000,
          isClosable: true,
        });
        push('/agcs');
      });
    });

    socket?.on(`dispararPerguntas:${callId}`, (valor: any) => {
      setIniciarPerguntas(valor || false);
    });

    socket?.on(`requestMicrophone:${callId}`, (participant: Participant) => {
      if (participant?.userId) {
        setRequestMicrophones((old) => {
          const filtered = old.filter(
            (item) => item.userId !== participant.userId,
          );
          return [...filtered, participant];
        });
      }
    });

    socket?.on('disconnect', () => {
      console.log('websocket agc disconnected ', !socket.connected);
    });
  }, [callId]);

  const requestMicrophone = (participant: Participant) => {
    setRequestedMicrophone(true);
    socket?.emit('requestMicrophone', {
      id: callId,
      payload: participant,
    });
  };

  const dispararPerguntas = async (id: number) => {
    socket?.emit('dispararPerguntas', {
      id: callId,
      payload: {
        id,
      },
    });
  };

  const deslogarTodos = async () => {
    socket?.emit('deslogarTodoMundo', {
      id: callId,
      payload: {},
    });
  };

  const suspenderAgc = async (data: any) => {
    callApiSuspender({ ...data, agc_id: callId });
    socket?.emit('suspenderAgc', {
      id: callId,
      payload: data,
    });
  };

  return (
    <WebSocketContext.Provider
      value={
        {
          dispararPerguntas,
          requestMicrophone,
          requestedMicrophones,
          removeRequestedMicrophone,
          iniciarPerguntas,
          deslogarTodos,
          setIniciarPerguntas,
          suspenderAgc,
          suspensao,
        } as any as IContext
      }
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebsocket = () => {
  const context = useContext<IContext>(WebSocketContext);

  if (!context) {
    throw new Error('useWebsocket must be used within an WebsocketProvider');
  }

  return context;
};
