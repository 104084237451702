/* eslint-disable no-unused-vars */
import { Button, Modal, Input, Form } from 'antd';
import classNames from 'classnames';
import React from 'react';
import { MdOutlineLiveTv } from 'react-icons/md';

interface LiveStreamButtonProps {
  onLiveStreamClick: () => void;
  isLiveStreamOn: boolean;
}
interface LiveStreamModalProps {
  visible: boolean;
  onStartLiveStream: (
    streamUrl: string,
    streamKey: string,
    broadcastUrl: string,
  ) => void;
  setVisible: (visible: boolean) => void;
}
const LiveStreamButton = (props: LiveStreamButtonProps) => {
  const { onLiveStreamClick, isLiveStreamOn } = props;
  return (
    <Button
      className={classNames('vc-button', {
        active: isLiveStreamOn,
      })}
      icon={<MdOutlineLiveTv />}
      ghost
      shape="circle"
      size="large"
      onClick={onLiveStreamClick}
    />
  );
};

const LiveStreamModal = (props: LiveStreamModalProps) => {
  const { visible, onStartLiveStream, setVisible } = props;
  const [form] = Form.useForm();
  return (
    <Modal
      open={visible}
      className="live-stream-setting-dialog"
      title="Configuração de transmissão ao vivo"
      okText="Salvar"
      cancelText="Cancelar"
      onOk={async () => {
        try {
          const data = await form.validateFields();
          const { streamUrl, streamKey, broadcastUrl } = data;
          onStartLiveStream(streamUrl, streamKey, broadcastUrl);
          setVisible(false);
        } catch (e) {
          console.log(e);
        }
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose
    >
      <Form form={form} name="live-stream-form">
        <Form.Item
          label="URL de transmissão"
          name="streamUrl"
          required
          rules={[
            { required: true, message: 'O URL da transmissão é obrigatório' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Chave de transmissão"
          name="streamKey"
          required
          rules={[
            { required: true, message: 'A chave da transmissão é obrigatória' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="URL de Broadcast"
          name="broadcastUrl"
          required
          rules={[
            { required: true, message: 'O URL de Broadcast é obrigatório' },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { LiveStreamButton, LiveStreamModal };
