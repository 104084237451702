import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { AiFillWarning, AiOutlineClose } from 'react-icons/ai';
import { FaTrash } from 'react-icons/fa';
import { api } from '../../../../../services/api';

interface Props {
  name: string | undefined;
  id: number | undefined;
  callback: () => void;
}

export const ModalDelete: React.FC<Props> = ({ name, id, callback }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const deleteMember = async () => {
    try {
      await api.delete(`agc-credor/${id}`);
      callback();
      onClose();
      toast({
        title: 'Credor removido!',
        description: 'Credor removido da AGC com sucesso!',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error: any) {
      console.error(error);
      onClose();
      toast({
        title: 'Erro ao remover credor!',
        description:
          error.response.data?.detail ??
          'Ocorreu um erro inesperado. Tente novamente mais tarde!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <button onClick={onOpen}>
        <FaTrash size={20} color="#D89320" />
      </button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody textAlign="center" p="20px">
            <Stack spacing="20px">
              <Stack display="flex" flexDir="column" alignItems="center">
                <AiFillWarning size={60} color="#D89320" />
                <Text fontSize="18px" fontFamily="Rubik">
                  Tem certeza que deseja remover o credor{' '}
                  <strong>{name}</strong>?
                </Text>
              </Stack>

              <HStack spacing="20px" justify="flex-end">
                <Button
                  bg="transparent"
                  border="2px solid #EbD87F"
                  onClick={onClose}
                  leftIcon={<AiOutlineClose />}
                >
                  Cancelar
                </Button>
                <Button
                  bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                  color="white"
                  fontFamily="Rubik"
                  leftIcon={<FaTrash />}
                  onClick={deleteMember}
                >
                  Confirmar
                </Button>
              </HStack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
