import { ComponentStyleConfig } from '@chakra-ui/react';

export const inputStyle: ComponentStyleConfig = {
  parts: ['field'],
  baseStyle: {
    field: {
      outline: 0,
      fontSize: '16px',
      paddingY: '17px',
      paddingX: '30px',
      borderRadius: '43px',
      boxShadow: '0px 15px 24px 0px rgba(0, 0, 0, 0.05)',
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'solid',
  },
};
