import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@chakra-ui/react';
import Header from '../Header';

const Layout: React.FC = () => {
  return (
    <div style={{ minHeight: '100vh', background: '#EEE' }}>
      <Header />

      <Container maxW="1300px">
        <Outlet />
      </Container>
    </div>
  );
};

export default Layout;
