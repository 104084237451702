import {
  Button,
  Center,
  Container,
  Divider,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { useAuth } from '../../context/AuthContext';
import Agc from '../../assets/icons/iconAgc.svg';
import Notification from '../../assets/icons/notification.svg';
import { api } from '../../services/api';

const HeaderButton: React.FC<any> = ({ name, path, leftIcon, rightIcon }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isActive = pathname.includes(`${path}`);

  return (
    <Button
      fontSize="16px"
      fontWeight="normal"
      color="#222"
      variant="solid"
      paddingX="35px"
      borderRadius="31px"
      transition="0.3s ease"
      onClick={() => navigate(path)}
      backgroundColor={isActive ? '#FFFFFF' : 'transparent'}
      boxShadow={isActive ? '0px 15px 30px 0px rgba(0, 0, 0, 0.10)' : ''}
      leftIcon={<Image src={leftIcon} />}
      rightIcon={rightIcon}
      h="53px"
    >
      {name}
    </Button>
  );
};

const Header: React.FC = () => {
  const { logout } = useAuth();

  const navigate = useNavigate();

  const [quantityMessages, setQuantityMessages] = useState<number>(0);

  const jsonValue = localStorage.getItem('user');
  let parsedValue = {
    nome: '',
    sobrenome: '',
  };
  if (jsonValue !== null) {
    parsedValue = JSON.parse(jsonValue);
  }

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    try {
      const response = await api.get(
        'agc-credor-procurador-dialogo/listar-dialogos/',
      );
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        setQuantityMessages(data.length);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header>
      <nav>
        <Flex
          backgroundColor="#F2F2F2"
          boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
          filter="drop-shadow(0px 15px 30px rgba(0, 0, 0, 0.04))"
        >
          <Container maxW="1300px">
            <Flex
              alignItems="center"
              justifyContent="space-between"
              paddingY={15}
              paddingX={20}
            >
              <Image
                src={logo}
                w="53px"
                h="53px"
                onClick={() => {
                  window.location.href = '/agcs';
                }}
                cursor="pointer"
              />

              <Flex>
                <HeaderButton name="AGC's" path="agcs" leftIcon={Agc} />
                <HeaderButton
                  name="Notificações"
                  path="messages"
                  leftIcon={Notification}
                  rightIcon={
                    <Center
                      w="20px"
                      h="20px"
                      bg="rgba(34, 34, 34, 0.10)"
                      borderRadius="100px"
                      color="#888"
                      fontSize="12px"
                    >
                      <Text mt="2px">{quantityMessages}</Text>
                    </Center>
                  }
                />
              </Flex>

              <Menu>
                <MenuButton
                  as={Button}
                  leftIcon={
                    <Text mt="1px">
                      {parsedValue.nome[0]}
                      {parsedValue.sobrenome[0]}
                    </Text>
                  }
                  rightIcon={<ChevronDownIcon />}
                  variant="solid"
                  padding="11px"
                  borderRadius="31px"
                  backgroundColor="#FFFFFF"
                  boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10)"
                >
                  <Text
                    fontWeight="400"
                    fontSize="16px"
                    color="#222222"
                  >{`${parsedValue.nome} ${parsedValue.sobrenome}`}</Text>
                </MenuButton>
                <MenuList background="#FFFFFF" zIndex={1000} pos="absolute">
                  <MenuItem onClick={() => navigate('/profile')} zIndex={1000}>
                    Perfil
                  </MenuItem>
                  <MenuItem onClick={() => navigate('/ajs')} zIndex={1000}>
                    AJ's
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={logout} py="8px" zIndex={1000}>
                    Sair
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Container>
        </Flex>
      </nav>
    </header>
  );
};

export default Header;
