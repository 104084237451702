import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiSend } from 'react-icons/fi';
import { differenceInMinutes } from 'date-fns';
import { RiFolderWarningLine } from 'react-icons/ri';
import Chats from '../../../../../assets/icons/chats.svg';
import User from '../../../../../assets/images/participant.png';
import { api } from '../../../../../services/api';

interface Props {
  id: string | undefined;
  selectedAttorney: any;
}

interface PropsMessages {
  id: number;
  agc_credor_procurador_id: number;
  remetente_id: number;
  tipo_remetente: string | undefined;
  data_mensagem: string | undefined;
  mensagem: string | undefined;
  remetente_nome: string | undefined;
}

export const CardComments: React.FC<Props> = ({ id, selectedAttorney }) => {
  const [messages, setMessages] = useState<PropsMessages[]>([]);

  const [message, setMessage] = useState<string | undefined>('');

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedAttorney) getMessages();
  }, [selectedAttorney]);

  const getMessages = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        `agc-credor-procurador-dialogo/?agc_credor_id=${id}&agc_credor_procurador_id=${selectedAttorney.id}`,
      );
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setMessages(results);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const getTimeSinceMessageSent = (dateString: string) => {
    const messageSentAt = new Date(dateString);
    const now = new Date();
    const minutesAgo = differenceInMinutes(now, messageSentAt);

    /* eslint no-else-return: "error" */
    if (minutesAgo < 1) {
      return 'Agora mesmo';
    } else if (minutesAgo === 1) {
      return '1min atrás';
    } else if (minutesAgo < 60) {
      return `${minutesAgo}min atrás`;
    } else if (minutesAgo < 120) {
      return '1 hora atrás';
    } else if (minutesAgo < 1440) {
      const hoursAgo = Math.floor(minutesAgo / 60);
      return `${hoursAgo} horas atrás`;
    }
    const daysAgo = Math.floor(minutesAgo / 1440);
    return `${daysAgo} dias atrás`;
  };

  const dialogue = async () => {
    try {
      const dataMessage = {
        agc_credor_procurador_id: selectedAttorney?.id,
        mensagem: message,
      };
      const response = await api.post(
        'agc-credor-procurador-dialogo/',
        dataMessage,
      );
      if (response.status === 200 || response.status === 201) {
        const { data } = response;
        setMessages((prevMessage) => [...prevMessage, data]);
        setMessage('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      p="24px 16px"
      bg="#FFF"
      borderRadius="31px"
      h="481px"
      w="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <Stack spacing="16px" h="100%">
        <HStack spacing="33px">
          <Image src={Chats} />
          <Text
            color="#222"
            fontFamily="Rubik"
            fontSize="18px"
            fontWeight="400"
            letterSpacing="1.8px"
          >
            Comentários
          </Text>
        </HStack>

        <Box
          h="100%"
          w="100%"
          maxH={selectedAttorney?.id ? '322px' : '100%'}
          overflow="auto"
          sx={{
            '&::-webkit-scrollbar': {
              width: '3px',
              marginLeft: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              bg: 'linear-gradient(90deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)',
              borderRadius: 'full',
            },
            '&::-webkit-scrollbar-track': {
              width: '8px',
              bg: '#CCC',
              borderRadius: '10px',
            },
          }}
        >
          {/* <Select
            size="lg"
            value={selectedAttorney.id}
            disabled
            borderRadius="43px"
            border="1px solid #DF8E25"
            iconColor="#8A8A8A"
            color="#222"
            _focus={{ border: '1px solid #DF8E25' }}
            _focusVisible={{ border: '1px solid #DF8E25' }}
            _hover={{ border: '1px solid #DF8E25' }}
            w="90%"
            onChange={(e) => setIdAttorney(e.target.value)}
            placeholder="Selecione um procurador"
          >
            {attorneys &&
              attorneys.length > 0 &&
              attorneys.map((item) => (
                <option value={item.id}>{item.procurador_nome}</option>
              ))}
          </Select> */}

          {loading && (
            <Flex justify="center" pt="20px" align="center" h="60%">
              <Spinner size="xl" color="#DF8E25" />
            </Flex>
          )}

          {!loading && messages.length === 0 && (
            <Box
              display="flex"
              flexDir="column"
              alignItems="center"
              justifyContent="center"
              h="60%"
            >
              <RiFolderWarningLine size={50} style={{ color: '#222222' }} />
              <Text color="#222222" fontFamily="Rubik" fontWeight="500">
                Nenhuma mensagem encontrada!
              </Text>
            </Box>
          )}

          {!loading && (
            <Stack spacing="24px" p="8px">
              <Stack spacing="8px">
                {messages &&
                  messages.length > 0 &&
                  messages.map((item) => (
                    <Box>
                      {item.tipo_remetente === 'aj' ? (
                        <Stack
                          p="8px"
                          bg="linear-gradient(1deg, #DF8E25 4.08%, #DCAC36 95.92%, #EBD87F 100%)"
                          boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
                          borderRadius="10px 10px 0px 10px"
                          spacing="8px"
                          w="90%"
                        >
                          <Text
                            color="#FFF"
                            fontFamily="Rubik"
                            fontSize="14px"
                            fontWeight="400"
                            letterSpacing="0.64px"
                          >
                            {item.mensagem}
                          </Text>
                          <Text
                            color="#FFF"
                            fontFamily="Rubik"
                            fontSize="12px"
                            fontWeight="400"
                          >
                            {item.remetente_nome} -{' '}
                            {item.data_mensagem
                              ? getTimeSinceMessageSent(item.data_mensagem)
                              : null}
                          </Text>
                        </Stack>
                      ) : (
                        <HStack spacing="8px" align="flex-end">
                          <Image src={User} w="20px" h="20px" />
                          <Stack
                            spacing="8px"
                            p="8px"
                            borderRadius="10px 10px 10px 0px"
                            border="1px solid #DEDEDE"
                            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
                          >
                            <Text
                              color="#000"
                              fontFamily="Rubik"
                              fontSize="14px"
                              fontWeight="400"
                              letterSpacing="0.64"
                            >
                              {item.mensagem}
                            </Text>
                            <Text
                              color="#888888"
                              fontFamily="Rubik"
                              fontSize="12px"
                              fontWeight="400"
                            >
                              {item.remetente_nome} -{' '}
                              {item.data_mensagem
                                ? getTimeSinceMessageSent(item.data_mensagem)
                                : null}
                            </Text>
                          </Stack>
                        </HStack>
                      )}
                    </Box>
                  ))}
              </Stack>
            </Stack>
          )}
        </Box>
      </Stack>

      {selectedAttorney?.id !== undefined && (
        <Stack
          display="flex"
          flexDir="column"
          justify="flex-end"
          h="100%"
          spacing="19px"
          mt="auto"
        >
          <Box h="1px" w="100%" bg="#CCC" />

          <HStack spacing="10px">
            <Input
              placeholder="Escreva um comentário"
              color="#888"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="0.8px"
              border="0px"
              boxShadow="none"
              p="10px"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <IconButton
              variant="solid"
              aria-label="Send"
              fontSize="20px"
              h="44px"
              w="44px"
              icon={<FiSend color="white" />}
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              onClick={dialogue}
              isDisabled={!message}
            />
          </HStack>
        </Stack>
      )}
    </Box>
  );
};
