import React from 'react';
import { Button, Tooltip, Dropdown } from 'antd';
import classNames from 'classnames';
import {
  LockOutlined,
  UnlockOutlined,
  UpOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { SharePrivilege } from '@zoom/videosdk';
import { LuScreenShare } from 'react-icons/lu';
import { getAntdDropdownMenu, getAntdItem } from './video-footer-utils';

const { Button: DropdownButton } = Dropdown;
interface ScreenShareButtonProps {
  sharePrivilege: SharePrivilege;
  isHostOrManager: boolean;
  onSharePrivilegeClick?: (_privilege: SharePrivilege) => void;
  onScreenShareClick: () => void;
}

interface ScreenShareLockButtonProps {
  isLockedScreenShare: boolean;
  onScreenShareLockClick: () => void;
}

const ScreenShareButton = (props: ScreenShareButtonProps) => {
  const {
    sharePrivilege,
    isHostOrManager,
    onScreenShareClick,
    onSharePrivilegeClick,
  } = props;
  const menu = [
    getAntdItem(
      'Bloquear compartilhamento',
      `${SharePrivilege.Locked}`,
      sharePrivilege === SharePrivilege.Locked && <CheckOutlined />,
    ),
    getAntdItem(
      'Um participante pode compartilhar por vez',
      `${SharePrivilege.Unlocked}`,
      sharePrivilege === SharePrivilege.Unlocked && <CheckOutlined />,
    ),
    getAntdItem(
      'Vários participantes podem compartilhar simultaneamente',
      `${SharePrivilege.MultipleShare}`,
      sharePrivilege === SharePrivilege.MultipleShare && <CheckOutlined />,
    ),
  ];
  const onMenuItemClick = (payload: { key: any }) => {
    onSharePrivilegeClick?.(Number(payload.key));
  };
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isHostOrManager ? (
        <DropdownButton
          className="vc-dropdown-button"
          size="large"
          menu={getAntdDropdownMenu(menu, onMenuItemClick)}
          onClick={onScreenShareClick}
          trigger={['click']}
          type="ghost"
          icon={<UpOutlined />}
          placement="topRight"
        >
          <LuScreenShare />
        </DropdownButton>
      ) : (
        <Button
          className={classNames('screen-share-button', 'vc-button')}
          icon={<LuScreenShare />}
          ghost
          shape="circle"
          size="large"
          onClick={onScreenShareClick}
        />
      )}
    </>
  );
};

const ScreenShareLockButton = (props: ScreenShareLockButtonProps) => {
  const { isLockedScreenShare, onScreenShareLockClick } = props;
  return (
    <Tooltip
      title={
        isLockedScreenShare
          ? 'desbloquear compartilhamento de tela'
          : 'bloquear compartilhamento de tela'
      }
    >
      <Button
        className="screen-share-button"
        icon={isLockedScreenShare ? <LockOutlined /> : <UnlockOutlined />}
        // eslint-disable-next-line react/jsx-boolean-value
        ghost={true}
        shape="circle"
        size="large"
        onClick={onScreenShareLockClick}
      />
    </Tooltip>
  );
};

export { ScreenShareButton, ScreenShareLockButton };
