import { useState } from 'react';
import { api } from '../../../services/api';

export const usePerguntas = (agcId: number) => {
  const [loading, setLoading] = useState(false);
  const [perguntas, setPerguntas] = useState<RetornoPergunta[]>([]);
  const [totalVotos, setTotalVotos] = useState(0);
  const [acompanhamento, setAcompanhamento] = useState<IAcompanhamento[]>();
  const [respostas, setRespostas] = useState<IRespostas[]>([]);

  const [acompanhamentoTotal, setAcompanhamentoTotal] =
    useState<Acompanhamento>();

  const votarPergunta = async (
    idPergunta: number,
    idResposta: number,
    credorId: number,
  ) => {
    try {
      await api.post('agc-pergunta-votacao-resposta/', {
        agc_pergunta_id: idPergunta,
        agc_pergunta_resposta_id: idResposta,
        agc_credor_procurador_id: credorId,
        observacao: '',
      });
    } catch (error) {
      throw new Error('Erro ao votar na pergunta');
    }
  };

  const getAcompanhamento = async () => {
    try {
      const res = await api.get<IAcompanhamento[]>(
        `/agc-pergunta/${agcId}/acompanhamento/`,
        { params: { agc_pergunta_id: agcId } },
      );
      setAcompanhamento(res.data ?? []);
    } catch (error) {
      //
    }
  };

  const getAcompanhamentoTotalizar = async () => {
    try {
      const res = await api.get<Acompanhamento>(
        `/agc-pergunta/${agcId}/acompanhamento-totalizar/`,
      );
      setAcompanhamentoTotal(res.data);
    } catch (error) {
      //
    }
  };

  const getTotalVotos = async () => {
    try {
      const res = await api.get<{ quantidade: number }>(
        `/agc-pergunta/${agcId}/total-votacao/`,
        { params: { id: agcId } },
      );
      setTotalVotos(res.data?.quantidade ?? 0);
    } catch (error) {
      //
    }
  };

  const getPerguntas = async () => {
    try {
      setLoading(true);
      const res = await api.get<{ results: RetornoPergunta[] }>(
        '/agc-pergunta/',
        { params: { agc_id: agcId } },
      );
      setPerguntas(res.data?.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const criarPergunta = async (
    data: CreatePergunta,
    classes: CriarClassePergunta[],
  ) => {
    try {
      setLoading(true);
      const res = await api.post<RetornoPergunta>('/agc-pergunta/', {
        ...data,
        agc_id: agcId,
      });

      await Promise.allSettled(
        data.respostas.map((resposta) =>
          api.post('/agc-pergunta-resposta/', {
            ...resposta,
            agc_pergunta_id: res.data.id,
          }),
        ),
      );

      await Promise.allSettled(
        classes.map((classe) =>
          api.post('/agc-pergunta-classe/', {
            ...classe,
            agc_pergunta_id: res.data.id,
          }),
        ),
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getRespostasDasPerguntas = async (idPergunta: number) => {
    try {
      const res = await api.get<ResultPerguntasRespostas>(
        'agc-pergunta-resposta/',
        { params: { agc_pergunta_id: idPergunta } },
      );
      setRespostas(res.data.results ?? []);
    } catch (error) {
      //
    }
  };

  return {
    getRespostasDasPerguntas,
    respostas,
    loading,
    criarPergunta,
    votarPergunta,
    getPerguntas,
    perguntas,
    totalVotos,
    getTotalVotos,
    acompanhamento,
    getAcompanhamentoTotalizar,
    acompanhamentoTotal,
    getAcompanhamento,
  };
};

export interface Resposta {
  resposta: string;
  cor_resposta: string;
}

export interface Totalizador {
  classe: string;
  resposta: string;
  quantidade: number;
  abstencao: boolean;
}

export interface Acompanhamento {
  classes: string[];
  respostas: Resposta[];
  totalizador: Totalizador[];
}

export interface RetornoPergunta {
  id: number;
  agc_id: number;
  descricao: string;
  status_pergunta: string;
}

export interface CreatePergunta {
  agc_id: number;
  descricao: string;
  respostas: PerguntaResposta[];
}

export interface PerguntaResposta {
  agc_pergunta_id?: number;
  resposta?: string;
  cor_resposta?: string;
  abstencao?: boolean;
}

export interface CriarClassePergunta {
  agc_pergunta_id?: number;
  agc_classe_id: number;
  cabeca?: boolean;
  credito?: boolean;
}

export interface IAcompanhamento {
  credor: string;
  classe: string;
  resposta: string;
  valor: number;
  data_votacao_resposta: string;
}

export interface IResponderPergunta {
  credorId: number;
  agc_pergunta_id?: number;
  resposta: string;
  cor_resposta: string;
  abstencao: boolean;
}

export interface IRespostas {
  id?: number;
  agc_pergunta_id?: number;
  resposta?: string;
  cor_resposta?: string;
  abstencao?: boolean;
}

export interface ResultPerguntasRespostas {
  count: number;
  next: string;
  previous: string;
  results: IRespostas[];
}
