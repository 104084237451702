/* eslint-disable react/jsx-no-useless-fragment */
import {
  Icon,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  Input as ChakraInput,
  InputProps as IProps,
  Spinner,
  Box,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { Eye, EyeSlash } from '@phosphor-icons/react';

interface InputProps extends Omit<IProps, 'onChange'> {
  name: string;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  type?: string;
  isLoading?: boolean;
  mask?: string;
  label?: string;
  buttonRight?: React.ReactNode;
  positionBox?:
    | '-webkit-sticky'
    | 'absolute'
    | 'fixed'
    | 'relative'
    | 'static'
    | 'sticky';
  // eslint-disable-next-line no-unused-vars
  onChange?: (e?: number | string) => void;
}

const InputComponent: React.FC<InputProps> = ({
  name,
  leftIcon,
  rightIcon,
  type = 'text',
  isLoading,
  mask,
  onChange,
  label,
  positionBox,
  buttonRight,
  ...rest
}) => {
  const [show, setShow] = useState(false);
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();

  const error = _.get(errors, name);

  return (
    <Box w={rest?.w} flex={rest?.flex} position={positionBox}>
      {label && (
        <Text fontSize="16px" fontWeight="400" pb="5px">
          {label}
        </Text>
      )}
      <InputGroup>
        {leftIcon && (
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
          >
            {leftIcon}
          </InputLeftElement>
        )}

        {rightIcon && (
          <InputRightElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
          >
            {rightIcon}
          </InputRightElement>
        )}

        <ChakraInput
          as={!mask ? undefined : InputMask}
          id={`${name + Math.round(Math.random() * 1000)}`}
          w="full"
          maskPlaceholder={null}
          mask={mask ?? ''}
          maskChar=""
          isInvalid={!!error}
          errorBorderColor="red.400"
          autoFocus
          placeholder={rest.placeholder ?? label}
          {...register(name)}
          {...rest}
          type={!show ? type ?? 'text' : 'text'}
          onChange={(e: any) => {
            let { value } = e.target;
            if (type === 'number') {
              value = Number(e.target.value);
            }
            onChange?.(value);
            setValue(name, value);
          }}
          value={watch(name) ?? ''}
          fontFamily="Rubik"
        />

        {isLoading && (
          <InputRightElement width="4.5rem" height="74%">
            <Spinner color="primary.500" />
          </InputRightElement>
        )}

        {type === 'password' && (
          <InputRightElement width="4rem" height="full" mr="-5px">
            <Icon
              onClick={() => setShow((m) => !m)}
              fontSize="25px"
              cursor="pointer"
              color="#8A8A8A"
              mt={1}
            >
              {show ? (
                <Eye weight="fill" size={20} />
              ) : (
                <EyeSlash weight="fill" size={20} />
              )}
            </Icon>
          </InputRightElement>
        )}
        <>{buttonRight && buttonRight}</>
      </InputGroup>

      {error && (
        <Text
          textAlign="start"
          marginLeft="15px"
          marginTop="4px !important"
          lineHeight="12px"
          fontSize="12px"
          color="red.400"
          textStyle="body.small"
          fontFamily="Rubik"
        >
          {String(error?.message)}
        </Text>
      )}
    </Box>
  );
};

export default InputComponent;
