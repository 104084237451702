import axios from 'axios';
import { getAuthStorage, setAuthStorage } from './storage';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// Função para enviar solicitações com o cabeçalho apropriado
export const sendRequest = (url: any, data: any, isFormData = false) => {
  const headers = isFormData
    ? { 'Content-Type': 'multipart/form-data' }
    : { 'Content-Type': 'application/json' };

  return api.post(url, data, { headers });
};

function urlParams(url: string): boolean {
  if (url.includes('login')) {
    return false;
  }

  if (url.includes('usuario/registro')) {
    return false;
  }

  if (url.includes('usuario/valida-cpf-existe')) {
    return false;
  }

  if (url.includes('usuario/valida-email-existe')) {
    return false;
  }

  if (url.includes('usuario/termos')) {
    return false;
  }

  if (url.includes('usuario/usuario_termos')) {
    return false;
  }

  return true;
}

api.interceptors.request.use(
  async (config) => {
    const { url } = config;

    if (url !== undefined && urlParams(url)) {
      const storageAuth = await getAuthStorage();
      config.headers.Authorization = `Bearer ${storageAuth.access}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  // eslint-disable-next-line @typescript-eslint/no-shadow
  async (error) => {
    if (error.response.status === 401) {
      if (
        error.config.url.includes('login') === true ||
        error.config.url.includes('recover') === true
      ) {
        return Promise.reject(error);
      }
      // eslint-disable-next-line no-use-before-define
      const token = await refreshToken();

      // eslint-disable-next-line no-param-reassign
      error.config.headers.Authorization = `Bearer ${token}`;

      return api(error.config);
    }

    return Promise.reject(error);
  },
);

const refreshToken = async () => {
  try {
    const storageAuth = await getAuthStorage();
    const data = {
      refresh: storageAuth.refresh,
    };
    const res = await api.post('/login/refresh/', data);
    if (res.status === 200) {
      const token = res.data.access;
      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      const refresh = {
        access: token,
        refresh: storageAuth.refresh,
        user_id: storageAuth.user_id,
      };
      await setAuthStorage(refresh);
      return token;
    }
    return Promise.reject();
  } catch (error) {
    return error;
  }
};
