/* eslint-disable no-console */
import { CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import * as yup from 'yup';
import CheckIcon from '../../../../assets/icons/checkIcon.png';
import { api } from '../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string | undefined;
  agcId: string | undefined;
  creditorId: string | undefined;
  // eslint-disable-next-line no-unused-vars
  onSave: (creditor: any) => void;
}

const schema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório.'),
  documento: yup
    .string()
    .required('Campo obrigatório.')
    .matches(
      /^(?:\d{3}\.\d{3}\.\d{3}-\d{2}|\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})$/,
      'Documento inválido.',
    ),
});

export const DrawerEditCreditor: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  agcId,
  creditorId,
  onSave,
}) => {
  const [creditor, setCreditor] = useState({
    nome: '',
    documento: '',
    agc_classe_id: 0,
    moeda: '',
    valor_original: 0,
  });
  const [classe, setClasse] = useState([{ id: 0, classe: '' }]);

  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});

  useEffect(() => {
    getValuesCreditor();
    getClasse();
  }, []);

  const getValuesCreditor = async () => {
    try {
      const response = await api.get(`/agc-credor/${id}/`);
      if (response.status === 200) {
        setCreditor(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getClasse = async () => {
    try {
      const response = await api.get(`/agc-classe/?agc_id=${agcId}`);
      if (response.status === 200) {
        const { results } = response.data;
        setClasse(results);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const editCreditor = async () => {
    try {
      await schema.validate(creditor, { abortEarly: false });

      const response = await api.put(`/agc-credor/${creditorId}`, creditor);
      if (response.status === 200) {
        onClose();
        onSave(creditor);
        setValidationErrors({});
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors: { [key: string]: string } = {};

        error.inner.forEach((err) => {
          if (err.path) {
            errors[err.path] = err.message;
          }
        });

        setValidationErrors(errors);
      } else {
        console.error(error);
      }
    }
  };

  const formatCnpjCpf = (value: any) => {
    if (value) {
      const cleanedValue = value.replace(/\D/g, '');

      if (cleanedValue.length === 11) {
        return cleanedValue.replace(
          /(\d{3})(\d{3})(\d{3})(\d{2})/,
          '$1.$2.$3-$4',
        );
      } else if (cleanedValue.length === 14) {
        return cleanedValue.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          '$1.$2.$3/$4-$5',
        );
      }
      return cleanedValue;
    }

    return value;
  };

  const handleChange = (e: any) => {
    const nameChange = e.target.name;
    const valueChange = e.target.value;
    let newValue = valueChange;

    if (nameChange === 'documento') {
      newValue = formatCnpjCpf(valueChange);
    }

    setCreditor({
      ...creditor,
      [nameChange]: newValue,
    });
  };

  const formattedValue = (e: string) => {
    if (e === 'USD') {
      return <Text>US$</Text>;
    } else if (e === 'EUR') {
      return <Text>€</Text>;
    }
    return <Text>R$</Text>;
  };

  const formatado = {
    valor: `${creditor?.valor_original.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'EUR',
    })}`,
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={() => {
        onClose();
        setValidationErrors({});
      }}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody p="68px 38px" bg="#EEE">
          <Stack spacing="36px">
            <HStack spacing="19px" align="center">
              <button onClick={onClose}>
                <FiChevronLeft size={24} />
              </button>
              <Text
                color="#222"
                fontFamily="Roboto"
                fontSize="30px"
                fontWeight="400"
                letterSpacing="-0.3px"
              >
                Editando credor
              </Text>
            </HStack>

            <Stack spacing="17px">
              <Stack spacing="8px">
                <Text
                  color="#06152B"
                  fontFamily="Rubik"
                  fontSize="16px"
                  fontWeight="400"
                >
                  Nome
                </Text>
                <Input
                  value={creditor && creditor.nome}
                  borderRadius="43px"
                  bg="#FFF"
                  boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                  h="54px"
                  name="nome"
                  onChange={handleChange}
                />
                {validationErrors.nome ? (
                  <Text
                    textAlign="start"
                    marginLeft="15px"
                    marginTop="8px !important"
                    lineHeight="12px"
                    fontSize="14px"
                    color="red.400"
                    textStyle="body.small"
                  >
                    {validationErrors.nome}
                  </Text>
                ) : null}
              </Stack>

              <HStack spacing="37px">
                <Stack spacing="8px" w="50%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    CNPJ / CPF
                  </Text>
                  <Input
                    value={formatCnpjCpf(creditor?.documento)}
                    borderRadius="43px"
                    bg="#FFF"
                    name="documento"
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                    h="54px"
                    onChange={handleChange}
                  />
                  {validationErrors.documento ? (
                    <Text
                      textAlign="start"
                      marginLeft="15px"
                      marginTop="8px !important"
                      lineHeight="12px"
                      fontSize="14px"
                      color="red.400"
                      textStyle="body.small"
                    >
                      {validationErrors.documento}
                    </Text>
                  ) : null}
                </Stack>

                <Stack spacing="8px" w="36%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Classe
                  </Text>
                  <Select
                    borderRadius="43px"
                    bg="#FFF"
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                    _focus={{ border: 'none' }}
                    _focusVisible={{ border: 'none' }}
                    h="54px"
                    iconColor="#8A8A8A"
                    name="agc_classe_id"
                    onChange={handleChange}
                  >
                    {classe.map((item) => {
                      /* eslint no-else-return: "error" */
                      const formattedClasse = () => {
                        if (item.classe === 'trabalhista') {
                          return 'Trabalhista';
                        } else if (item.classe === 'garantia-real') {
                          return 'Garantia Real';
                        } else if (item.classe === 'quirografario') {
                          return 'Quirografário';
                        }
                        return 'Microempresa';
                      };

                      return (
                        <option value={item.id} key={item.id}>
                          {formattedClasse()}
                        </option>
                      );
                    })}
                  </Select>
                </Stack>
              </HStack>

              <HStack spacing="20px" align="flex-end">
                <Stack spacing="8px" w="80%">
                  <Text
                    color="#06152B"
                    fontFamily="Rubik"
                    fontSize="16px"
                    fontWeight="400"
                  >
                    Moeda
                  </Text>
                  <Input
                    placeholder="Moeda"
                    color="#8A8A8A"
                    borderRadius="43px"
                    bg="#FFF"
                    boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                    _focus={{ border: 'none' }}
                    _focusVisible={{ border: 'none' }}
                    h="54px"
                    value={creditor.moeda}
                    onChange={handleChange}
                    isReadOnly
                    cursor="auto"
                  />
                </Stack>

                <HStack justify="space-between" w="100%" spacing="20px">
                  <InputGroup>
                    <InputLeftElement mt="4px" ml="8px">
                      {formattedValue(creditor.moeda)}
                    </InputLeftElement>
                    <Input
                      placeholder="Valor"
                      borderRadius="43px"
                      bg="#FFF"
                      boxShadow="0px 15px 24px 0px rgba(0, 0, 0, 0.05)"
                      h="54px"
                      name="valor_original"
                      onChange={handleChange}
                      value={formatado.valor.replace(/€|US\$|R\$/g, '')}
                    />
                  </InputGroup>
                </HStack>
              </HStack>
            </Stack>

            <Flex justify="space-between">
              <Button
                borderRadius="45px"
                border="2px solid #DF8E25"
                bg="rgba(255, 255, 255, 0.00)"
                color="#000"
                fontFamily="Rubik"
                fontSize="14px"
                fontWeight="400"
                onClick={() => {
                  onClose();
                  setValidationErrors({});
                }}
              >
                <HStack spacing="10px" align="center">
                  <CloseIcon w="10px" h="10px" color="#000" opacity="0.25" />
                  <Text>Cancelar</Text>
                </HStack>
              </Button>

              <Button
                borderRadius="105px"
                bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                boxShadow="0px 5px 10px 0px rgba(0, 0, 0, 0.10)"
                fontFamily="Rubik"
                fontSize="14px"
                color="#FFF"
                p="14px 25px"
                w="170px"
                onClick={editCreditor}
              >
                <HStack spacing="10px">
                  <Image src={CheckIcon} />
                  <Text>Salvar</Text>
                </HStack>
              </Button>
            </Flex>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
