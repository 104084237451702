import { ChakraProvider, Checkbox, extendTheme } from '@chakra-ui/react';
import React from 'react';

interface Props {
  name: string | undefined;
  isChecked?: boolean;
  value?: string;
  // eslint-disable-next-line no-unused-vars
  onChange?: (e: any) => void;
  nameForm?: string | undefined;
}

export const CheckboxAgc: React.FC<Props> = ({
  name,
  isChecked,
  value,
  onChange,
  nameForm,
}) => {
  const theme = extendTheme({
    components: {
      Checkbox: {
        baseStyle: {
          control: {
            _checked: {
              bg: '#3A4546',
              border: 'none',
              _hover: 'none',
            },
          },
        },
      },
    },
  });
  return (
    <ChakraProvider theme={theme}>
      <Checkbox
        color="#222"
        fontSize="16px"
        fontWeight="500"
        fontFamily="Ubuntu"
        isChecked={isChecked}
        value={value}
        onChange={onChange}
        name={nameForm}
      >
        {name}
      </Checkbox>
    </ChakraProvider>
  );
};
