import { useToast } from '@chakra-ui/react';
import { useState } from 'react';
import { api } from '../../../services/api';

export const useSuspenderAGC = () => {
  const toast = useToast();
  const [suspensao, setSuspensa] = useState<ISuspensao[]>([]);

  const getSuspensao = async (agcId: number) => {
    try {
      const res = await api.get('agc-suspensao', {
        params: { agc_id: agcId },
      });
      setSuspensa(res.data?.results);
    } catch (error) {
      //
    }
  };

  const suspenderAgc = async (data: any) => {
    try {
      await api.post('/agc-suspensao/', data);
    } catch (error) {
      toast({
        title: 'Erro ao suspender agc',
        description: 'Tente novamente mais tarde',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return { suspensao, getSuspensao, suspenderAgc };
};

export interface ISuspensao {
  id: number;
  agc_id: number;
  data_suspensao: string;
  data_retomada: string;
}
