import {
  Tabs,
  Box,
  Flex,
  Tab,
  TabList,
  Text,
  TabPanels,
  TabPanel,
  Tag,
  TagLabel,
  HStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CloseIcon } from '@chakra-ui/icons';
import { AllAgcsList } from './tabs/allAgcs';
import { MyAgcs } from './tabs/myAgcs';

const AGCs: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  const [tabsIndex, setTabsIndex] = useState<number>(0);

  return (
    <Box
      background="#EEE"
      boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset, 0px 15px 30px rgba(0, 0, 0, 0.20)"
      borderRadius="30px"
      minH="86vh"
      marginTop="25px"
    >
      <Flex p="32px 37px 15px 64px" alignItems="center" justify="space-between">
        <Text color="#222" fontFamily="Rubik" fontSize="30px" fontWeight="500">
          AGC's
        </Text>
      </Flex>

      <Box>
        <Tabs
          variant="unstyled"
          index={tabsIndex}
          onChange={(index) => setTabsIndex(index)}
          isLazy
        >
          <TabList pl="60px">
            <Tab
              border="none"
              _selected={{
                bg: '#F2F2F2',
                boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                color: '#222',
              }}
              w="269px"
              background="#FFFFFF"
              borderTopRadius="30px"
              boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
              color="#AAA"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              <Text py="6px">Todas as AGC's</Text>
            </Tab>
            <Tab
              _selected={{
                bg: '#F2F2F2',
                boxShadow: '0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset',
                color: '#222',
              }}
              border="none"
              w="269px"
              background="#FFFFFF"
              borderTopRadius="30px"
              boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40)"
              color="#AAA"
              fontFamily="Rubik"
              fontSize="18px"
              fontWeight="400"
              letterSpacing="1.8px"
            >
              <Text py="6px">Minhas AGC's</Text>
            </Tab>
            <HStack w="68%" spacing="13px" justify="flex-end">
              {selectedValue &&
                selectedValue.length > 0 &&
                selectedValue.map((item) => {
                  /* eslint no-else-return: "error" */
                  const formattedProfiles = () => {
                    if (item === 'aj') {
                      return 'Administrador Judicial';
                    } else if (item === 'aj-equipe') {
                      return 'Membro da Equipe';
                    } else if (item === 'credor') {
                      return 'Credor';
                    } else if (item === 'recuperanda') return 'Recuperanda';
                    return false;
                  };

                  const handleTagClose = () => {
                    const updatedValue = selectedValue.filter(
                      (e) => e !== item,
                    );
                    setSelectedValue(updatedValue);
                  };

                  return (
                    <Tag
                      key={item}
                      borderRadius="full"
                      variant="solid"
                      bg="#DEDEDE"
                      alignItems="center"
                    >
                      <HStack spacing="10px" p="8px 17px">
                        <CloseIcon
                          w="10px"
                          h="10px"
                          color="#848484"
                          onClick={handleTagClose}
                          cursor="pointer"
                        />
                        <TagLabel
                          color="#848484"
                          fontFamily="Rubik"
                          fontSize="12px"
                          fontWeight="400"
                        >
                          {formattedProfiles()}
                        </TagLabel>
                      </HStack>
                    </Tag>
                  );
                })}
            </HStack>
          </TabList>

          <TabPanels
            borderRadius="30px"
            fill="#DADADA"
            boxShadow="0px 2px 0px 0px rgba(255, 255, 255, 0.40) inset"
          >
            <TabPanel>
              <AllAgcsList />
            </TabPanel>

            <TabPanel>
              <MyAgcs />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default AGCs;
