import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Input,
  Spinner,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Table,
} from '@chakra-ui/react';
import { delay } from 'lodash';

import { useCredores } from '../../../../hooks/useCredores';
import { useStream } from '../../../../context/stream.context';
import { useDialog } from '../../../../context/useDialog';
import CardCredor from '../../card-credor';

interface Props {
  isOpen: boolean;
}

const TodosCredores: React.FC<Props> = ({ isOpen }) => {
  const { callId, currentUserBackend, isAdmin } = useStream();
  const showDialog = useDialog();

  const {
    filters,
    getCredores,
    credores,
    updateCredor,
    loading,
    deleteCredor,
    count,
    setFilters,
  } = useCredores(Number(callId));

  useEffect(() => {
    if (isOpen) {
      getCredores();
    }
  }, [isOpen, filters]);

  useEffect(() => {
    if (!isAdmin) {
      setFilters({ ...filters, usuario_id: currentUserBackend?.id });
    }
  }, [currentUserBackend, isAdmin]);

  const deleteCredorDialog = (id: number) => {
    showDialog({
      title: 'Excluir credor',
      message: 'Deseja realmente excluir este credor?',
      buttonCancel: 'Cancelar',
      buttonOK: 'Excluir',
      onClick: () => {
        deleteCredor(id);
      },
    });
  };

  const pages = filters.limit ? Math.ceil(count / filters.limit) : 0;
  const currentPage = filters.page;

  return (
    <Flex flex={1} flexDir="column">
      <Box pb="20px">
        <Input
          size="sm"
          w="full"
          bg="white"
          color="gray.600"
          placeholder="Pesquisar credor"
          onChange={async (e) => {
            delay(
              () =>
                setFilters({
                  ...filters,
                  search: e.target.value,
                }),
              500,
            );
          }}
        />
      </Box>

      {loading && (
        <Center w="full" py="30px">
          <Spinner />
        </Center>
      )}

      {!loading && (
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>nome</Th>
                <Th>CNPJ / CPF</Th>
                <Th>classe</Th>
                <Th isNumeric>moeda</Th>
                <Th isNumeric>credito</Th>
                <Th isNumeric>ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {credores.map((credor) => (
                <CardCredor
                  updateCredor={updateCredor}
                  credor={credor}
                  key={String(credor.id)}
                  deleteCredor={deleteCredorDialog}
                />
              ))}

              {credores.length === 0 && (
                <Tr>
                  <Th opacity={0.5} pt="30px" colSpan={6} textAlign="center">
                    Nenhum credor encontrado
                  </Th>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}

      <HStack justify="center">
        {Array.from({ length: pages }).map((_, index) => (
          <Button
            size="sm"
            color={currentPage === index + 1 ? 'white' : 'gray.600'}
            fontSize="14px"
            borderRadius="10px"
            onClick={() => {
              setFilters({
                ...filters,
                page: index + 1,
              });
            }}
            bg={currentPage === index + 1 ? 'primary' : 'gray.300'}
          >
            {index + 1}
          </Button>
        ))}
      </HStack>
    </Flex>
  );
};

export default TodosCredores;
