import { useState } from 'react';
import { api } from '../../../services/api';

export const useDocs = (agcId: number) => {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState<IDocs[]>([]);

  const getDocs = async () => {
    try {
      setLoading(true);
      const res = await api.get<IResult>('/agc-documento/', {
        params: { agc_id: agcId },
      });
      setDocs(res.data?.results);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return { getDocs, loading, docs };
};

export interface IDocs {
  id: number;
  agc_id: number;
  tipo_documento: string;
  data_documento: string;
  arquivo_link: string;
}

export interface IResult {
  count: number;
  next: string;
  previous: string;
  results: IDocs[];
}
