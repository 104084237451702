import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { FaTrash } from 'react-icons/fa';
import { AiOutlineSearch } from 'react-icons/ai';
import { api } from '../../../../../services/api';
import { ModalAddMember } from '../modalAddMember';
import { ModalDelete } from '../modalDelete';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: number;
}

interface DataUser {
  id: number;
  nome: string | undefined;
  sobrenome: string | undefined;
  cpf: string | undefined;
  email: string | undefined;
}

interface MembersTeam {
  id: number;
  usuario_cpf: string | undefined;
  usuario_nome: string | undefined;
  ativo: boolean;
}

interface MemberDelete {
  usuario_nome: string | undefined;
  id: number;
}

export const ModalMember: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const [cpf, setCpf] = useState<string>('');
  const [errorExist, setErrorExist] = useState<string>('');

  const [dados, setDados] = useState<DataUser | null>();
  const [dataDelete, setDataDelete] = useState<MemberDelete | null>();

  const [membersTeam, setMembersTeam] = useState<MembersTeam[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getMembers();
  }, [id]);

  const getUser = async () => {
    try {
      const response = await api.get(`/usuario/buscar-cpf/${cpf}`);
      if (response.status === 200 || response.status === 201) {
        setDados(response.data);
        if (errorExist) {
          setErrorExist('');
        }
        setOpen(true);
      }
    } catch (error: any) {
      const { status } = error.response;
      if (status === 404) {
        setErrorExist('Não encontramos este usuário.');
      }
    }
  };

  const headTable = [
    { title: 'Nome', id: 'usuario_nome' },
    { title: 'CPF', id: 'usuario_cpf' },
    { title: 'Ativo', id: 'ativo' },
    { title: 'Ações', id: '' },
  ];

  const getMembers = async () => {
    setLoading(true);
    try {
      const response = await api.get(`aj-equipe/?aj_id=${id}`);
      if (response.status === 200 || response.status === 201) {
        const { results } = response.data;
        setMembersTeam(results);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const postMember = async () => {
    try {
      const data = {
        aj_id: id,
        usuario_id: dados?.id,
      };
      const response = await api.post('aj-equipe/', data);
      if (response.status === 200 || response.status === 201) {
        setCpf('');
        setMembersTeam((prevState) => [...prevState, response.data]);
        setOpen(false);
      }
    } catch (error: any) {
      const { __all__ } = error.response.data;
      if (error) {
        setErrorExist(__all__[0]);
        setOpen(false);
      }
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setDados(null);
        setCpf('');
        setErrorExist('');
      }}
      size="3xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody bg="#EEE" borderRadius="8px" p="25px 25px">
          <Stack spacing="10px">
            <Text fontWeight="500" fontSize="24px">
              Membros de Equipe
            </Text>

            <Box h="2px" bg="#CCC" />

            <HStack>
              <Input
                placeholder="CPF"
                as={InputMask}
                mask="***.***.***-**"
                name="cpf"
                w="40%"
                value={cpf}
                onChange={(e) => {
                  const unmaskedValue = e.target.value.replace(/[^0-9]/g, '');
                  setCpf(unmaskedValue);
                }}
              />
              {cpf.length === 11 && (
                <Button
                  borderRadius="71px"
                  bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
                  fontFamily="Rubik"
                  fontWeight="400"
                  color="#FFF"
                  onClick={getUser}
                  leftIcon={<AiOutlineSearch />}
                >
                  Buscar
                </Button>
              )}
            </HStack>
            {errorExist && (
              <Text color="red.500" fontSize="16px">
                {errorExist}
              </Text>
            )}

            {loading && (
              <Flex align="center" justify="center">
                <Spinner color="#D89320" size="lg" />
              </Flex>
            )}

            {!loading && (
              <TableContainer mt="10px">
                <Table variant="simple">
                  <Thead borderBottom="2px solid #9B9B9B">
                    <Tr>
                      {headTable.map((item) => (
                        <Th
                          key={item.id}
                          color="#000"
                          fontFamily="Rubik"
                          fontSize="12px"
                          fontWeight="700"
                        >
                          {item.title}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {membersTeam &&
                      membersTeam.length > 0 &&
                      membersTeam.map((item, index, array) => {
                        const lastItem = index === array.length - 1;
                        return (
                          <Tr
                            borderBottom={lastItem ? '' : '2px solid #CCC'}
                            fontWeight="400"
                            fontFamily="Rubik"
                            color="#000"
                            fontSize="14px"
                          >
                            <Td>{item.usuario_nome}</Td>
                            <Td>{item.usuario_cpf}</Td>
                            <Td>{item.ativo ? 'Sim' : 'Não'}</Td>
                            <Td>
                              <FaTrash
                                color="#D89320"
                                size={20.5}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setOpenDelete(true);
                                  setDataDelete({
                                    id: item.id,
                                    usuario_nome: item.usuario_nome,
                                  });
                                }}
                              />
                            </Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </Stack>
        </ModalBody>
      </ModalContent>

      <ModalAddMember
        isOpen={open}
        onClose={() => setOpen(false)}
        name={dados?.nome}
        surname={dados?.sobrenome}
        onClick={postMember}
      />
      <ModalDelete
        isOpen={openDelete}
        onClose={() => setOpenDelete(false)}
        name={dataDelete?.usuario_nome}
        id={dataDelete?.id}
        callback={setMembersTeam}
      />
    </Modal>
  );
};
