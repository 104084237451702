import { Box, Center, Flex, Spinner, Stack, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { api } from '../../services/api';

interface Props {
  id: number;
  visualizada: boolean;
  mensagem: string | undefined;
  remetente_nome: string | undefined;
  data_mensagem: string | undefined;
  agc_credor_id: number;
  agc_id: number;
}

const Messages: React.FC = () => {
  const [messages, setMessages] = useState<Props[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    getMessages();
  }, []);

  const getMessages = async () => {
    setLoading(true);
    try {
      const response = await api.get(
        'agc-credor-procurador-dialogo/listar-dialogos/',
      );
      if (response.status === 200 || response.status === 201) {
        setMessages(response.data);
        setLoading(false);
      }
    } catch (error) {
      console.error();
    }
  };

  const viewMessage = async (id: number, credorId: number, agcId: number) => {
    setLoading(true);
    try {
      const response = await api.patch(
        `agc-credor-procurador-dialogo/${id}/marcar-visualiza/`,
      );
      if (response.status === 200 || response.status === 201) {
        window.location.href = `/agcs/${agcId}/creditors/${credorId}`;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box p="19px 20px 24px 20px">
      <Box
        bg="#EEEEEE"
        borderRadius="20px"
        boxShadow="0px 15px 30px 0px rgba(0, 0, 0, 0.10), 0px 2px 0px 0px rgba(255, 255, 255, 0.30) inset"
        minH="86vh"
      >
        <Center pt="26px" pb="26px">
          <Stack spacing="10px">
            {loading && (
              <Flex align="center" justify="center">
                <Spinner size="lg" color="#FDCA5F" />
              </Flex>
            )}

            {!loading && messages && messages.length === 0 && (
              <Center p="40px">
                <Text opacity={0.5}>Nenhuma notificação</Text>
              </Center>
            )}

            {!loading &&
              messages &&
              messages.length > 0 &&
              messages.map((item) => {
                const dateFormatted = (value: string | undefined) => {
                  if (value) {
                    const data = new Date(value);

                    const dataFormatada = format(data, 'dd/MM/yyyy');
                    return dataFormatada;
                  }
                  return null;
                };
                return (
                  <Box
                    borderRadius="12px"
                    borderLeft={
                      !item.visualizada ? '4px solid #FDCA5F' : 'none'
                    }
                    bg="#FFF"
                    onClick={() =>
                      viewMessage(item.id, item.agc_credor_id, item.agc_id)
                    }
                    key={item.id}
                    cursor="pointer"
                  >
                    <Stack p="10px" w="461px" spacing="10px">
                      <Text color="#515151" fontSize="16px" fontWeight="500">
                        Teste
                      </Text>
                      <Text color="#515151" fontSize="14px" fontWeight="400">
                        {item.mensagem}
                      </Text>
                      <Text color="#A0A0A0" fontSize="12px" fontWeight="400">
                        {dateFormatted(item.data_mensagem)}
                      </Text>
                    </Stack>
                  </Box>
                );
              })}
          </Stack>
        </Center>
      </Box>
    </Box>
  );
};

export default Messages;
