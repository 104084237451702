import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Download, File } from '@phosphor-icons/react';
import React, { ChangeEvent, useRef, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { CardDoc } from './components/cardDocument';
import { api } from '../../../../../../../services/api';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: number;
  // eslint-disable-next-line no-unused-vars
  arrayDocs: (prevState: any) => void;
}

export const DrawerAddDocs: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  arrayDocs,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const files = ev.target.files || [];
    setSelectedFiles(Array.from(files));
  };

  const sendDocs = async () => {
    try {
      const uploadPromises = selectedFiles.map(async (item) => {
        const formData = new FormData();
        formData.append('agc_credor_procurador_id', id.toString());
        formData.append('tipo_documento', item.name);
        formData.append('arquivo_link', item);

        const response = await api.post(
          'agc-credor-procurador-documento/',
          formData,
        );
        if (response.status === 200 || response.status === 201) {
          setSelectedFiles([]);
          arrayDocs((prevState: any) => [response.data, ...prevState]);
          onClose();
        }
      });

      await Promise.all(uploadPromises);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent bg="#EEE">
        <DrawerCloseButton />

        <DrawerBody p="68px 38px">
          <Stack spacing="36px">
            <Text
              color="#222"
              fontFamily="Roboto"
              fontSize="30px"
              fontWeight="400"
              letterSpacing="-0.3px"
              w="40%"
            >
              Documentos Importantes
            </Text>

            <Text
              color="#222"
              fontFamily="Rubik"
              fontSize="22px"
              fontWeight="400"
              w="80%"
            >
              Voce pode adicionar <br /> manualmente ou usar o modelo abaixo
              para fazer o upload do arquivo
            </Text>

            <Button
              borderRadius="45px"
              border="2px solid #EBD87F"
              bg="none"
              leftIcon={<Download size={32} weight="bold" />}
              color="#000"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              h="51px"
            >
              Modelo de documento
            </Button>

            <Button
              borderRadius="31px"
              bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
              color="#FFF"
              fontFamily="Rubik"
              fontSize="16px"
              fontWeight="400"
              letterSpacing="0.8px"
              onClick={openFileInput}
            >
              Adicionar documento
            </Button>

            {selectedFiles.length > 0 &&
              selectedFiles.map((item) => {
                const formattedData = () => {
                  if (item.lastModified) {
                    const today = new Date(item.lastModified);
                    const month =
                      today.getMonth() + 1 < 10
                        ? `0${today.getMonth() + 1}`
                        : today.getMonth() + 1;
                    const day =
                      today.getDate() < 10
                        ? `0${today.getDate()}`
                        : today.getDate();
                    const hours =
                      today.getHours() < 10
                        ? `0${today.getHours()}`
                        : today.getHours();
                    const minutes =
                      today.getMinutes() < 10
                        ? `0${today.getMinutes()}`
                        : today.getMinutes();

                    const dateNow = `${day}/${month}/${today.getFullYear()} - ${hours}:${minutes} AM`;

                    return dateNow;
                  }

                  return '';
                };

                return (
                  <HStack spacing="12px">
                    <CardDoc
                      data={formattedData()}
                      name={item.name}
                      type={item.type}
                    />
                    <FaTrash
                      color="#D89320"
                      size={20}
                      onClick={() =>
                        setSelectedFiles((prevItem) =>
                          prevItem.filter((value) => value !== item),
                        )
                      }
                      cursor="pointer"
                    />
                  </HStack>
                );
              })}

            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              accept=".xls,.xlsx,.xlsb,.txt,.csv,.tsv,.pdf"
              multiple
              onChange={handleFileChange}
            />

            {selectedFiles.length > 0 && (
              <HStack>
                <Button
                  bg="transparent"
                  boxShadow="none"
                  borderColor="#DF8E25"
                  borderWidth="1px"
                  fontWeight="500"
                  fontSize="15px"
                  w="100%"
                  onClick={() => setSelectedFiles([])}
                >
                  Cancelar
                </Button>

                <Button
                  bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%), #FFF"
                  boxShadow="none"
                  borderWidth="1px"
                  fontWeight="500"
                  fontSize="15px"
                  color="white"
                  w="100%"
                  onClick={sendDocs}
                >
                  Confirmar
                </Button>
              </HStack>
            )}
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
