import { Box, Button, HStack, Text, useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import ModalResultado from './ModalResultado';
import { RetornoPergunta, usePerguntas } from '../../../hooks/usePerguntas';
import { useAGC } from '../../../hooks/useAGC';

interface Props {
  pergunta: RetornoPergunta;
  isAdmin?: boolean;
}

const CardPergunta: React.FC<Props> = ({ pergunta, isAdmin }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getTotalVotos, totalVotos } = usePerguntas(Number(pergunta.id));
  const { iniciarVotacao, votacaoIniciada } = useAGC(Number(pergunta.id));

  const isEncerrada = pergunta?.status_pergunta === 'votacao-encerrada';

  useEffect(() => {
    getTotalVotos();
  }, []);

  return (
    <Box
      mt="14px"
      p="20px"
      borderRadius="10px"
      bg="linear-gradient(0deg, #DF8E25 0%, #DCAC36 100%, #EBD87F 100%)"
    >
      <Text fontSize="18px" color="white" pb="10px">
        {pergunta.descricao}
      </Text>

      <HStack justify="space-between">
        <Text fontSize="32px">
          {totalVotos}{' '}
          <Text as="span" fontSize="18px">
            votos
          </Text>
        </Text>

        <HStack justify="end">
          {isAdmin && (
            <Button
              _hover={{
                color: 'white',
                bg: 'primary',
              }}
              color="white"
              borderWidth="2px"
              borderColor="white"
              size="sm"
              isDisabled={isEncerrada}
              variant="outline"
              onClick={async () => {
                await iniciarVotacao(Number(pergunta.id));
                onOpen();
              }}
            >
              {votacaoIniciada ? 'Encerrar votação' : 'Iniciar Votação'}
            </Button>
          )}

          {isAdmin && (
            <Button
              onClick={() => {
                onOpen();
              }}
              _hover={{
                color: 'white',
                bg: 'primary',
              }}
              color="white"
              borderWidth="2px"
              borderColor="white"
              size="sm"
              variant="outline"
            >
              Resultado
            </Button>
          )}
        </HStack>
      </HStack>

      <ModalResultado
        perguntaId={Number(pergunta.id)}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Box>
  );
};

export default CardPergunta;
