export const resizeImg = (
  dataUri: string,
  callback: (_img: string) => void,
) => {
  const imgEl = document.createElement('img');
  imgEl.addEventListener('load', () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = 200;
    canvas.height = 150;

    ctx?.drawImage(imgEl, 0, 0, canvas.width, canvas.height);
    const dataURI = canvas.toDataURL();

    callback(dataURI);
  });
  imgEl.src = dataUri;
};
